import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/FilterAlt';
import ClearIcon from '@mui/icons-material/Clear';
import LockIcon from '@mui/icons-material/Lock';
import AddBox from '@mui/icons-material/AddBox';
import Edit from '@mui/icons-material/Edit';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import React, { useState } from 'react';
import { useNotifications } from '../../../hooks/useNotifications';
import * as UserService from '../../../services/user-service/user.service';
import { UserForm } from './userForm';
import { TDataTableColumn } from '../../../shared/components/data-table/DataTable';
import DataTableWithPagination from '../../../shared/components/data-table/DataTableWithPagination';
import UserAvatar from '../../../shared/components/UserAvatar';
import { DateTime } from 'luxon';
import { Warning } from '@mui/icons-material';
import { CustomerService } from '../../../services/customer-service/customer.service';
import { UserRole } from '../../../shared/backend';
import DeleteButton from '../../../shared/components/delete/DeleteButton';
import { countryToFlag } from '../../../shared/helper/country';
import { useEnumParam, useStringParam } from '../../../hooks/useParam';
import { useTranslation } from 'react-i18next';

type Props = {
  customerId?: number;
};

export const UserTable: React.FC<Props> = ({ customerId }) => {
  const { t } = useTranslation('users');
  const notifications = useNotifications();
  const customers = CustomerService.useCustomers();

  const [userToEdit, setUserToEdit] = useState<UserService.SafeUserDto | null>(null);
  const [showCreateUserDialog, setShowCreateUserDialog] = useState(false);

  const users = UserService.useUsers();
  const [filter, setFilter] = useStringParam('q', '');
  const [role, setRole] = useEnumParam('role', UserRole);

  const columns: TDataTableColumn<UserService.SafeUserDto>[] = [
    {
      title: t('Avatar'),
      render: (rowData) => <UserAvatar user={rowData} />,
    },
    {
      title: t('ID'),
      field: 'userId',
    },
    { title: t('First name'), field: 'firstName' },
    { title: t('Last name'), field: 'lastName' },
    { title: t('E-Mail'), field: 'email' },
    { title: t('Company'), field: 'company' },
    { title: t('Department'), field: 'department' },
    {
      title: t('Role'),
      render: (rowData) => (
        <span>
          {rowData.role === UserRole.CUSTOMER
            ? customers.data.find(({ customerId }) => customerId === rowData.customerId)?.company ?? rowData.role
            : rowData.role}{' '}
          {!!rowData.processTypes.length && (
            <Tooltip title={rowData.processTypes.map(({ label }) => label).join(', ')}>
              <span>
                &nbsp;
                {[
                  ...rowData.processTypes.reduce((countries, { dispatchCountry, destinationCountry }) => {
                    countries.add(dispatchCountry);
                    countries.add(destinationCountry);
                    return countries;
                  }, new Set<string>()),
                ].map((country) => countryToFlag(country))}
              </span>
            </Tooltip>
          )}
        </span>
      ),
    },
    {
      title: t('Last login'),
      render: (rowData) => (
        <>
          {rowData.isInactive && (
            <Warning
              color="warning"
              sx={{ verticalAlign: 'bottom' }}
            />
          )}{' '}
          {rowData.lastAuthenticated ? DateTime.fromISO(rowData.lastAuthenticated).toRelative() : <em>-</em>}
          {rowData.hasTotpEnabled && (
            <LockIcon
              fontSize="small"
              color="disabled"
            />
          )}
        </>
      ),
    },
    {
      title: '',
      render: (rowData) => (
        <DeleteButton
          key={rowData.userId}
          label={t('Deactivate')}
          variant="contained"
          onClick={async () => {
            await deleteUser(rowData.userId);
            users.mutate();
          }}
          icon={<PersonOffIcon />}
        />
      ),
    },
  ];

  const deleteUser = async (id: number) => {
    try {
      await UserService.deleteUser(id);

      users.mutate();
    } catch (error) {
      notifications.addError(error);
    }
  };

  const predicate = (user: UserService.SafeUserDto): boolean => {
    const regex = new RegExp(filter, 'i');

    return (
      regex.test(user.firstName) ||
      regex.test(user.lastName) ||
      regex.test(user.email) ||
      regex.test(user.company) ||
      regex.test(user.department)
    );
  };

  const rows = filter
    ? users.data.filter(predicate)
    : customerId
      ? users.data.filter((user) => user.customerId === customerId)
      : users.data;

  return (
    <>
      <Stack
        direction="column"
        spacing={3}
      >
        <Stack
          direction="row"
          spacing={1}
          justifyContent="flex-end"
        >
          <TextField
            select
            value={role ?? ''}
            onChange={(ev) => setRole((ev.target.value as UserRole) || null)}
            size="small"
            label={t('Role')}
            sx={{ minWidth: 120 }}
          >
            <MenuItem>
              <em>{t('All')}</em>
            </MenuItem>
            {Object.values(UserRole).map((role) => (
              <MenuItem
                key={role}
                value={role}
              >
                {role}
              </MenuItem>
            ))}
          </TextField>
          {!customerId && (
            <TextField
              size="small"
              margin="none"
              value={filter}
              label={t('Filter')}
              onChange={(ev) => setFilter(ev.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {filter ? (
                      <IconButton
                        onClick={() => setFilter('')}
                        edge="end"
                      >
                        <ClearIcon />
                      </IconButton>
                    ) : (
                      <SearchIcon color="disabled" />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          )}

          <Button
            color="secondary"
            size="small"
            onClick={() => setShowCreateUserDialog(true)}
            startIcon={<AddBox />}
            variant="outlined"
          >
            {t('Create user')}
          </Button>
        </Stack>

        <DataTableWithPagination
          columns={columns}
          rows={rows.filter((row) => !role || role === row.role)}
          actions={[
            {
              icon: () => <Edit />,
              tooltip: t('Edit'),
              onClick: (rowData) => {
                setUserToEdit(rowData);
              },
            },
          ]}
          isLoading={users.isLoading}
        />
      </Stack>

      {userToEdit && (
        <Dialog
          open={true}
          onClose={() => setUserToEdit(null)}
        >
          <DialogTitle>{t('Edit user')}</DialogTitle>
          <DialogContent>
            <UserForm
              customerId={customerId}
              onSubmit={() => {
                users.mutate();
                setUserToEdit(null);
              }}
              defaultValues={userToEdit}
              onReactivateUser={async (userId) => {
                await UserService.reactivateUser(userId);
                users.mutate();
                setUserToEdit(null);
              }}
            />
          </DialogContent>
        </Dialog>
      )}

      {showCreateUserDialog && (
        <Dialog
          open={true}
          onClose={() => setShowCreateUserDialog(false)}
        >
          <DialogTitle>{t('Create user')}</DialogTitle>
          <DialogContent>
            <UserForm
              customerId={customerId}
              defaultValues={customerId ? { customerId, role: UserRole.CUSTOMER } : undefined}
              onSubmit={() => {
                users.mutate();
                setShowCreateUserDialog(false);
              }}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};
