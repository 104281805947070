import React from 'react';
import { ProcessWithCustomerDto } from '../../../../services/process-service/process.service';
import { Card, CardHeader, Typography, CardContent, IconButton, Stack, Box } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import ProcessDeletion from './ProcessDeletion';
import BlockProcess from './BlockProcess';
import ProcessDeleteArticles from './ProcessDeleteArticles';
import { useTranslation } from 'react-i18next';

type Props = {
  process: ProcessWithCustomerDto;
};

const ProcessDangerzone: React.FC<Props> = ({ process }) => {
  const { t } = useTranslation('CRM');

  return (
    <Card sx={{ p: 1, border: '3px solid', borderColor: 'warning.light' }}>
      <CardHeader
        title={<Typography variant="h5">{t('Danger zone')}</Typography>}
        avatar={
          <IconButton
            sx={{ mr: 0.5 }}
            color="warning"
            component="a"
            href="https://www.youtube.com/watch?v=siwpn14IE7E"
            target="_blank"
            rel="noopener noreferrer"
            edge="start"
          >
            <WarningIcon sx={{ fontSize: '2.5rem' }} />
          </IconButton>
        }
      />

      <CardContent>
        <Stack
          direction="column"
          spacing={6}
        >
          <Box>
            <Typography
              variant="h6"
              mb={1}
            >
              {t('Condition')}
            </Typography>

            <BlockProcess process={process} />
          </Box>

          <Box>
            <Typography
              variant="h6"
              mb={1}
            >
              {t('Data')}
            </Typography>

            <ProcessDeleteArticles processId={process.processId} />
          </Box>

          <Box>
            <Typography
              variant="h6"
              mb={1}
            >
              {t('The End')}
            </Typography>

            <ProcessDeletion process={process}></ProcessDeletion>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default ProcessDangerzone;
