import React from 'react';
import DataTable, { TDataTableAction, TDataTableColumn } from '../../shared/components/data-table/DataTable';
import { ShipmentStateIcon } from '../../shared/components/shipment-state-icon/ShipmentStateIcon';
import { useAuthentication } from '../../hooks/useAuthentication';
import EditIcon from '@mui/icons-material/Edit';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { Paper, TablePagination } from '@mui/material';
import { useIntParam } from '../../hooks/useParam';
import { useHistoryNavigation } from '../../hooks/useHistoryNavigation';
import { ShipmentReturnDto } from '../../services/shipment-service/shipment.service';
import { ShipmentType } from '../../shared/backend';
import { useTranslation } from 'react-i18next';

type Props = {
  type: ShipmentType;
  shipments: ShipmentReturnDto[];
  isLoading: boolean;
};

const ShipmentTable: React.FC<Props> = ({ type, shipments, isLoading }) => {
  const { t } = useTranslation();
  const auth = useAuthentication();
  const { navigateTo } = useHistoryNavigation();

  const [page, setPage] = useIntParam('page', 0);
  const [rowsPerPage, setRowsPerPage] = useIntParam('pageSize', 5);

  const columns: TDataTableColumn<ShipmentReturnDto>[] = [
    {
      title: t('Status'),
      field: 'state',
      render: (rowData) => {
        const shipmentState = rowData.state;

        return (
          <>
            <ShipmentStateIcon state={shipmentState}></ShipmentStateIcon>
          </>
        );
      },
    },
    {
      title: t('Online store'),
      render: (rowData) => rowData.process.customer.company,
    },
    {
      title: t('Customer Facing No.'),
      render: (rowData) => rowData.order.customerFacingId,
    },
    {
      title: t('Customer name'),
      render: (rowData) => rowData.order.name,
    },
    {
      title: t('City'),
      render: (rowData) => rowData.order.city ?? '',
    },
  ];

  columns.push(
    type === ShipmentType.SHIPMENT
      ? {
          title: t('Ordered on'),
          render: (rowData) =>
            new Date(rowData.order.externalCreatedAt ?? rowData.order.createdAt).toLocaleDateString(),
        }
      : {
          title: t('Processed on'),
          render: (rowData) => (rowData.processedAt ? new Date(rowData.processedAt).toLocaleDateString() : '-'),
        },
  );

  const actions: TDataTableAction<ShipmentReturnDto>[] = auth.isStaff()
    ? [
        {
          icon: () => <EditIcon />,
          tooltip: t('Edit'),
          onClick: ({ shipmentId }) => navigateTo(`/shipment/${shipmentId}`),
        },
      ]
    : [
        {
          icon: () => <ListAltIcon />,
          tooltip: t('Show return'),
          onClick: ({ shipmentId }) => navigateTo(`/shipment/${shipmentId}`),
        },
      ];

  return (
    <Paper>
      <DataTable
        page={page}
        columns={columns}
        rows={shipments}
        actions={actions}
        isLoading={isLoading}
      />

      <TablePagination
        component="div"
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 20, 30]}
        onRowsPerPageChange={(ev) => setRowsPerPage(parseInt(ev.target.value, 10))}
        page={page}
        count={shipments.length < rowsPerPage ? page * rowsPerPage + shipments.length : -1}
        onPageChange={(ev, page) => setPage(page)}
        nextIconButtonProps={{
          disabled: shipments.length < rowsPerPage,
        }}
      />
    </Paper>
  );
};

export default ShipmentTable;
