import {
  Box,
  Button,
  CircularProgress,
  Fade,
  FormControl,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { ShipmentFormProps } from '../shipmentView';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { FormHelperText } from '@mui/material';
import ScaleIcon from '@mui/icons-material/Scale';
import { ShipmentDto } from '../../../../../../../../services/shipment-service/shipment.service';
import { OrderType, ShipmentState, ShipmentType } from '../../../../../../../../shared/backend';
import { Warning } from '@mui/icons-material';
import { checkWeightBoundaries } from '../WeightWarningDialog';
import { useTranslation } from 'react-i18next';
import { confirmGlobalSubmitWarningDialog } from '../GlobalSubmitWarningDialog';

export function parseShipmentWeightText(text: string | null): { valid: boolean; weight: number | null } {
  if (text === null || text.length === 0) {
    return { valid: true, weight: null };
  }
  text = text.trim();
  if (text.length > 6 || !/^(\d+)$/.test(text)) {
    return { valid: false, weight: null };
  }
  // allows an explicit weight of 0
  const weight = parseInt(text, 10);
  return { valid: true, weight };
}

export function isShipmentWeightTextValid(text: string | null): boolean {
  const result = parseShipmentWeightText(text);
  // allows an explicit weight of 0
  return result.valid && result.weight !== null;
}

interface ShipmentWeightProps {
  setBlockScaleInputFromChild: (value: boolean) => void;
  setShippingWeightSavedFromChild: (value: boolean) => void;
  onUpdateShipment: (shipmentWeight: number) => Promise<void>;
  onUpdateForm: (shipmentWeight: number | null) => Promise<void>;
  shipment: ShipmentDto;
  shipmentForm: ShipmentFormProps;
  handlePrint: () => Promise<void>;
  handleAcceptShipment: () => Promise<void>;
  autoFocus?: boolean;
  shippingWeightSaved: boolean | undefined;
  handleFormChange: (prop: keyof ShipmentFormProps) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  orderType: OrderType;
  disableShipmentEditing: boolean;
  isScaleConnected?: boolean;
  minWeight: number | null;
  maxWeight: number | null;
}

export const ShipmentWeight: React.FC<ShipmentWeightProps> = (props: ShipmentWeightProps) => {
  const { t } = useTranslation();

  const shippingWeightInputFieldRef = useRef<HTMLInputElement>(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const [hasFocus, setHasFocus] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isError = !isShipmentWeightTextValid(props.shipmentForm.shippingWeight);
  const { weight } = parseShipmentWeightText(props.shipmentForm.shippingWeight);

  const { isTooLight, isTooHeavy } = checkWeightBoundaries(weight, props.minWeight, props.maxWeight);

  const handleShippingWeightBlur = async (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setHasFocus(false);

    if (isSubmitting) {
      return;
    }

    const weightText = event.target.value;
    const result = parseShipmentWeightText(weightText);

    if (!result.valid) {
      props.onUpdateForm(null);
      return;
    }

    // allows an explicit weight of 0
    if (result.weight === null || result.weight === props.shipment.shippingWeight) {
      return;
    }

    await props.onUpdateShipment(result.weight);
    props.setShippingWeightSavedFromChild(true);
  };

  const handleShippingWeightSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    try {
      setIsSubmitting(true);

      props.setBlockScaleInputFromChild(true);

      const weightText = props.shipmentForm.shippingWeight;
      const result = parseShipmentWeightText(weightText);

      if (!result.valid) {
        props.onUpdateForm(null);
        return;
      }

      // allows an explicit weight of 0
      if (result.weight === null) {
        return;
      }

      await props.onUpdateShipment(result.weight);

      if (props.shipment.type === ShipmentType.SHIPMENT && !props.shipment.foreignOutboundTrackingId) {
        await props.handlePrint();
      } else if (props.shipment.state === ShipmentState.NEW && props.shipment.foreignOutboundTrackingId) {
        await props.handleAcceptShipment();
      }

      props.setShippingWeightSavedFromChild(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (props.autoFocus && !props.disableShipmentEditing) {
      shippingWeightInputFieldRef.current?.focus();
    }
  }, [props.autoFocus, props.disableShipmentEditing]);

  useEffect(() => {
    if (!props.autoFocus || props.disableShipmentEditing) {
      return;
    }

    const onKeyPress = async (ev: KeyboardEvent) => {
      if (ev.target === document.body && ev.key === 'Enter' && !isSubmitting) {
        if (await confirmGlobalSubmitWarningDialog({ shipmentLabel: props.shipment.shipmentId.toString() })) {
          submitButtonRef.current?.click();
        }
      }
    };

    window.addEventListener('keypress', onKeyPress);

    return () => {
      window.removeEventListener('keypress', onKeyPress);
    };
  }, [isSubmitting, props.autoFocus, props.disableShipmentEditing, props.shipment.shipmentId]);

  const minWeight = props.minWeight;
  const maxWeight = props.maxWeight;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: '10px', height: '100%' }}>
      <form onSubmit={handleShippingWeightSubmit}>
        <FormControl
          variant="standard"
          style={{ marginTop: '20px' }}
          error={isError}
        >
          <TextField
            inputRef={shippingWeightInputFieldRef}
            sx={{
              width: '150px',
              fontSize: '1.1rem',
            }}
            value={props.shipmentForm.shippingWeight ?? ''}
            autoFocus={props.autoFocus}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {props.shippingWeightSaved ? (
                    <Fade
                      in={props.shippingWeightSaved}
                      onEntered={() => setTimeout(() => props.setShippingWeightSavedFromChild(false), 1500)}
                    >
                      <CheckCircleIcon color="success" />
                    </Fade>
                  ) : isSubmitting ? (
                    <CircularProgress size="1em" />
                  ) : (
                    props.isScaleConnected && <ScaleIcon />
                  )}
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {isTooLight ? (
                    <Tooltip
                      title={t('The lower weight limit is {{minWeight}}g. Is the weight really correct?', {
                        minWeight,
                      })}
                    >
                      <Warning color="warning" />
                    </Tooltip>
                  ) : isTooHeavy ? (
                    <Tooltip
                      title={t('The upper weight limit is {{maxWeight}}g. Is the weight really correct?', {
                        maxWeight,
                      })}
                    >
                      <Warning color="warning" />
                    </Tooltip>
                  ) : (
                    <Typography variant="h6">g</Typography>
                  )}
                </InputAdornment>
              ),
            }}
            //label="Gewicht"
            onChange={props.handleFormChange('shippingWeight')}
            onBlur={(event) => handleShippingWeightBlur(event)}
            onFocus={() => {
              if (!props.disableShipmentEditing) {
                setHasFocus(true);
              }
            }}
            variant="outlined"
            error={isError}
            disabled={props.disableShipmentEditing || isSubmitting}
          />
          <FormHelperText id="shippingWeight-helper">
            {isError ? (
              t('Please enter in grams')
            ) : props.autoFocus && hasFocus ? (
              <>&#9166; {t('Hit enter to accept')}</>
            ) : (
              ' '
            )}
          </FormHelperText>
        </FormControl>

        {/* I tried to submit the form directly, but the onSubmit handler was not triggered */}
        <Button
          type="submit"
          sx={{ display: 'none' }}
          ref={submitButtonRef}
        ></Button>
      </form>
    </Box>
  );
};
