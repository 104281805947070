import { CircularProgress, Fade, IconButton, InputAdornment, TextField, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { ShipmentDto, ShipmentService } from '../../services/shipment-service/shipment.service';
import { ScaleContext, scaleContext } from '../../contexts/ScaleContext';
import ScaleIcon from '@mui/icons-material/Scale';
import { useNotifications } from '../../hooks/useNotifications';
import { OrderService } from '../../services/order-service/order.service';
import { equalDeltaPercentage } from '../../shared/helper/helper';
import { useAuthentication } from '../../hooks/useAuthentication';
import { Warning } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

export function parseShipmentWeightText(text: string | null): { valid: boolean; weight: number | null } {
  if (text === null || text.length === 0) {
    return { valid: true, weight: null };
  }
  text = text.trim();
  if (text.length > 6 || !/^(\d+)$/.test(text)) {
    return { valid: false, weight: null };
  }
  // allows an explicit weight of 0
  const weight = parseInt(text, 10);
  return { valid: true, weight };
}

export function isShipmentWeightTextValid(text: string | null): boolean {
  const result = parseShipmentWeightText(text);
  // allows an explicit weight of 0
  return result.valid && result.weight !== null;
}

type Props = {
  onSubmit: (weight: number) => void;
  shipment: ShipmentDto;
  disabled: boolean;
  totalOrderWeight: number;
};

const ShipmentWeight: React.FC<Props> = ({ shipment, disabled, onSubmit, totalOrderWeight }) => {
  const { t } = useTranslation();
  const auth = useAuthentication();
  const notifications = useNotifications();
  const scale = React.useContext<ScaleContext>(scaleContext);
  const { mutate } = OrderService.useOrderByShipmentId(shipment.shipmentId);

  const inputRef = useRef<HTMLInputElement>();
  const [weight, setWeight] = useState<number | null>(shipment.shippingWeight);
  const [isSaving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);

  const saveWeight = async () => {
    if (weight === shipment.shippingWeight) {
      return;
    }

    setSaving(true);

    try {
      if (weight !== null) {
        await ShipmentService.updateShipment(shipment.shipmentId, { shippingWeight: weight });
      }

      await mutate();

      setSaving(false);
      setSaved(true);
    } catch (err) {
      setSaving(false);

      notifications.addError(err);
    }
  };

  useEffect(() => {
    setWeight(shipment.shippingWeight);
  }, [shipment.shippingWeight]);

  useEffect(() => {
    if (scale.weight && scale.isStable && !weight) {
      setWeight(scale.weight);
    }
  }, [scale.weight, scale.isStable, weight]);

  const isError = !!weight && !isFinite(weight);
  const isSameWeight = weight && totalOrderWeight && equalDeltaPercentage(weight, totalOrderWeight, 0.05);
  const isLargerThanTotalOrder = weight !== null && weight / totalOrderWeight > 1.05;
  const scaleWeight = scale.weight;

  return (
    <form
      onSubmit={async (ev) => {
        ev.preventDefault();

        if (weight !== null) {
          await saveWeight();
          onSubmit(weight);
        }
      }}
    >
      <TextField
        inputRef={inputRef}
        sx={{ width: '150px' }}
        value={weight ?? ''}
        autoFocus={true} // If the ean field also gets focus, it has precedence, so we can always set the focus here
        placeholder={t('Weight')}
        inputProps={{ tabIndex: 1, style: { textAlign: 'right' } }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {isSaving ? (
                <CircularProgress size="1.5em" />
              ) : saved ? (
                <Fade
                  in={saved}
                  onEntered={() => setTimeout(() => setSaved(false), 1500)}
                >
                  <CheckCircleIcon color="success" />
                </Fade>
              ) : (
                scale.connected &&
                (scale.weight && scale.weight !== weight && scale.isStable ? (
                  <Tooltip title={t('Insert scale value ({{weight}}g)', { weight: scaleWeight })}>
                    <IconButton
                      color="warning"
                      onClick={() => setWeight(scale.weight ?? null)}
                      edge="start"
                    >
                      <ScaleIcon />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <ScaleIcon />
                ))
              )}
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {isLargerThanTotalOrder ? (
                <Tooltip
                  title={t('The complete order weighs only {{weight}}g. Is the weight really correct?', {
                    weight: totalOrderWeight,
                  })}
                >
                  <Warning color="warning" />
                </Tooltip>
              ) : (
                <Tooltip
                  title={
                    isSameWeight && auth.isStaff()
                      ? t('Same weight as the outbound shipment. You can just accept the shipment and hit ENTER.')
                      : ''
                  }
                >
                  <Typography
                    sx={{ display: 'inline-block', width: '1em' }}
                    variant="h6"
                  >
                    {isSameWeight && auth.isStaff() ? '🎉' : 'g'}
                  </Typography>
                </Tooltip>
              )}
            </InputAdornment>
          ),
        }}
        onChange={(ev) => {
          if (!ev.target.value) {
            setWeight(null);
          } else {
            const parsedWeight = parseInt(ev.target.value, 10);

            if (isFinite(parsedWeight)) {
              setWeight(parsedWeight);
            }
          }
        }}
        onBlur={() => {
          saveWeight();
        }}
        variant="outlined"
        error={isError}
        disabled={disabled || isSaving}
      />
    </form>
  );
};

export default ShipmentWeight;
