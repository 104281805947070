import { Alert, AlertTitle, Box, Button, IconButton, Stack, TextField } from '@mui/material';
import React, { FC } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DeleteIcon from '@mui/icons-material/Delete';
import ButtonAsync from '../../../../shared/components/ButtonAsync';
import { ProcessService, ProcessWithCustomerDto } from '../../../../services/process-service/process.service';
import { useNavigate } from 'react-router-dom';
import { useNotifications } from '../../../../hooks/useNotifications';
import { useTranslation } from 'react-i18next';

export interface ProcessDeletionProps {
  process: ProcessWithCustomerDto;
}

const ProcessDeletion: FC<ProcessDeletionProps> = ({ process }) => {
  const { t } = useTranslation('CRM');

  const navigateTo = useNavigate();
  const notifications = useNotifications();

  const [isActive, setIsActive] = React.useState<boolean>(false);
  const [isRunning, setIsRunning] = React.useState<boolean>(false);
  const [company, setCompany] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');

  const isCompanyError = !!company && company !== process.customer.company;

  const handleDeleteProcess = async () => {
    setIsRunning(true);

    try {
      await ProcessService.deleteProcess(process.processId, company, password);

      setPassword('');
      navigateTo(`/crm/customers/${process.customerId}`);
    } catch (error) {
      notifications.addError(error);
    } finally {
      setIsRunning(false);
    }
  };

  return (
    <div>
      {isActive ? (
        <>
          <Alert
            severity="error"
            sx={{ mb: 3 }}
          >
            <AlertTitle>{t('Attention')}</AlertTitle>

            <Box
              component="ul"
              sx={{ pl: 3 }}
            >
              <li>{t('Currently, when a process is deleted, its tours are not archived!')}</li>
              <li>{t('The deletion process can take up to one minute.')}</li>
            </Box>
          </Alert>

          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
          >
            <TextField
              size="small"
              variant="outlined"
              label={t('Company name')}
              value={company}
              onChange={(ev) => setCompany(ev.target.value)}
              error={isCompanyError}
            />

            {/* This prevents chrome to autofill both inputs. Additionally we do not want
                to delete  a process on submit, therefore this is fine. */}
            <form onSubmit={(ev) => ev.preventDefault()}>
              <TextField
                size="small"
                variant="outlined"
                type="password"
                label={t('Log-in password')}
                value={password}
                onChange={(ev) => setPassword(ev.target.value)}
              />
            </form>

            <ButtonAsync
              variant="contained"
              color="error"
              disabled={!company || !password || isCompanyError}
              onClick={() => handleDeleteProcess()}
              startIcon={<DeleteForeverIcon />}
            >
              {t('Really delete process now')}
            </ButtonAsync>

            <IconButton
              disabled={isRunning}
              onClick={() => setIsActive(false)}
            >
              <CancelIcon />
            </IconButton>
          </Stack>

          {isCompanyError && (
            <Alert
              severity="warning"
              sx={{ mt: 2 }}
            >
              {t('Company name is wrong.')}
            </Alert>
          )}
        </>
      ) : (
        <Button
          variant="contained"
          color="error"
          disabled={isRunning}
          onClick={() => setIsActive(true)}
          startIcon={<DeleteIcon />}
        >
          {t('Delete process')}
        </Button>
      )}
    </div>
  );
};

export default ProcessDeletion;
