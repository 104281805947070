import { Stack, Typography } from '@mui/material';
import React from 'react';
import { ShipmentService } from '../../services/shipment-service/shipment.service';
import { useTranslation } from 'react-i18next';

type Props = {};

const ReturnShipmentCounter: React.FC<Props> = () => {
  const { t } = useTranslation();

  const counter = ShipmentService.useReturnShipmentCounter();

  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant="body1">{t('Return parcel no.')}</Typography>
      <Typography variant="body2">
        <strong>{counter.value ?? '?'}</strong>
      </Typography>
    </Stack>
  );
};

export default ReturnShipmentCounter;
