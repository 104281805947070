import React from 'react';
import { TourAndChecksDto } from '../../../../services/tour-service/tour.service';
import { TourType, ShipmentReturnHandling } from '../../../../shared/backend';
import TourWarningIcon, { TourWarningType } from '../../TourWarningIcon';
import QrCodeIcon from '@mui/icons-material/QrCode';
import DiamondIcon from '@mui/icons-material/Diamond';
import GradeIcon from '@mui/icons-material/Grade';
import { Chip, Tooltip } from '@mui/material';

type Props = {
  tour: TourAndChecksDto['tour'];
  checks: TourAndChecksDto['checks'];
};

const TourWarningsCell: React.FC<Props> = ({ tour, checks }) => {
  return (
    <>
      {tour.process.usesVoecHandling && (
        <Tooltip title="Tour uses Norway VOEC">
          <Chip
            size="small"
            label="VOEC"
          />
        </Tooltip>
      )}

      {tour.type === TourType.RETOUR &&
        tour.process.returnPackageHandling === ShipmentReturnHandling.SPECIAL_TREATMENT && (
          <Tooltip title="Special return treatment">
            <GradeIcon
              titleAccess="Spezialbehandlung"
              color="info"
            />
          </Tooltip>
        )}

      {(checks?.faultyShipmentWeight || !!checks?.missingShipmentWeights?.length) && (
        <Tooltip title={checks?.missingShipmentWeights.length ? 'Missing shipment weights' : 'Faulty shipment weights'}>
          <TourWarningIcon
            type={
              !!checks?.missingShipmentWeights.length
                ? TourWarningType.MISSING_SHIPPING_WEIGHTS
                : TourWarningType.FAULTY_SHIPPING_WEIGHTS
            }
          />
        </Tooltip>
      )}

      {(checks?.missingExportoBarcode.length ?? 0) > 0 && (
        <Tooltip title="Exporto barcode missing">
          <QrCodeIcon color="warning" />
        </Tooltip>
      )}

      {(checks?.blockedArticleIds.length ?? 0) > 0 && (
        <Tooltip title="Blocked articles">
          <TourWarningIcon type={TourWarningType.ARTICLE_BLOCKED} />
        </Tooltip>
      )}

      {Object.keys(checks?.articlesWithWarnings ?? {}).length > 0 && (
        <Tooltip title="Articles with warnings">
          <TourWarningIcon type={TourWarningType.ARTICLE_WARNING} />
        </Tooltip>
      )}

      {(checks?.missingArticles.length ?? 0) > 0 && (
        <Tooltip title="Missing articles">
          <TourWarningIcon type={TourWarningType.ARTICLE_MISSING} />
        </Tooltip>
      )}

      {(checks?.incompleteArticleValues.length ?? 0) > 0 && (
        <Tooltip title="Incomplete articles">
          <TourWarningIcon type={TourWarningType.ARTICLE_INCOMPLETE} />
        </Tooltip>
      )}

      {(checks?.invalidArticleValues ? Object.entries(checks.invalidArticleValues).length > 0 : false) && (
        <Tooltip title="Invalid articles">
          <TourWarningIcon type={TourWarningType.ARTICLE_INVALID} />
        </Tooltip>
      )}

      {(checks?.emptyShipments.length ?? 0) > 0 && (
        <Tooltip title={checks.emptyShipments.length >= tour.shipments.length ? 'Empty tour' : 'Empty shipments'}>
          <TourWarningIcon
            type={
              checks.emptyShipments.length >= tour.shipments.length
                ? TourWarningType.TOUR_EMPTY
                : TourWarningType.SHIPMENT_EMPTY
            }
          />
        </Tooltip>
      )}

      {Object.keys(checks?.shipmentLineItemsPriceWarnings ?? {}).length > 0 && (
        <Tooltip title="Not derivable line item price">
          <TourWarningIcon type={TourWarningType.NOT_DERIVABLE_LINE_ITEM_PRICE} />
        </Tooltip>
      )}

      {(checks?.shipmentsWithEmk.length ?? 0) > 0 && (
        <Tooltip title="Shipments with EMK articles">
          <DiamondIcon color="info" />
        </Tooltip>
      )}
    </>
  );
};

export default TourWarningsCell;
