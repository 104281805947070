import React from 'react';
import { useBooleanParam, useEnumListParam, useIntParam, useStringParam } from '../../hooks/useParam';
import { Alert, AlertTitle, Box, Grid, Stack } from '@mui/material';
import ShipmentTable from './ShipmentTable';
import InboundShipmentSearch from './InboundShipmentSearch';
import ShipmentStateLegend from '../shipment/ShipmentStateLegend';
import { useAuthentication } from '../../hooks/useAuthentication';
import { useProcessIdsFromCustomerParam } from '../../hooks/useProcess';
import { ShipmentState, ShipmentType } from '../../shared/backend';
import { ShipmentService } from '../../services/shipment-service/shipment.service';
import ScaleStatus from '../../shared/components/scale/ScaleStatus';
import { useLastReturn } from '../../shared/hooks/last-return';
import { DateTime } from 'luxon';
import LastReturn from './LastReturn';
import LazyMotivationQuote from '../../shared/components/LazyMotivationQuote';
import { useTranslation } from 'react-i18next';

type Props = {};

const InboundShipments: React.FC<Props> = () => {
  const { t } = useTranslation();
  const auth = useAuthentication();
  const [lastReturn, setLastReturn] = useLastReturn();

  const [searchQuery] = useStringParam('q', '');
  const [useExtendedSearchOverAllFields] = useBooleanParam('extended', false);

  const [page] = useIntParam('page', 0);
  const [pageSize] = useIntParam('pageSize', 5);
  const { processIds, processTypeIds } = useProcessIdsFromCustomerParam();
  const [shipmentStates] = useEnumListParam('states', ShipmentState, []);

  const inboundShipments = ShipmentService.useShipments({
    search: searchQuery,
    limit: pageSize,
    offset: page * pageSize,
    processIds,
    processTypeIds,
    shipmentStates,
    useExtendedSearchOverAllFields,
  });

  const isOutboundSearch =
    !!searchQuery && inboundShipments.data?.length === 0 && page === 0 && shipmentStates.length === 0;

  const outboundShipments = ShipmentService.useShipments(
    isOutboundSearch
      ? {
          search: searchQuery,
          limit: 100,
          processIds,
          processTypeIds,
          useExtendedSearchOverAllFields,
          // we could additionally filter for closed, but we want to make sure
          // that we find only shipments for one order
        }
      : null,
    ShipmentType.SHIPMENT,
  );

  const distinctOutboundOrderIds = outboundShipments.data
    ? [...new Set(outboundShipments.data.map(({ orderId }) => orderId))]
    : undefined;
  const areOutboundShipmentsHavingSameOrder = !!distinctOutboundOrderIds?.length
    ? distinctOutboundOrderIds.length === 1
    : undefined;
  const firstValidOutboundShipment = areOutboundShipmentsHavingSameOrder
    ? outboundShipments.data?.find(
        (shipment) => shipment.state === ShipmentState.CLOSED && shipment.shippingWeight !== 0,
      )
    : undefined;

  return (
    <Grid
      container
      spacing={3}
    >
      {auth.isStaff() && (
        <Grid
          item
          xs={12}
        >
          <Box
            mt={-1}
            mb={-1}
            display="flex"
            justifyContent="flex-end"
          >
            <ScaleStatus />
          </Box>
        </Grid>
      )}

      <Grid
        item
        xs={12}
      >
        <InboundShipmentSearch
          isLoading={inboundShipments.isLoading || outboundShipments.isLoading}
          inboundShipment={inboundShipments.data?.length === 1 ? inboundShipments.data[0] : undefined}
          outboundShipment={firstValidOutboundShipment}
          mutate={async () => {
            await inboundShipments.mutate();
          }}
        />
      </Grid>

      <Grid
        item
        xs={12}
      >
        <Stack
          direction="column"
          spacing={3}
        >
          {!!lastReturn && DateTime.fromJSDate(lastReturn.processedAt).diffNow('hours').hours > -1 && (
            <LastReturn
              shipmentId={lastReturn.shipmentId}
              onClose={() => setLastReturn(null)}
            />
          )}

          {areOutboundShipmentsHavingSameOrder === false && (
            <Alert severity="warning">
              <AlertTitle>{t('Multiple orders found')}</AlertTitle>
              {t('Several different orders ({{orderIds}}) were found for the return TrackingId.', {
                orderIds: distinctOutboundOrderIds?.join(', '),
              })}
            </Alert>
          )}
          {areOutboundShipmentsHavingSameOrder && !firstValidOutboundShipment && (
            <Alert severity="warning">
              <AlertTitle>{t('Unprocessed order found')}</AlertTitle>
              {t(
                'Shipments {{shipmentIds}} are not completed or have no weight. Therefore, no returns for order {{orderId}} can be processed.',
                {
                  shipmentIds: outboundShipments.data?.map(({ shipmentId }) => shipmentId).join(', ') ?? '???',
                  orderId: (outboundShipments.data ?? [])[0]?.orderId ?? '???',
                },
              )}
            </Alert>
          )}

          {!!firstValidOutboundShipment &&
            (firstValidOutboundShipment.process.blocked ? (
              <Alert severity="info">
                <AlertTitle>{t('Do not process shipment!')}</AlertTitle>
                {t('The store is blocked.')}
              </Alert>
            ) : (
              <Alert severity="info">
                <AlertTitle>{t('Tracking ID found')}</AlertTitle>
                {t('To make a registration, please press Enter.')}
              </Alert>
            ))}

          {!firstValidOutboundShipment && (
            <ShipmentTable
              type={ShipmentType.RETOUR}
              shipments={inboundShipments.data ?? []}
              isLoading={inboundShipments.isLoading}
            />
          )}
        </Stack>
      </Grid>

      <Grid
        mt={5}
        item
        xs={12}
      >
        <Box
          component="details"
          sx={{ color: 'text.disabled' }}
        >
          <Box
            component="summary"
            sx={{ cursor: 'pointer' }}
          >
            Legende
          </Box>

          <ShipmentStateLegend />
        </Box>

        {auth.isStaff() && (
          <Box my={3}>
            <LazyMotivationQuote />
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default InboundShipments;
