import React from 'react';
import { LineItemDto } from '../../services/line-item-service/lineItem.service';
import { TableRow, TableCell, Typography, Button, Stack, Box, Tooltip } from '@mui/material';
import { SplitLineItem } from '../orders/shared/components/order-detail-modal/components/shipment-view/components/SplitLineItem';
import { useAuthentication } from '../../hooks/useAuthentication';
import { ArticleService } from '../../services/article-service/article.service';
import AssignmentReturnedIcon from '@mui/icons-material/AssignmentReturned';
import { OrderService } from '../../services/order-service/order.service';
import { useDebugView } from '../../shared/hooks/dev-mode';
import { Info } from '@mui/icons-material';
import { ShipmentService } from '../../services/shipment-service/shipment.service';
import { OrderType } from '../../shared/backend';
import { useTranslation } from 'react-i18next';

type Props = {
  shipmentId: number;
  lineItem: LineItemDto;
  assignLineItemToShipment: (lineItemId: number) => void;
  disabled: boolean;
  unconsigned?: boolean;
};

const LineItemUnassignedRow: React.FC<Props> = ({
  shipmentId,
  lineItem,
  assignLineItemToShipment,
  disabled,
  unconsigned,
}) => {
  const { t } = useTranslation();
  const [debugView] = useDebugView();
  const auth = useAuthentication();
  const order = OrderService.useOrderByShipmentId(shipmentId);
  const outgoingShipments = ShipmentService.useShipmentsByOrder(order.data?.orderId, OrderType.ORDER);

  const article = ArticleService.useArticleByExternalArticleId(
    lineItem.processId,
    lineItem.externalArticleId,
    auth.isStaff(),
  );

  return (
    <TableRow sx={unconsigned ? { backgroundColor: 'action.disabledBackground' } : undefined}>
      <TableCell padding="checkbox">
        {article.data ? (
          <Tooltip
            title={
              <Box
                component="ul"
                sx={{ m: 0, p: 0, listStyleType: 'none' }}
              >
                {article.data.ean && <li>EAN: {article.data.ean}</li>}
                {article.data.imageUrl && (
                  <li>
                    <a
                      href={article.data.imageUrl}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {article.data.imageUrl}
                    </a>
                  </li>
                )}
                <li>{article.data.description}</li>
              </Box>
            }
          >
            <Info color="info" />
          </Tooltip>
        ) : undefined}
      </TableCell>
      <TableCell>
        <Typography variant="body2">
          {lineItem.externalArticleId && auth.isOffice() ? (
            <Box
              component="a"
              href={
                article.data?.articleId
                  ? `/articles?articleId=${article.data.articleId}`
                  : `/articles?search=${lineItem.externalArticleId}`
              }
              sx={{ textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}
              color="inherit"
            >
              {lineItem.name || article.data?.name}
            </Box>
          ) : (
            lineItem.name || article.data?.name
          )}
        </Typography>
        {article.data?.name && article.data?.name !== lineItem.name && (
          <Typography variant="body1">{article.data?.name}</Typography>
        )}
        {debugView && !!article.data?.ean && <Typography variant="body1">{article.data.ean}</Typography>}
      </TableCell>
      {!auth.isPicker() && (
        <TableCell>
          <Typography
            variant="h6"
            component="p"
          >
            {lineItem.priceGross}
          </Typography>
        </TableCell>
      )}
      <TableCell sx={{ width: 1 / 5 }}>
        <Stack
          display="flex"
          direction="row"
          alignItems="center"
        >
          <Typography
            variant="h6"
            component="p"
            display="inline"
          >
            {lineItem.quantity}
          </Typography>
          {!disabled && (
            <SplitLineItem
              item={lineItem}
              reloadShipments={async () => {
                await order.mutate();
                await outgoingShipments.mutate();
              }}
            />
          )}
        </Stack>
      </TableCell>
      {auth.isStaff() && (
        <TableCell align="right">
          <Button
            onClick={() => assignLineItemToShipment(lineItem.lineItemId)}
            size="small"
            variant="outlined"
            color="secondary"
            startIcon={<AssignmentReturnedIcon />}
            disabled={disabled}
          >
            {t('Assign')}
          </Button>
        </TableCell>
      )}
    </TableRow>
  );
};

export default LineItemUnassignedRow;
