import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import resourcesToBackend from 'i18next-resources-to-backend';

export const supportedLangs = ['en', 'de'];

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(resourcesToBackend((language: string, namespace: string) => import(`./${language}/${namespace}.json`)))
  .init({
    detection: {
      order: ['localStorage', 'navigator'],
      lookupLocalStorage: 'user:lang',
      caches: ['localStorage'],
    },
    // allows . in keys
    keySeparator: false,
    // allows : in keys
    nsSeparator: false,
    supportedLngs: supportedLangs,
    // resources: LANG_RESOURCES,
    defaultNS: 'shared',
    // use the key as fallback
    fallbackLng: 'en',
    // allow an empty value to count as invalid (by default is true)
    returnEmptyString: false,
  });

export default i18n;
