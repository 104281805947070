import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material';
import React, { FC, Fragment, useState } from 'react';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PrintIcon from '@mui/icons-material/Print';
import { useNotifications } from '../../../../../hooks/useNotifications';
import CustomerForm from './components/customer-form/customerForm';
import Switch from '@mui/material/Switch';
import BuildIcon from '@mui/icons-material/Build';
import PeopleIcon from '@mui/icons-material/People';
import CustomerDeletion from './components/customer-deletion/CustomerDeletion';
import {
  CreateCustomerDto,
  CustomerDto,
  CustomerService,
  IDeleteCustomerResponse,
  UpdateCustomerDto,
} from '../../../../../services/customer-service/customer.service';
import { Link, useNavigate, useParams } from 'react-router-dom';
import CustomerAddressCard from './components/customer-address-card/customerAddressCard';
import CustomerAssets from './components/CustomerAssets';
import devCat from '../../../../../assets/img/dev-cat.jpg';
import ErrorPage from '../../../../../shared/components/ErrorPage';
import { Helmet } from 'react-helmet-async';
import BreadcrumbsGridItem from '../../../BreadcrumbsGridItem';
import CustomerProcesses from '../CustomerProcesses';
import { UserTable } from '../../../../users/components/userTable';
import ButtonAsync from '../../../../../shared/components/ButtonAsync';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import { impersonateCustomer } from '../../../../../services/authentication-service/authentication.service';
import { useTranslation } from 'react-i18next';

export interface CustomerDetailsProps {
  new: boolean;
}

const CustomerDetails: FC<CustomerDetailsProps> = (props: CustomerDetailsProps) => {
  const notificationHandler = useNotifications();
  const navigate = useNavigate();
  const { customerId } = useParams();
  const { t } = useTranslation('CRM');

  let parsedCustomerId = customerId && !isNaN(parseInt(customerId, 10)) ? parseInt(customerId, 10) : null;
  const customer = CustomerService.useCustomer(parsedCustomerId);

  const [isDevelopmentMode, setIsDevelopmentMode] = useState<boolean>(false);

  const handleCustomerCreate = async (body: CreateCustomerDto): Promise<CustomerDto | undefined> => {
    try {
      const customer = await CustomerService.createCustomer(body);
      if (customer) {
        navigate(`/crm/customers/${customer.customerId}`);
      }
      return customer;
    } catch (error) {
      notificationHandler.addError(error);
    }
  };

  const handleCustomerUpdate = async (body: UpdateCustomerDto): Promise<CustomerDto | undefined> => {
    if (customer.data) {
      try {
        const newCustomer = await CustomerService.updateCustomer(body, customer.data.customerId);
        if (newCustomer) {
          customer.mutate();
        }
        return newCustomer;
      } catch (error) {
        notificationHandler.addError(error);
      }
    }
  };

  const handleDeleteCustomer = async (company: string, password: string) => {
    const response: IDeleteCustomerResponse = await CustomerService.deleteCustomer(
      Number(customerId),
      company,
      password,
    );
    if (!response.success && response.message) {
      notificationHandler.addInfo(response.message);
      return false;
    }
    notificationHandler.addInfo(t('The customer has been successfully deleted'));
    navigate(`/crm/customers`);

    return true;
  };

  return (
    <Fragment>
      <Helmet>
        <title>
          {props.new
            ? t('New customer')
            : t('{{customerCompany}} | customer', { customerCompany: customer.data?.company })}
        </title>
      </Helmet>

      {!props.new && !customer.isLoading && !customer.data && (
        <ErrorPage
          title={t('Invalid link')}
          content={t('No customer with this ID could be found.')}
        >
          <Button
            variant="contained"
            color="secondary"
            component={Link}
            to="/crm/customers"
          >
            Zur Kundenübersicht
          </Button>
        </ErrorPage>
      )}
      {(props.new || (!customer.isLoading && customer.data)) && (
        <Stack spacing={3}>
          {customer.data && (
            <BreadcrumbsGridItem
              items={[
                { href: '/crm/customers', label: t('Customers') },
                {
                  label: customer.data.company,
                },
              ]}
            />
          )}

          <Box>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                navigate(-1);
              }}
              startIcon={<ArrowBackIcon />}
            >
              Zurück
            </Button>
          </Box>

          <Card sx={{ p: 1 }}>
            <CardHeader
              title={<Typography variant="h5">{props.new ? t('New customer') : customer.data?.company}</Typography>}
              action={
                <FormControlLabel
                  control={
                    <Switch
                      checked={isDevelopmentMode}
                      onChange={(event) => setIsDevelopmentMode(event.target.checked)}
                    />
                  }
                  label={t('Development mode')}
                />
              }
              avatar={
                isDevelopmentMode ? (
                  <Avatar
                    alt="Dev cat"
                    src={devCat}
                    sx={{ transition: 'transform 0.5s', '&:hover': { transform: 'scale(2)' } }}
                  />
                ) : (
                  <AssignmentIndIcon sx={{ fontSize: '2.5rem' }} />
                )
              }
            ></CardHeader>

            <CardContent>
              <CustomerForm
                customer={customer.data}
                onCustomerCreate={handleCustomerCreate}
                onCustomerUpdate={handleCustomerUpdate}
                developmentMode={isDevelopmentMode}
              />
            </CardContent>
          </Card>

          {parsedCustomerId && <CustomerProcesses customerId={parsedCustomerId} />}

          {customer.data && <CustomerAddressCard customerId={customer.data.customerId} />}

          <Card sx={{ p: 1 }}>
            <CardHeader
              title={<Typography variant="h5">{t('Label icons')}</Typography>}
              avatar={<PrintIcon sx={{ fontSize: '2.5rem' }} />}
            />

            {parsedCustomerId && <CustomerAssets customerId={parsedCustomerId} />}
          </Card>

          <Card sx={{ p: 1 }}>
            <CardHeader
              title={<Typography variant="h5">Benutzende</Typography>}
              avatar={<PeopleIcon sx={{ fontSize: '2.5rem' }} />}
              action={
                parsedCustomerId ? (
                  <ButtonAsync
                    size="small"
                    variant="outlined"
                    startIcon={<CoPresentIcon />}
                    onClick={async () => {
                      await impersonateCustomer(parsedCustomerId);

                      window.location.href = '/';

                      return new Promise(() => undefined);
                    }}
                  >
                    {t('Impersonate')}
                  </ButtonAsync>
                ) : undefined
              }
            />

            {parsedCustomerId && <UserTable customerId={parsedCustomerId} />}
          </Card>

          {customer.data && (
            <Card sx={{ p: 1, border: '3px solid', borderColor: 'warning.light' }}>
              <CardHeader
                title={<Typography variant="h5">{t('Administration')}</Typography>}
                avatar={<BuildIcon sx={{ fontSize: '2.5rem' }} />}
              />
              <CustomerDeletion onDeleteCustomer={handleDeleteCustomer} />
            </Card>
          )}
        </Stack>
      )}
      {!props.new && customer.isLoading && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          height="100%"
        >
          <CircularProgress color="secondary" />
        </Stack>
      )}
    </Fragment>
  );
};

export default CustomerDetails;
