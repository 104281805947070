import { Note } from '@mui/icons-material';
import { Alert, AlertTitle, Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  notes: { company: string; value: string }[];
};

const CustomsNotesAlert: React.FC<Props> = ({ notes }) => {
  const { t } = useTranslation();

  return (
    <>
      <Alert
        severity="info"
        icon={<Note />}
      >
        <AlertTitle>{t('Customs notes')}</AlertTitle>
        {notes.length === 1 ? (
          <>
            <strong>{notes[0].company}:</strong> {notes[0].value}
          </>
        ) : (
          <Box
            component="ul"
            margin={0}
            paddingLeft={3}
          >
            {notes.map((note) => (
              <li key={note.company}>
                <strong>{note.company}:</strong> {note.value}
              </li>
            ))}
          </Box>
        )}
      </Alert>
    </>
  );
};

export default CustomsNotesAlert;
