import { Stack, Typography } from '@mui/material';
import React from 'react';
import { ShipmentStateIcon } from '../../../../../../../../shared/components/shipment-state-icon/ShipmentStateIcon';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { ShipmentDto } from '../../../../../../../../services/shipment-service/shipment.service';
import { TourDto } from '../../../../../../../../services/tour-service/dto/tour.dto';
import { useAuthentication } from '../../../../../../../../hooks/useAuthentication';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface OrderClosedProps {
  shipment: ShipmentDto & { tour?: TourDto };
}

export const OrderClosed: React.FC<OrderClosedProps> = (props: OrderClosedProps) => {
  const { t } = useTranslation();
  const auth = useAuthentication();

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
    >
      <ShipmentStateIcon state={props.shipment.state}></ShipmentStateIcon>

      <Typography>{t('shipped')}</Typography>
      {props.shipment.tour && (
        <>
          {(auth.isAdmin() || auth.isCustomsAgent()) && (
            <>
              <ArrowForwardIosIcon sx={{ fontSize: '1em' }} />

              <Typography>
                Tour{' '}
                <Link to={`/customs/tour-details/${props.shipment.tour.tourId}`}>{props.shipment.tour.tourId}</Link>
              </Typography>
            </>
          )}
          <ArrowForwardIosIcon sx={{ fontSize: '1em' }} />
          <Typography>{new Date(props.shipment.tour.createdAt).toLocaleDateString()}</Typography>
        </>
      )}
    </Stack>
  );
};
