import React, { PropsWithChildren, useEffect, useState } from 'react';
import {
  ORIGIN_AREAS,
  ProcessService,
  ProcessWithCustomerDto,
  UpdateProcessDto,
} from '../../../services/process-service/process.service';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Controller, FieldNamesMarkedBoolean, FieldValues, useForm } from 'react-hook-form';
import { Settings } from '@mui/icons-material';
import { useNotifications } from '../../../hooks/useNotifications';
import FormTextField from '../../../shared/components/react-hook-form/FormTextField';
import FormSelectCountryCode from '../../../shared/components/react-hook-form/FormSelectCountryCode';
import { UNKNOWN_COUNTRY_CODE, defaultCommonCountryCodes } from '../../../shared/helper/country';
import FormSelect from '../../../shared/components/react-hook-form/FormSelect';
import * as TaskService from '../../../services/task-service/task.service';
import { IsoCountryCode, ShipmentReturnHandling } from '../../../shared/backend';
import FormCheckbox from '../../../shared/components/react-hook-form/FormCheckbox';

import SelectProcess from '../../../shared/components/SelectProcess';
import FormSchema from '../../../shared/components/react-hook-form/FormSchema';
import { useCustomerAddresses } from '../../../services/address-service/address.service';
import FormSelectAddress from '../../../shared/components/react-hook-form/FormSelectAddress';
import { ProcessTypeService, getProcessTypeLabel } from '../../../services/process-type-service/process-type.service';
import { useAuthentication } from '../../../hooks/useAuthentication';
import ButtonAsync from '../../../shared/components/ButtonAsync';
import { ReauthTimeInMinutes, assertAuthentication } from '../../../shared/components/ReAuthenticationDialog';
import { useTranslation } from 'react-i18next';
import {
  CredentialsCustomsFtpServerDto,
  CredentialsCustomsFtpServerResponseDto,
  ProcessCredentialsService,
} from '../../../services/process-service/process-credentials.service';

const notificationsKey = 'unsaved-process-settings';

// https://github.com/orgs/react-hook-form/discussions/9472#discussioncomment-7947064
function getDirtyFields<TFieldValues extends FieldValues>(
  dirtyFields: Partial<Readonly<FieldNamesMarkedBoolean<TFieldValues>>>,
  formValues: TFieldValues,
): Partial<TFieldValues> {
  if (typeof dirtyFields !== 'object' || dirtyFields === null || !formValues) {
    return {};
  }

  return Object.keys(dirtyFields).reduce((accumulator, key: keyof TFieldValues) => {
    const isDirty = dirtyFields[key as keyof typeof dirtyFields] ?? false;
    const value = formValues[key];

    // If it's an array, apply the logic recursively to each item
    if (Array.isArray(isDirty)) {
      // eslint-disable-next-line no-underscore-dangle
      const _dirtyFields = isDirty.map((item: any, index: number) => getDirtyFields(item, value[index]));
      if (_dirtyFields.length > 0) {
        // eslint-disable-next-line no-param-reassign
        accumulator[key] = _dirtyFields as any;
      }
    }
    // If it's an object, apply the logic recursively
    else if (typeof isDirty === 'object' && isDirty !== null) {
      // eslint-disable-next-line no-param-reassign
      accumulator[key] = getDirtyFields(isDirty, value) as any;
    }
    // If it's a dirty field, get the value from formValues
    else if (isDirty) {
      // eslint-disable-next-line no-param-reassign
      accumulator[key] = value;
    }

    return accumulator;
  }, {} as Partial<TFieldValues>);
}

const FormGridItem: React.FC<PropsWithChildren> = ({ children }) => (
  <Grid
    item
    xs={12}
    sm={6}
    md={3}
    xl={2}
  >
    {children}
  </Grid>
);

type Props = {
  process: ProcessWithCustomerDto;
};

const ProcessSettings: React.FC<Props> = ({ process }) => {
  const { t } = useTranslation('CRM');
  const {
    formState: { dirtyFields, isDirty, isSubmitting },
    reset,
    setValue,
    setError,
    clearErrors,
    ...form
  } = useForm<{
    process: UpdateProcessDto;
    credentials: Record<string, unknown>;
    customsFtpServer: CredentialsCustomsFtpServerResponseDto | null;
  }>({
    mode: 'onTouched',
    defaultValues: {
      process,
      credentials: {},
      customsFtpServer: null,
    },
  });

  const originCountry = form.watch('process.originCountry');
  const originArea = form.watch('process.originArea');
  const trustworthyArticleWeights = form.watch('process.trustworthyArticleWeights');
  const shopSystem = form.watch('process.shopSystem');
  const hubspotDealId = form.watch('process.hubspotDealId');
  const isNonVOECNorway =
    process.processType.destinationCountry === IsoCountryCode.NO && !form.watch('process.usesVoecHandling');

  const auth = useAuthentication();
  const notifications = useNotifications();
  const customerAddresses = useCustomerAddresses(process.customerId);
  const customsFtpServer = ProcessCredentialsService.useCustomsFtpServer(isNonVOECNorway ? process.processId : null);
  const shopSystemApi = ProcessService.useProcessShopSystemApi(process.shopSystem ? process.processId : null);
  const credentialSchema = TaskService.useShopSystemCredentialSchema(shopSystem ?? null);
  const { mutate } = ProcessService.useProcess(process.processId);
  const processTypes = ProcessTypeService.useProcessTypes();
  const shopSystems = TaskService.useShopSystems();
  const [areCredentialsUnmasked, setCredentialsUnmasked] = useState(false);

  const { dispatchCountry, destinationCountry } = process.processType;
  const isGermanyToSwiss = dispatchCountry === IsoCountryCode.DE && destinationCountry === IsoCountryCode.CH;
  const isCustomsRequired = [IsoCountryCode.CH, IsoCountryCode.GB].includes(destinationCountry as IsoCountryCode);
  const isToGreatBritain = destinationCountry === IsoCountryCode.GB;

  const route = [dispatchCountry, destinationCountry];
  const isGermanRoute = route.includes(IsoCountryCode.DE);
  const isSwissRoute = route.includes(IsoCountryCode.CH);
  const isToNorway = destinationCountry === IsoCountryCode.NO;

  const availableProcessTypes = processTypes.data.filter(
    (pt) =>
      pt.destinationCountry === process.processType.destinationCountry &&
      pt.dispatchCountry === process.processType.dispatchCountry,
  );

  useEffect(() => {
    if (isDirty) {
      notifications.addWarning(`Ungespeicherte Änderungen am Prozess`, null, notificationsKey);
    } else {
      notifications.close(notificationsKey);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => notifications.close(notificationsKey), []);

  useEffect(() => {
    reset(
      { credentials: shopSystemApi.data?.config ?? {}, process, customsFtpServer: customsFtpServer.data },
      { keepDirtyValues: true, keepIsSubmitted: true },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, shopSystemApi.data?.updatedAt, process.updatedAt, customsFtpServer.data?.updatedAt]);

  useEffect(() => {
    if (originCountry !== IsoCountryCode.DE) {
      setValue('process.originArea', '99');
    }
  }, [setValue, originCountry]);

  useEffect(() => {
    if ((originArea as string) === '17') {
      setError('process.originArea', { type: 'easteregg', message: 'Hyper Hyper' });
    } else if (originCountry === IsoCountryCode.DE && originArea === '99') {
      setError('process.originArea', { type: 'validate', message: 'Welches BL darf es sein?' });
    } else {
      clearErrors('process.originArea');
    }
  }, [setError, clearErrors, originCountry, originArea]);

  useEffect(() => {
    if (!trustworthyArticleWeights) {
      setValue('process.useArticleWeightsForShippingWeightCalculation', false);
    }
  }, [setValue, trustworthyArticleWeights]);

  const unsavedChangesMessage = t('You have unsaved changes!');

  useEffect(() => {
    const onBeforeUnload = (ev: BeforeUnloadEvent) => {
      // we love web development. Do we?
      ev.returnValue = unsavedChangesMessage;
      return unsavedChangesMessage;
    };

    if (isDirty && !isSubmitting) {
      // this is only triggered on page reloads. To show a warning
      // if the user clicks another link we have to update our router
      // to a data router.
      window.addEventListener('beforeunload', onBeforeUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [isDirty, isSubmitting, unsavedChangesMessage]);

  return (
    <Card sx={{ p: 1 }}>
      <CardHeader
        title={<Typography variant="h5">Konfiguration</Typography>}
        avatar={<Settings sx={{ fontSize: '2.5rem' }} />}
      />

      <CardContent>
        <form
          onSubmit={form.handleSubmit(async (data) => {
            try {
              reset(data);

              const dirtyData = getDirtyFields(dirtyFields, data);

              if (dirtyData.process) {
                await assertAuthentication(ReauthTimeInMinutes.EDIT_PROCESS);

                await ProcessService.putProcess(dirtyData.process, process.processId);

                mutate({ ...process, ...data.process });

                notifications.addSuccess(t('Process updated'));
              }

              if (dirtyData.credentials) {
                await assertAuthentication(ReauthTimeInMinutes.EDIT_PROCESS);

                await ProcessService.updateProcessShopSystemApi({ config: data.credentials }, process.processId);

                shopSystemApi.mutate({ ...shopSystemApi.data!, config: data.credentials });

                notifications.addSuccess(t('Credentials updated'));
              }

              if (dirtyData.customsFtpServer) {
                await assertAuthentication(ReauthTimeInMinutes.EDIT_PROCESS);

                const changedCustomsFtpServer: CredentialsCustomsFtpServerDto = {
                  username: data.customsFtpServer?.username ?? '',
                  password: data.customsFtpServer?.password ?? '',
                  host: data.customsFtpServer?.host ?? '',
                };

                await ProcessCredentialsService.updateCustomsFtpServer(process.processId, changedCustomsFtpServer);

                customsFtpServer.mutate({ ...customsFtpServer.data!, ...changedCustomsFtpServer });

                notifications.addSuccess(t('Customs FTP server updated'));
              }
            } catch (error) {
              notifications.addError(error);
            }
          })}
          noValidate
        >
          <Stack
            direction="column"
            spacing={6}
          >
            <Box>
              <Typography
                variant="h3"
                mb={1}
              >
                Allgemein
              </Typography>

              <Grid
                container
                columnSpacing={1}
                rowSpacing={3}
                my={1}
              >
                <FormGridItem>
                  <FormSelect
                    fullWidth
                    disabled={availableProcessTypes.length < 2}
                    name="process.processTypeId"
                    control={form.control}
                    options={availableProcessTypes.map((pt) => ({
                      label: getProcessTypeLabel(pt),
                      value: pt.processTypeId,
                    }))}
                  />
                </FormGridItem>
                <FormGridItem>
                  <Controller
                    name="process.articleReferenceId"
                    control={form.control}
                    render={({ field: { onChange, value }, fieldState: { isDirty } }) => {
                      return (
                        <SelectProcess
                          fullWidth
                          processId={value ?? null}
                          onChange={onChange}
                          disabled={form.watch('process.articleAutoImportEnabled')}
                          filter={(p) => p.processId !== process.processId && !p.articleReferenceId}
                          label={t('Link article master')}
                          sx={{ input: { color: isDirty ? 'warning.main' : undefined } }}
                        />
                      );
                    }}
                  />
                </FormGridItem>
                <FormGridItem>
                  <FormTextField
                    name="process.hubspotDealId"
                    label={t('Hubspot Deal ID')}
                    fullWidth
                    control={form.control}
                    InputProps={{
                      endAdornment: hubspotDealId ? (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            LinkComponent="a"
                            href={`https://app.hubspot.com/contacts/19829949/record/0-3/${hubspotDealId}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <OpenInNewIcon />
                          </IconButton>
                        </InputAdornment>
                      ) : undefined,
                    }}
                  />
                </FormGridItem>
                <FormGridItem>
                  <FormTextField
                    name="process.debtorId"
                    label={t('Debtor ID')}
                    fullWidth
                    control={form.control}
                  />
                </FormGridItem>
              </Grid>

              <Grid
                container
                columnSpacing={1}
                rowSpacing={0}
                my={1}
              >
                <FormGridItem>
                  <FormCheckbox
                    name="process.printReturnLabel"
                    label={t('Print label + return label')}
                    control={form.control}
                  />
                </FormGridItem>

                <FormGridItem>
                  <FormCheckbox
                    disabled={!form.watch('process.trustworthyArticleWeights')}
                    name="process.useArticleWeightsForShippingWeightCalculation"
                    label={t('Shipment weight calculation with article weights')}
                    control={form.control}
                    description={
                      !form.watch('process.trustworthyArticleWeights')
                        ? t('Requires reliable article weights')
                        : undefined
                    }
                  />
                </FormGridItem>

                <FormGridItem>
                  <FormCheckbox
                    name="process.shipmentWeightImportEnabled"
                    label={t('Use external shipment weight')}
                    control={form.control}
                    disabled={!shopSystem && !form.watch('process.useArticleWeightsForShippingWeightCalculation')}
                    description={t('If enabled, the imported or calculated weight is used as shipment weight')}
                  />
                </FormGridItem>
              </Grid>
            </Box>

            <Box>
              <Typography
                variant="h3"
                mb={1}
              >
                {t('Importer')}
              </Typography>
              <Grid
                container
                columnSpacing={1}
                rowSpacing={3}
                mt={1}
                mb={3}
              >
                <FormGridItem>
                  <FormSelect
                    name="process.shopSystem"
                    label={t('Shop system')}
                    fullWidth
                    control={form.control}
                    options={[
                      { value: '', label: t('No shop system') },
                      ...[...new Set<string>([...shopSystems.data, process.shopSystem])]
                        .sort((a, b) => (a > b ? 1 : -1))
                        .filter((id) => !!id)
                        .map((id) => ({ value: id, label: id })),
                    ]}
                  />
                </FormGridItem>

                {credentialSchema.data &&
                  (shopSystemApi.isLoading ? (
                    <>
                      <FormGridItem>
                        <Skeleton height={54} />
                      </FormGridItem>
                      <FormGridItem>
                        <Skeleton height={54} />
                      </FormGridItem>
                      <FormGridItem>
                        <Skeleton height={54} />
                      </FormGridItem>
                    </>
                  ) : (
                    <FormSchema
                      path="credentials"
                      control={form.control}
                      schema={credentialSchema.data ?? {}}
                    />
                  ))}
              </Grid>

              <Grid
                container
                columnSpacing={1}
                rowSpacing={0}
                my={1}
              >
                <FormGridItem>
                  <FormCheckbox
                    name="process.notificationsEnabled"
                    label={t('Upload notifications')}
                    control={form.control}
                    disabled={!shopSystem}
                  />
                </FormGridItem>

                <FormGridItem>
                  <FormCheckbox
                    name="process.importTrackingIdWithoutSpecialCharacters"
                    label={t('Import tracking IDs without special characters [.-]')}
                    control={form.control}
                    disabled={!shopSystem}
                  />
                </FormGridItem>

                <FormGridItem>
                  <FormCheckbox
                    name="process.disableTrackingIdImport"
                    label={t('Do not import tracking ID')}
                    control={form.control}
                    disabled={!shopSystem}
                  />
                </FormGridItem>

                <FormGridItem>
                  <FormCheckbox
                    name="process.importShipmentsAsProcessed"
                    label={t("Import shipments as ‘processed'")}
                    control={form.control}
                    disabled={!shopSystem}
                  />
                </FormGridItem>
              </Grid>

              <Grid
                container
                columnSpacing={1}
                rowSpacing={0}
                my={1}
              >
                <FormGridItem>
                  <FormCheckbox
                    name="process.orderAutoImportEnabled"
                    label={t('Automatic order import')}
                    control={form.control}
                    disabled={!shopSystem}
                  />
                </FormGridItem>

                <FormGridItem>
                  <FormCheckbox
                    disabled={!!form.watch('process.articleReferenceId') || !shopSystem}
                    name="process.articleAutoImportEnabled"
                    label={t('Automatic article import')}
                    control={form.control}
                    description={
                      form.watch('process.articleReferenceId')
                        ? t('No article import possible if an article master is linked')
                        : undefined
                    }
                  />
                </FormGridItem>

                <FormGridItem>
                  <FormCheckbox
                    disabled={!form.watch('process.articleAutoImportEnabled') || !shopSystem}
                    name="process.dailyArticleImportEnabled"
                    label={t('Import articles only once a day')}
                    control={form.control}
                    description={
                      !form.watch('process.articleAutoImportEnabled')
                        ? t('Requires an activated article import')
                        : undefined
                    }
                  />
                </FormGridItem>
              </Grid>
            </Box>

            <Box>
              <Typography
                variant="h3"
                mb={1}
              >
                Zoll
              </Typography>
              <Grid
                container
                columnSpacing={1}
                rowSpacing={2}
                my={1}
              >
                {isToNorway && (
                  <FormGridItem>
                    <FormTextField
                      name="process.postNordCustomerNumberNO"
                      label={t('Post Nord Customer Number NO')}
                      fullWidth
                      control={form.control}
                    />
                  </FormGridItem>
                )}

                {isGermanRoute && (
                  <>
                    <FormGridItem>
                      <FormTextField
                        name="process.iossVat"
                        label={t('IOSS VAT Number')}
                        fullWidth
                        control={form.control}
                      />
                    </FormGridItem>

                    <FormGridItem>
                      <FormTextField
                        name="process.vatNumberDe"
                        label={t('VAT Number DE')}
                        fullWidth
                        control={form.control}
                      />
                    </FormGridItem>
                  </>
                )}

                {isSwissRoute && (
                  <FormGridItem>
                    <FormTextField
                      name="process.vatNumber"
                      label={t('CH VAT Number')}
                      fullWidth
                      control={form.control}
                    />
                  </FormGridItem>
                )}

                {isSwissRoute && (
                  <FormGridItem>
                    <FormTextField
                      name="process.zazNumber"
                      label={t('ZAZ Konto')}
                      fullWidth
                      control={form.control}
                    />
                  </FormGridItem>
                )}

                {isToGreatBritain && (
                  <FormGridItem>
                    <FormTextField
                      name="process.dutyDefermentAccountGB"
                      label={t('Duty Deferment Account')}
                      fullWidth
                      control={form.control}
                    />
                  </FormGridItem>
                )}

                <FormGridItem>
                  <FormTextField
                    name="process.generalGoodsDescription"
                    label={t('General description of goods')}
                    fullWidth
                    control={form.control}
                  />
                </FormGridItem>

                {isToGreatBritain && (
                  <FormGridItem>
                    <FormTextField
                      name="process.transitTariffNumber"
                      label={t('Transit Tariff Number')}
                      fullWidth
                      control={form.control}
                    />
                  </FormGridItem>
                )}

                <FormGridItem>
                  <FormSelectCountryCode
                    name="process.originCountry"
                    label={t('Country of origin')}
                    fullWidth
                    control={form.control}
                    commonCountryCodes={[UNKNOWN_COUNTRY_CODE, ...defaultCommonCountryCodes, IsoCountryCode.CN]}
                  />
                </FormGridItem>

                <FormGridItem>
                  <FormSelect
                    name="process.originArea"
                    label={t('State of origin')}
                    fullWidth
                    control={form.control}
                    disabled={originCountry !== IsoCountryCode.DE}
                    options={Object.entries({ ...ORIGIN_AREAS, '17': 'Mallorca' })
                      .sort((a, b) => (a[1] < b[1] ? -1 : 1))
                      .map(([id, label]) => ({ value: id, label: `${label} (${id})` }))}
                  />
                </FormGridItem>

                <FormGridItem>
                  <FormSelectAddress
                    name="process.fulfillerAddressId"
                    label={t('Fulfiller address')}
                    fullWidth
                    control={form.control}
                    addresses={customerAddresses.data}
                  />
                </FormGridItem>

                <FormGridItem>
                  <FormTextField
                    name="process.customsNote"
                    label={t('General tour note')}
                    fullWidth
                    control={form.control}
                  />
                </FormGridItem>

                {isNonVOECNorway && (
                  <>
                    <FormGridItem>
                      <FormTextField
                        name="customsFtpServer.host"
                        label={t('FTP server host')}
                        fullWidth
                        control={form.control}
                      />
                    </FormGridItem>

                    <FormGridItem>
                      <FormTextField
                        name="customsFtpServer.username"
                        label={t('FTP server username')}
                        fullWidth
                        control={form.control}
                      />
                    </FormGridItem>

                    <FormGridItem>
                      <FormTextField
                        name="customsFtpServer.password"
                        label={t('FTP server password')}
                        fullWidth
                        control={form.control}
                      />
                    </FormGridItem>
                  </>
                )}
              </Grid>

              <Grid
                container
                columnSpacing={1}
                rowSpacing={0}
                my={1}
              >
                <FormGridItem>
                  <FormCheckbox
                    name="process.useLineItemName"
                    label={t('Use line item name')}
                    control={form.control}
                  />
                </FormGridItem>

                <FormGridItem>
                  <FormCheckbox
                    name="process.trustworthyArticleWeights"
                    label={t('Reliable article weights')}
                    control={form.control}
                  />
                </FormGridItem>

                <FormGridItem>
                  <FormCheckbox
                    name="process.lineItemHandlingOnOrderLevel"
                    label={t('Weight calculation via order')}
                    control={form.control}
                  />
                </FormGridItem>

                {isGermanyToSwiss && (
                  <FormGridItem>
                    <FormCheckbox
                      name="process.outboundBulkImport"
                      label={t('Bulk import')}
                      control={form.control}
                    />
                  </FormGridItem>
                )}

                {isCustomsRequired && (
                  <FormGridItem>
                    <FormCheckbox
                      name="process.useConsigneeAsImporter"
                      label={t('Declare recipient as importer')}
                      control={form.control}
                    />
                  </FormGridItem>
                )}

                {isToNorway && (
                  <FormGridItem>
                    <FormCheckbox
                      name="process.usesVoecHandling"
                      label={t('Voec handling activated')}
                      control={form.control}
                    />
                  </FormGridItem>
                )}
              </Grid>
            </Box>

            <Box>
              <Typography
                variant="h3"
                mb={1}
              >
                Retouren
              </Typography>
              <Grid
                container
                columnSpacing={1}
                rowSpacing={3}
                my={1}
              >
                <FormGridItem>
                  <FormSelect
                    name="process.returnPackageHandling"
                    label={t('Returns are')}
                    required
                    fullWidth
                    control={form.control}
                    options={[
                      {
                        label: t('returned to the shop'),
                        value: ShipmentReturnHandling.RETURN_TO_SHOP,
                      },
                      { label: t('disposed'), value: ShipmentReturnHandling.DISPOSE },
                      {
                        label: t('specially treated'),
                        value: ShipmentReturnHandling.SPECIAL_TREATMENT,
                      },
                    ]}
                  />
                </FormGridItem>

                <FormGridItem>
                  <FormSelectAddress
                    name="process.returnWarehouseAddressId"
                    label={t('Return warehouse address')}
                    fullWidth
                    control={form.control}
                    addresses={customerAddresses.data}
                  />
                </FormGridItem>
              </Grid>

              <Grid
                container
                columnSpacing={1}
                rowSpacing={0}
                my={1}
              >
                <FormGridItem>
                  <FormCheckbox
                    name="process.returnPortalDisabled"
                    label={t('Returns portal deactivated')}
                    control={form.control}
                  />
                </FormGridItem>

                <FormGridItem>
                  <FormCheckbox
                    name="process.returnNotificationsEnabled"
                    label={t('Returns mailing')}
                    control={form.control}
                  />
                </FormGridItem>

                <FormGridItem>
                  <FormCheckbox
                    name="process.useShippingUnitForReturns"
                    label={t('Use shipping unit')}
                    control={form.control}
                    disabled={!form.watch('process.returnWarehouseAddressId')}
                  />
                </FormGridItem>

                <FormGridItem>
                  <FormCheckbox
                    name="process.autoReturnShipmentConfirmationEnabled"
                    label={t('Confirm returns with same weight automatically')}
                    control={form.control}
                  />
                </FormGridItem>
              </Grid>
            </Box>
          </Stack>

          <Stack
            direction="row"
            spacing={1}
            justifyContent="flex-end"
          >
            {auth.isSuperAdmin() &&
              (areCredentialsUnmasked ? (
                <ButtonAsync
                  color="secondary"
                  variant="contained"
                  startIcon={<VisibilityOffIcon />}
                  onClick={() => {
                    reset(
                      { credentials: shopSystemApi.data?.config ?? {}, process },
                      { keepDirtyValues: true, keepIsSubmitted: true },
                    );

                    setCredentialsUnmasked(false);
                  }}
                >
                  {t('Hide passwords')}
                </ButtonAsync>
              ) : (
                <ButtonAsync
                  color="secondary"
                  variant="contained"
                  startIcon={<VisibilityIcon />}
                  onClick={async () => {
                    await assertAuthentication(ReauthTimeInMinutes.DECRYPT_CONFIG);

                    try {
                      const credentials = await ProcessService.getUnmaskedProcessShopSystemApi(process.processId);
                      const customsFtpServer = await ProcessCredentialsService.getPlainCustomsFtpServer(
                        process.processId,
                      );

                      reset(
                        { credentials: credentials.config, process, customsFtpServer },
                        { keepDirtyValues: true, keepIsSubmitted: true },
                      );
                      setCredentialsUnmasked(true);
                    } catch (error) {
                      notifications.addError(error);
                    }
                  }}
                >
                  Passwörter anzeigen
                </ButtonAsync>
              ))}

            <Button
              type="submit"
              variant="contained"
              disabled={!isDirty || isSubmitting}
              startIcon={isSubmitting ? <CircularProgress size="1em" /> : <SaveIcon />}
            >
              {t('Update process')}
            </Button>
          </Stack>

          {dirtyFields.process?.processTypeId && (
            <Alert
              severity="warning"
              sx={{ mt: 2 }}
            >
              <AlertTitle>{t('You have changed the process type!')}</AlertTitle>
              {t('Was that intentional?')}
            </Alert>
          )}
        </form>
      </CardContent>
    </Card>
  );
};

export default ProcessSettings;
