import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { CustomerTable } from './components/customerTable';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  tableStyle: {
    marginTop: '20px',
    marginBottom: '20px',
  },
});

export const Customers: React.FC<{}> = () => {
  const { t } = useTranslation('CRM');
  const classes = useStyles();

  return (
    <div className={classes.tableStyle}>
      <Helmet>
        <title>{t('Customers')}</title>
      </Helmet>

      <CustomerTable></CustomerTable>
    </div>
  );
};
