import React from 'react';
import { ProcessWithCustomerDto } from '../../../../services/process-service/process.service';
import { Card, CardHeader, Typography, CardContent } from '@mui/material';
import FiscalRepresentation from './FiscalRepresentation';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { useTranslation } from 'react-i18next';

type Props = {
  process: ProcessWithCustomerDto;
};

const ProcessFiscalRepresentation: React.FC<Props> = ({ process }) => {
  const { t } = useTranslation('CRM');

  return (
    <Card sx={{ p: 1 }}>
      <CardHeader
        title={<Typography variant="h5">{t('Representation')}</Typography>}
        avatar={<AccountBalanceIcon sx={{ fontSize: '2.5rem' }} />}
      />

      <CardContent>
        <FiscalRepresentation processId={process.processId} />
      </CardContent>
    </Card>
  );
};

export default ProcessFiscalRepresentation;
