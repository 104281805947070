import { Grid, Tooltip } from '@mui/material';
import { DateTime } from 'luxon';
import React from 'react';
import { ActivityDto } from '../../../services/activity-service/activity.service';
import DataTable, { TDataTableColumn } from '../../../shared/components/data-table/DataTable';
import { renderJson } from './GeneralActivityTable';
import UserLabel from './UserLabel';
import { useTranslation } from 'react-i18next';

type Props = {
  activities: ActivityDto[];
  isLoading?: boolean;
};

const Panel = (row: ActivityDto) => {
  return (
    <Grid
      container
      spacing={1}
    >
      <Grid
        item
        xs={12}
      >
        {renderJson(row.afterValue)}
      </Grid>
    </Grid>
  );
};

const ImporterActivityTable: React.FC<Props> = ({ activities, isLoading }) => {
  const { t } = useTranslation('activity');

  const getFormattedId = (id: unknown | undefined) => (typeof id === 'string' ? <em>{id.split('-')[0]}: </em> : '');
  const getFormattedDuration = (duration: unknown | undefined) =>
    typeof duration === 'number' ? ` nach ${(duration / 60 / 1000).toFixed(1)} Minuten` : '';

  const columns: TDataTableColumn<ActivityDto>[] = [
    {
      title: t('Process'),
      field: 'entityId',
    },
    {
      title: t('Importer'),
      field: 'entityName',
    },
    {
      title: t('Type'),
      render: (activity) => (activity.action.includes(':order:') ? 'Order' : 'Artikel'),
    },
    {
      title: t('Action'),
      render: (activity) => {
        const shortId = getFormattedId(activity.afterValue?.requestId);
        const duration = getFormattedDuration(activity.afterValue?.duration);

        if (activity.action.endsWith(':finished')) {
          return (
            <span>
              {shortId}Import beendet {duration}
            </span>
          );
        } else if (activity.action.endsWith('started:user')) {
          if (!activity.userId) {
            return <span>{shortId}Von Hand gestartet</span>;
          }

          return (
            <span>
              {shortId}Gestartet durch <UserLabel userId={activity.userId} />
            </span>
          );
        } else if (activity.action.endsWith('started:cron')) {
          return <span>{shortId}Automatischer Start</span>;
        } else if (activity.action.endsWith('error')) {
          return <span>{shortId}Abbruch mit Fehler</span>;
        } else {
          return <span>{activity.action}</span>;
        }
      },
    },
    {
      title: t('Created'),
      render: (activity) => (
        <Tooltip title={DateTime.fromISO(activity.createdAt).toString()}>
          <span>{DateTime.fromISO(activity.createdAt).toRelative()}</span>
        </Tooltip>
      ),
    },
  ];
  return (
    <DataTable
      columns={columns}
      rows={activities}
      isLoading={isLoading}
      detailPanel={Panel}
    />
  );
};

export default ImporterActivityTable;
