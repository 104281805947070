import { api, IUseObjectResponse, SchemaType } from '../../shared/client';

export interface CredentialsCustomsFtpServerDto extends SchemaType<'CredentialsCustomsFtpServerDto'> {}
export interface CredentialsCustomsFtpServerResponseDto extends SchemaType<'CredentialsCustomsFtpServerResponseDto'> {}

export namespace ProcessCredentialsService {
  export const useCustomsFtpServer = (
    processId: number | null,
  ): IUseObjectResponse<CredentialsCustomsFtpServerResponseDto> => {
    const { data, mutate, error, isLoading } = api.useApi<'/v1/process/{processId}/credentials/customs-ftp-server'>(
      processId
        ? {
            key: '/v1/process/{processId}/credentials/customs-ftp-server',
            processId,
          }
        : null,
    );

    return {
      data: data ?? null,
      mutate,
      error,
      isLoading,
      isError: !!error,
    };
  };

  export const getPlainCustomsFtpServer = async (
    processId: number,
  ): Promise<CredentialsCustomsFtpServerResponseDto> => {
    const res = await api.get<'/v1/process/{processId}/credentials/customs-ftp-server/plain'>(
      `/v1/process/${processId}/credentials/customs-ftp-server/plain`,
    );
    return res.data;
  };

  export const updateCustomsFtpServer = async (
    processId: number,
    body: CredentialsCustomsFtpServerDto,
  ): Promise<void> => {
    await api.put<'/v1/process/{processId}/credentials/customs-ftp-server'>(
      `/v1/process/${processId}/credentials/customs-ftp-server`,
      body,
    );
  };
}
