import React from 'react';
import { OrderDto, OrderService } from '../../services/order-service/order.service';
import {
  Alert,
  Button,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useAuthentication } from '../../hooks/useAuthentication';
import LineItemUnassignedRow from './LineItemUnassignedRow';
import AssignmentReturnedIcon from '@mui/icons-material/AssignmentReturned';
import { BarcodeReader } from './BarcodeReader';
import { useNotifications } from '../../hooks/useNotifications';
import { ArticleDto, ArticleService } from '../../services/article-service/article.service';
import { useSWRConfig } from 'swr';
import { LineItemDto, LineItemService } from '../../services/line-item-service/lineItem.service';
import { getConsignedInboundLineItemIds, getTotalOrderWeight, isAssignAllLineItems } from './shipment.helper';
import { OrderType, ShipmentType } from '../../shared/backend';
import { ShipmentDto, ShipmentService } from '../../services/shipment-service/shipment.service';
import { ScaleContext, scaleContext } from '../../contexts/ScaleContext';
import { useTranslation } from 'react-i18next';
import { ProcessService } from '../../services/process-service/process.service';

function isSevenSendersBarcode(barcode: string, lineItem: LineItemDto, article: ArticleDto): boolean {
  const sevenSendersBarcodes =
    !lineItem.barcode &&
    article.raw &&
    typeof article.raw === 'object' &&
    'barcodes' in article.raw &&
    Array.isArray(article.raw.barcodes)
      ? article.raw.barcodes
          .map((barcode: { barcode?: string } | undefined | null) => barcode?.barcode)
          .filter((barcode): barcode is string => !!barcode)
      : [];

  // This is extracting the sku from the Breuninger barcode
  const fuckingSku = /^(78\d{22}|79\d{24})$/.test(barcode) ? barcode.slice(6, 15) : null;
  const isSevenSendersBarcode =
    sevenSendersBarcodes.includes(barcode) ||
    (fuckingSku && sevenSendersBarcodes.includes(fuckingSku)) ||
    (fuckingSku && lineItem.barcode === fuckingSku);

  return !!isSevenSendersBarcode;
}

type Props = {
  order: OrderDto;
  shipment: ShipmentDto;
  disabled: boolean;
  editing: boolean;
  acceptShipment: (shippingWeight: number | null) => Promise<void>;
};

const LineItemsUnassigned: React.FC<Props> = ({ order, shipment, disabled, editing, acceptShipment }) => {
  const { t } = useTranslation();
  const { cache: swrCache } = useSWRConfig();
  const auth = useAuthentication();
  const notifications = useNotifications();
  const scale = React.useContext<ScaleContext>(scaleContext);
  const process = ProcessService.useProcess(order.processId);

  const { mutate } = OrderService.useOrderByShipmentId(shipment.shipmentId);
  const outgoingShipments = ShipmentService.useShipmentsByOrder(shipment.orderId, OrderType.ORDER);

  const consignedLineItemIds = getConsignedInboundLineItemIds(outgoingShipments.data);
  const unassignedLineItems = order.lineItems.filter(
    (lineItem) => !lineItem.returnShipmentId && consignedLineItemIds.includes(lineItem.lineItemId),
  );
  const unconsignedLineItems = order.lineItems.filter(
    (lineItem) => !lineItem.returnShipmentId && !consignedLineItemIds.includes(lineItem.lineItemId),
  );

  const shouldAssignAllLineItems =
    process.data?.autoReturnShipmentConfirmationEnabled &&
    isAssignAllLineItems(shipment, getTotalOrderWeight(outgoingShipments.data));

  const assignLineItem = async (lineItemId: number) => {
    try {
      await ShipmentService.addLineItemToShipment(shipment.shipmentId, lineItemId);
      await mutate();

      if (
        unassignedLineItems.length < 1 ||
        (unassignedLineItems.length === 1 && unassignedLineItems[0].quantity === 1 && shipment.shippingWeight)
      ) {
        await acceptShipment(shipment.shippingWeight);
      }
    } catch (err) {
      notifications.addError(err);
    }
  };

  const assignAllLineItems = async () => {
    try {
      for (const lineItem of unassignedLineItems) {
        await ShipmentService.addLineItemToShipment(shipment.shipmentId, lineItem.lineItemId);
      }

      await mutate();
    } catch (err) {
      notifications.addError(err);
    }
  };

  const addSingleLineItemToReturnShipment = async (lineItem: LineItemDto) => {
    let lineItemId = lineItem.lineItemId;

    if (lineItem.quantity > 1) {
      const { newLineItem } = await LineItemService.splitLineItem(lineItem.lineItemId, 1);
      lineItemId = newLineItem.lineItemId;
    }

    await assignLineItem(lineItemId);
  };

  const onBarcodeScan = async (scannedBarcode: string): Promise<boolean> => {
    console.group('barcode scan');
    console.log(`Scanned barcode: "${scannedBarcode}"`);

    if (!scannedBarcode) {
      return false;
    }

    for (const lineItem of unassignedLineItems) {
      try {
        const { externalArticleId } = lineItem;
        const article = externalArticleId
          ? await ArticleService.getCachedArticleByExternalArticleId(lineItem.processId, externalArticleId, swrCache)
          : null;

        if (!article) {
          console.log(`No article found for ${externalArticleId}`);
          continue;
        }

        console.log(`${lineItem.lineItemId}; Line item barcode: "${lineItem.barcode}"; EAN: "${article.ean}";`);

        const trimmedScannedBarcode = scannedBarcode.trim().replace(/^0+/, '');
        const trimmedLineItemBarcode = lineItem.barcode.trim().replace(/^0+/, '');
        const trimmedArticleBarcode = article.ean?.trim().replace(/^0+/, '');

        if (
          trimmedLineItemBarcode === trimmedScannedBarcode ||
          trimmedArticleBarcode === trimmedScannedBarcode ||
          isSevenSendersBarcode(scannedBarcode, lineItem, article)
        ) {
          addSingleLineItemToReturnShipment(lineItem);

          console.log('Found matching line item');
          console.groupEnd();

          return true;
        }
      } catch (error) {
        notifications.addError(error);
      }
    }

    console.log('Found no matching line item');
    console.groupEnd();

    return false;
  };

  if (outgoingShipments.isLoading) {
    return <Skeleton height={30} />;
  }

  if (!unassignedLineItems.length && !unconsignedLineItems.length) {
    return (
      <Typography
        variant="body2"
        align="center"
        mt={3}
        color="text.disabled"
      >
        Alle Artikel zugewiesen
      </Typography>
    );
  }

  const articleCount = unassignedLineItems.reduce(
    (lineItemSum, lineItemQuantity) => lineItemSum + lineItemQuantity.quantity,
    0,
  );

  const headerCells = [
    <TableCell
      key="info"
      sx={{ width: 24 }}
      padding="checkbox"
    ></TableCell>,
    <TableCell key="name">
      {shipment.type === ShipmentType.SHIPMENT
        ? t('Unassigned items ({{articleCount}} pcs.)', { articleCount })
        : t('Items not returned ({{articleCount}} pcs.)', { articleCount })}
    </TableCell>,
  ];

  if (!auth.isPicker()) {
    headerCells.push(<TableCell key="price">Preis</TableCell>);
  }

  headerCells.push(<TableCell key="quantity">Anzahl</TableCell>);

  if (auth.isStaff()) {
    headerCells.push(
      <TableCell
        align="right"
        key="management"
      >
        {shipment.type === ShipmentType.SHIPMENT ? t('Package management') : t('Return management')}
      </TableCell>,
    );
  }

  return (
    <Paper>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>{headerCells}</TableRow>
          </TableHead>
          <TableBody>
            {unassignedLineItems.map((lineItem) => (
              <LineItemUnassignedRow
                shipmentId={shipment.shipmentId}
                key={`unassigned-${lineItem.lineItemId}`}
                lineItem={lineItem}
                assignLineItemToShipment={assignLineItem}
                disabled={disabled}
              />
            ))}
            {!!unconsignedLineItems.length && (
              <TableRow sx={{ backgroundColor: 'action.disabledBackground' }}>
                <TableCell colSpan={headerCells.length}>
                  <Typography variant="h6">
                    {t('The following items have not yet been cleared through customs:')}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {unconsignedLineItems.map((lineItem) => (
              <LineItemUnassignedRow
                shipmentId={shipment.shipmentId}
                key={`unconsigned-${lineItem.lineItemId}`}
                lineItem={lineItem}
                assignLineItemToShipment={assignLineItem}
                disabled={!editing}
                unconsigned={true}
              />
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={2}>
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                >
                  <BarcodeReader
                    focus={scale.connected || !!shipment.shippingWeight}
                    handleScanBarcode={(barcode) => onBarcodeScan(barcode)}
                    onSubmit={async () => {
                      if (unassignedLineItems.length && shouldAssignAllLineItems) {
                        await assignAllLineItems();
                        notifications.addSuccess(t('All items added'));
                      }

                      if (shipment.lineItems.length || shouldAssignAllLineItems) {
                        await acceptShipment(shipment.shippingWeight);
                      }
                    }}
                    disabled={disabled || !unassignedLineItems.length}
                  />
                  {shouldAssignAllLineItems && <Typography>{t('🎉 Same weight as outgoing order')}</Typography>}
                  {process.data?.autoReturnShipmentConfirmationEnabled === false && (
                    <Alert severity="info">{t('Auto accept is disabled')}</Alert>
                  )}
                </Stack>
              </TableCell>
              <TableCell
                colSpan={headerCells.length - 2}
                align="right"
              >
                <Button
                  variant={shouldAssignAllLineItems ? 'contained' : 'outlined'}
                  size="small"
                  onClick={() => assignAllLineItems()}
                  title={t('Assign all items to return')}
                  startIcon={<AssignmentReturnedIcon />}
                  disabled={disabled || !unassignedLineItems.length}
                >
                  {t('Assign all items')}
                </Button>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default LineItemsUnassigned;
