import Tooltip from '@mui/material/Tooltip';
import Edit from '@mui/icons-material/Edit';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import BlockIcon from '@mui/icons-material/Block';
import React from 'react';
import { FunctionComponent } from 'react';
import { OrderDto, OrderWithCustomerDto } from '../../../../services/order-service/order.service';
import { Paper, TablePagination } from '@mui/material';
import DataTable, { TDataTableAction, TDataTableColumn } from '../../../../shared/components/data-table/DataTable';
import { OrderStateIcon } from './OrderStateIcons';
import { OrderState, UserRole } from '../../../../shared/backend';
import { useTranslation } from 'react-i18next';

interface Props {
  orders: OrderWithCustomerDto[];
  userRole: UserRole | null;
  onOrderSelected: (order: OrderDto) => void;
  page: number;
  setPage: (page: number) => void;
  rowsPerPage: number;
  setRowsPerPage: (rowsPerPage: number) => void;
}

export const Table: FunctionComponent<Props> = ({
  orders,
  userRole,
  onOrderSelected,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
}) => {
  const { t } = useTranslation();

  const OrderStateTitle = {
    [OrderState.NEW]: t('Order has been received in the system'),
    [OrderState.REJECTED]: t(
      'There were discrepancies at customs with at least one parcel relating to the order. The parcel will be delivered to the destination country the following day.',
    ),
    [OrderState.IN_PROGRESS]: t('Order is being processed by exporto'),
    [OrderState.CLOSED]: t('Closed'),
    [OrderState.BLOCKED]: t('Blocked'),
  };

  const actions: TDataTableAction<OrderWithCustomerDto>[] = [
    userRole !== UserRole.CUSTOMER
      ? {
          tooltip: t('Edit'),
          icon: () => <Edit />,
          onClick: (rowData) => onOrderSelected(rowData),
          disabled: (rowData) => userRole === UserRole.PICKER && rowData.state === OrderState.BLOCKED,
        }
      : {
          tooltip: t('Show order'),
          icon: () => <MenuOpenIcon />,
          onClick: (rowData) => onOrderSelected(rowData),
        },
  ];

  const columns: TDataTableColumn<OrderWithCustomerDto>[] = [
    {
      title: t('State'),
      field: 'state',
      render: (rowData: OrderWithCustomerDto) => (
        <Tooltip title={OrderStateTitle[rowData.state]}>
          <span>{OrderStateIcon[rowData.state]}</span>
        </Tooltip>
      ),
    },
    {
      title: t('Online store'),
      render: (order: OrderWithCustomerDto) =>
        order.process.blocked ? (
          <>
            <BlockIcon
              color="error"
              sx={{ fontSize: '1.2em', verticalAlign: 'sub' }}
            />{' '}
            {order.process.customer.company}
          </>
        ) : (
          order.process.customer.company
        ),
    },
    { title: t('Customer Facing No.'), field: 'customerFacingId' },
    { title: t('Customer name'), field: 'name' },
    { title: t('City'), field: 'city' },
    {
      title: t('Ordered on'),
      render: (rowData: OrderWithCustomerDto) =>
        rowData.externalCreatedAt ? new Date(rowData.externalCreatedAt).toLocaleDateString() : '',
    },
  ];

  return (
    <Paper>
      <DataTable
        page={page}
        columns={columns}
        rows={orders}
        actions={actions}
      />
      <TablePagination
        component="div"
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 20, 30]}
        onRowsPerPageChange={(ev) => setRowsPerPage(parseInt(ev.target.value, 10))}
        page={page}
        count={orders.length < rowsPerPage ? page * rowsPerPage + orders.length : -1}
        onPageChange={(ev, page) => setPage(page)}
        nextIconButtonProps={{
          disabled: orders.length < rowsPerPage,
        }}
      />
    </Paper>
  );
};
