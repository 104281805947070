import React, { useEffect, useState } from 'react';

import { Autocomplete, CircularProgress, ListItem, ListItemText, TextField } from '@mui/material';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';
import { TourService } from '../../services/tour-service/tour.service';
import { useTranslation } from 'react-i18next';

type Props = {};

const TourBatchSearchField: React.FC<Props> = () => {
  const navigateTo = useNavigate();
  const { t } = useTranslation();
  const [shortBatchId, setShortBatchId] = useState<string | null>(null);
  const tourBatches = TourService.useTourBatchesByShortId(shortBatchId?.length === 6 ? shortBatchId : null);
  const singleTourBatchId = tourBatches.data.length === 1 ? tourBatches.data[0].tourBatchId : null;

  useEffect(() => {
    if (singleTourBatchId) {
      navigateTo(`/customs/tour/${singleTourBatchId}`);
    }
  }, [singleTourBatchId, navigateTo]);

  return (
    <Autocomplete
      freeSolo
      loading={tourBatches.isLoading}
      options={tourBatches.data.length > 1 ? tourBatches.data : []}
      getOptionLabel={(option) => (typeof option !== 'string' ? option.tourBatchId : option)}
      onInputChange={(_, value) => setShortBatchId(value)}
      onChange={(_, selectedValue) => {
        if (!selectedValue || typeof selectedValue === 'string') {
          return;
        }

        navigateTo(`/customs/tour/${selectedValue?.tourBatchId}`);
      }}
      componentsProps={{ popper: { style: { width: 'fit-content' } } }}
      renderOption={(props, option) => (
        <ListItem
          {...props}
          key={option.tourBatchId}
        >
          <ListItemText
            primary={option.tourBatchId}
            secondary={DateTime.fromISO(option.date).toLocaleString(DateTime.DATETIME_MED)}
          />
        </ListItem>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('Batch ID')}
          variant="outlined"
          size="small"
          sx={{ minWidth: '8em' }}
          inputProps={{ ...params.inputProps, maxLength: 6 }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {tourBatches.isLoading ? (
                  <CircularProgress
                    color="inherit"
                    size={20}
                  />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default TourBatchSearchField;
