import { backendUri } from '../../shared/helper/env/helper';
import client, { SchemaType } from '../../shared/client';
import useSWRInfinite from 'swr/infinite';
import { Backend, JobQueueJobs, JobStatus } from '../../shared/backend';
import { DateTime } from 'luxon';

export type TJobStateChangeHandler = (jobId: string, jobState: JobDto) => void | Promise<void>;
export interface JobDto extends SchemaType<'JobDto'> {}
export interface ReducedJobDto extends SchemaType<'ReducedJobDto'> {}
export type GetJobsForUserQuery = Backend.paths['/v1/queue-manager/user-jobs']['get']['parameters']['query'];

export class JobError {
  constructor(private jobState: JobDto) {}

  public getJobState(): JobDto {
    return this.jobState;
  }
}

export interface IWaitingJob {
  jobState?: JobDto;
  onProgress?: TJobStateChangeHandler;
  onSuccess?: TJobStateChangeHandler;
  onError?: TJobStateChangeHandler;
}

export const useJobsInfinite = (jobStates: JobStatus[], chunkSize = 6) => {
  const getUrl = (params?: GetJobsForUserQuery): string => {
    const url = new URL(`${backendUri}/v1/queue-manager/user-jobs`);

    if (typeof params?.start === 'number') {
      url.searchParams.set('start', params.start.toString());
    }
    if (typeof params?.end === 'number') {
      url.searchParams.set('end', params.end.toString());
    }
    if (params?.jobStates) {
      url.searchParams.set('jobStates', jobStates.join(','));
    }

    return url.href;
  };

  const { data, error, mutate, size, setSize, isLoading } = useSWRInfinite<JobDto[]>(
    (index) => {
      return getUrl({
        start: index * chunkSize,
        end: index * chunkSize + chunkSize - 1,
        jobStates,
      });
    },
    {
      initialSize: 2,
      refreshInterval: 10000,
    },
  );

  const isEmpty = data?.[0]?.length === 0;

  return {
    data: data ?? [],
    error,
    isLoading,
    isLoadingMore: isLoading || (size > 0 && data && typeof data[size - 1] === 'undefined'),
    isError: !!error,
    isEmpty,
    isReachingEnd: false,
    mutate,
    size,
    setSize,
  };
};

export function getJobProgressState(job: ReducedJobDto): JobStatus {
  let jobState: JobStatus = JobStatus.WAITING;
  if (job.processedOn) {
    jobState = JobStatus.ACTIVE;
  }

  if (job.finishedOn) {
    if (job.failedReason) {
      jobState = JobStatus.FAILED;
    } else {
      jobState = JobStatus.COMPLETED;
    }
  }

  return jobState;
}

export function getJobResultFileName(job: ReducedJobDto): string {
  const tourIds = job.data.parameters?.tourIds ?? [];

  switch (job.name) {
    case JobQueueJobs.GERMAN_IMPORT_DOCUMENTS_CREATION:
      return `De_import_documents_${tourIds.join('+')}.pdf`;
    case JobQueueJobs.EMK_DOCUMENTS_CREATION:
      return `emk_${tourIds.join('_')}.zip`;
    case JobQueueJobs.COLLECTIVE_REFERENCE_CREATION:
      return `${DateTime.now().toFormat('dd.MM.yyyy')}_${tourIds.join('+')}_sammler.pdf`;
    case JobQueueJobs.INVOICE_CREATION:
      const shipmentId = job.data.parameters?.shipmentId;
      return `invoice_shipment_${shipmentId}.pdf`;
    case JobQueueJobs.TRANSIT_DEPARTURE_DOCUMENT_CREATION:
      return `${DateTime.now().toFormat('dd.MM.yyyy')}_${tourIds.join('+')}_transit.pdf`;
    case JobQueueJobs.POST_NORD_CSV:
      return `Post_nord_customs_${job.data.parameters?.tourBatchId}.zip`;
    default:
      return '';
  }
}

class JobService {
  protected jobs: Map<string, IWaitingJob>;

  constructor() {
    this.jobs = new Map();
  }

  public addJobPromise(jobId: string, onProgress?: TJobStateChangeHandler): Promise<JobDto> {
    return new Promise((resolve, reject) => {
      this.addJob(jobId, {
        onSuccess: (jobId, jobState) => resolve(jobState),
        onError: (jobId, jobState) => reject(new JobError(jobState)),
        onProgress,
      });
    });
  }

  public addJob(
    jobId: string,
    handlers: {
      onSuccess?: TJobStateChangeHandler;
      onError?: TJobStateChangeHandler;
      onProgress?: TJobStateChangeHandler;
    } = {},
  ): void {
    if (this.jobs.has(jobId)) {
      throw new Error(`JobService: Job with ID ${jobId} is already created.`);
    }

    const { onSuccess, onError, onProgress } = handlers;

    this.jobs.set(jobId, {
      onSuccess,
      onError,
      onProgress,
    });
  }

  public removeJob(jobId: string): void {
    if (!this.jobs.has(jobId)) {
      throw new Error(`JobService: Job with ID ${jobId} could not be found.`);
    }
    this.jobs.delete(jobId);
  }

  public async getJob(jobId: string): Promise<JobDto | null> {
    const response = await client.get<JobDto | null>(`${backendUri}/v1/queue-manager/job-state/${jobId}`);
    return response.data;
  }

  public async pollJobStates(): Promise<void> {
    for (const [jobId, waitingJob] of this.jobs.entries()) {
      const job = await this.getJob(jobId);

      if (!job) {
        console.log(
          `JobService: Job with ID ${jobId} could not be found in queue-manager. Removing job from jobs map.`,
        );
        this.removeJob(jobId);
        continue;
      }

      const previousState = waitingJob.jobState ? getJobProgressState(waitingJob.jobState) : null;
      const newState = getJobProgressState(job);

      const stateChanged = previousState !== newState;
      const progressChanged = !waitingJob.jobState || waitingJob.jobState.progress !== job.progress;

      if (stateChanged || progressChanged) {
        waitingJob.jobState = job;

        switch (newState) {
          case 'active':
          case 'delayed':
          case 'paused':
            if (waitingJob.onProgress) {
              await waitingJob.onProgress(jobId, waitingJob.jobState);
            }
            break;
          case 'completed':
            if (waitingJob.onSuccess) {
              await waitingJob.onSuccess(jobId, waitingJob.jobState);
            }
            this.removeJob(jobId);
            break;
          case 'failed':
            if (waitingJob.onError) {
              await waitingJob.onError(jobId, waitingJob.jobState);
            }
            this.removeJob(jobId);
            break;
          case 'waiting':
          default:
        }
      }
    }
  }
}

export const jobService = new JobService();
const pollIntervalMs = 2000;

const pollJobStates = () => {
  jobService.pollJobStates().finally(() => setTimeout(pollJobStates, pollIntervalMs));
};

setTimeout(pollJobStates, pollIntervalMs);
