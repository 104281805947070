import {
  Card,
  CardHeader,
  Typography,
  CardContent,
  Box,
  Stack,
  TextField,
  MenuItem,
  Grid,
  CardActionArea,
} from '@mui/material';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CustomerService } from '../../../../services/customer-service/customer.service';
import ButtonAsync from '../../../../shared/components/ButtonAsync';
import { countryToFlag } from '../../../../shared/helper/country';
import { ProcessService } from '../../../../services/process-service/process.service';
import ListIcon from '@mui/icons-material/List';
import { ProcessTypeService } from '../../../../services/process-type-service/process-type.service';
import { useNotifications } from '../../../../hooks/useNotifications';
import BlockIcon from '@mui/icons-material/Block';
import { useTranslation } from 'react-i18next';

type Props = {
  customerId: number;
};

const CustomerProcesses: React.FC<Props> = ({ customerId }) => {
  const { t } = useTranslation('CRM');
  const navigate = useNavigate();
  const notifications = useNotifications();
  const processes = ProcessService.useProcesses(customerId);
  const processTypes = ProcessTypeService.useProcessTypes();

  const [newProcessProcessTypeId, setNewProcessProcessTypeId] = useState<number | null>(null);

  return (
    <Card sx={{ p: 1 }}>
      <CardHeader
        title={<Typography variant="h5">{t('Processes')}</Typography>}
        avatar={<ListIcon sx={{ fontSize: '2.5rem' }} />}
      />

      <CardContent>
        {processes.data.length === 0 && <Typography variant="body2">{t('No processes created so far')}</Typography>}

        <Grid
          container
          spacing={1}
        >
          {processes.data.map((process) => (
            <Grid
              key={process.processId}
              item
              xs={12}
              md={3}
              xl={2}
            >
              <Card>
                <CardActionArea
                  component={Link}
                  to={`/crm/process/${process.processId}`}
                >
                  <CardHeader
                    avatar={
                      process.blocked ? (
                        <BlockIcon
                          color="error"
                          sx={{ fontSize: '2em' }}
                        />
                      ) : (
                        <Box
                          component="span"
                          sx={{ fontSize: '2em' }}
                        >
                          {countryToFlag(process.processType.destinationCountry)}
                        </Box>
                      )
                    }
                    title={t(`Process {{processId}}`, { processId: process.processId })}
                    subheader={
                      process.processType.label ||
                      `${process.processType.dispatchCountry} -> ${process.processType.destinationCountry}`
                    }
                  />
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>

        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="flex-end"
          sx={{ mt: 3 }}
        >
          <TextField
            select
            label={t('Process type')}
            sx={{ minWidth: 160 }}
            size="small"
            value={newProcessProcessTypeId ?? ''}
            onChange={(ev) =>
              ev.target.value
                ? setNewProcessProcessTypeId(parseInt(ev.target.value, 10))
                : setNewProcessProcessTypeId(null)
            }
          >
            <MenuItem value="">
              <em>{t('Please select')}</em>
            </MenuItem>
            {processTypes.data.map((processType) => (
              <MenuItem
                key={processType.processTypeId}
                value={processType.processTypeId}
              >
                {processType.label}
              </MenuItem>
            ))}
          </TextField>

          <ButtonAsync
            variant="contained"
            disabled={!newProcessProcessTypeId}
            onClick={async () => {
              const newProcess = await CustomerService.createProcess(customerId ?? 0, {
                shopSystem: '',
                processTypeId: newProcessProcessTypeId ?? 0,
                dailyArticleImportEnabled: true,
              });

              await processes.mutate();

              notifications.addSuccess(t('New process created'));

              navigate(`/crm/process/${newProcess.processId}`);
            }}
          >
            {t('Add process')}
          </ButtonAsync>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default CustomerProcesses;
