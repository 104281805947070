import { Card, Typography, Alert, Box, AlertTitle } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import FileSaver from 'file-saver';
import React from 'react';
import { useNotifications } from '../../../../../../hooks/useNotifications';
import { LineItemService, LineItemUpdateDto } from '../../../../../../services/line-item-service/lineItem.service';
import { OrderWithCustomerDto } from '../../../../../../services/order-service/order.service';
import { ShipmentMethodDto } from '../../../../../../services/shipment-method-service/shipmentMethod.service';
import { ShipmentUpdateDto } from '../../../../../../services/shipment-service/dto/shipmentUpdate.dto';
import useStyles from '../OrderDetailModal.style';
import { RegularOrderView } from './regular-order-view/RegularOrderView';
import ShipmentView from './shipment-view/shipmentView';
import { printBase64PDF } from '../../../../../../shared/helper/print';
import GradeIcon from '@mui/icons-material/Grade';
import { ShipmentDto, ShipmentService } from '../../../../../../services/shipment-service/shipment.service';
import {
  OrderBlockedBy,
  OrderState,
  OrderType,
  ShipmentReturnHandling,
  ShipmentState,
  ShipmentType,
} from '../../../../../../shared/backend';
import { useAuthentication } from '../../../../../../hooks/useAuthentication';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import PersonIcon from '@mui/icons-material/Person';
import SmartToyIcon from '@mui/icons-material/SmartToy';

const downloadPDFlabel = (data: string, fileName: { customerFacingId: string; company: string; suffix?: string }) => {
  const name = `${fileName.customerFacingId}_${fileName.company}_paket_${fileName.suffix || '1'}_label.pdf`;

  FileSaver.saveAs(`data:application/pdf;base64,${data}`, name);
};

const createShipment = (internalId: number, orderType: OrderType) => {
  return ShipmentService.createShipmentByOrder(internalId, {
    shipmentType: orderType === OrderType.ORDER ? ShipmentType.SHIPMENT : ShipmentType.RETOUR,
  });
};

type Props = {
  order: OrderWithCustomerDto;
  shipments: ShipmentDto[];
  shipmentMethods: ShipmentMethodDto[];
  orderType: OrderType;
  shipmentId: number | null;
  scannedTrackingCode?: string;
  editAddressAndShipment: boolean;
  handleNextShipment: () => void;
  mutateOrder: (updatedOrder?: OrderWithCustomerDto) => Promise<unknown>;
  mutateShipments: () => Promise<unknown>;
  handleShipmentUpdate: (shipmentId: number, body: ShipmentUpdateDto) => Promise<void>;
  handleSwitchEditAdress: (edit: boolean) => void;
  showArticleWeights?: boolean;
};

const ModalBody: React.FC<Props> = ({
  order,
  shipments,
  shipmentMethods,
  orderType,
  shipmentId,
  scannedTrackingCode,
  editAddressAndShipment,
  handleNextShipment,
  mutateOrder,
  mutateShipments,
  handleShipmentUpdate,
  handleSwitchEditAdress,
  showArticleWeights,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const notificationHandler = useNotifications();
  const auth = useAuthentication();

  const printLabel = async (shipmentId: number, shipmentMethodId: number, suffix?: string, downloadable?: boolean) => {
    try {
      let label = await ShipmentService.createLabel(shipmentId, shipmentMethodId, undefined, {
        referrer: window.location.href,
      });

      if (label) {
        if (downloadable) {
          downloadPDFlabel(label, {
            customerFacingId: order.customerFacingId ?? '',
            company: order.process.customer.company || '',
            suffix,
          });
        } else {
          await printBase64PDF(label);

          handleNextShipment();
        }

        await mutateOrder();
      }
    } catch (error) {
      notificationHandler.addError(error);
    }
  };

  const handleShipmentPrint = async (shipmentId: number, shipmentMethodId: number, downloadable?: boolean) => {
    const shipmentIndex: number | undefined = shipments.findIndex((shipment) => shipment.shipmentId === shipmentId);
    await printLabel(shipmentId, shipmentMethodId, (shipmentIndex + 1).toString(), downloadable);

    await mutateShipments();
  };

  const handleShipmentCreate = async () => {
    await createShipment(order.orderId, orderType);

    await mutateShipments();
  };

  const handleLineItemAssign = async (shipmentId: number, lineItemId: number) => {
    try {
      await ShipmentService.addLineItemToShipment(shipmentId, lineItemId);

      await mutateShipments();
    } catch (error) {
      notificationHandler.addError(error);
    }
  };

  // REVIEW method is probably not necessary
  const handleLineItemAssignFinish = async () => {
    await mutateOrder();
  };

  const handleLineItemDelete = async (shipmentId: number, lineItemId: number) => {
    try {
      await ShipmentService.removeLineItemFromShipment(shipmentId, lineItemId);

      await mutateShipments();
      await mutateOrder();
    } catch (error) {
      notificationHandler.addError(error);
    }
  };

  const handleLineItemUpdate = async (body: LineItemUpdateDto[]) => {
    try {
      await LineItemService.updateLineItems(body);
      await mutateShipments();
      await mutateOrder();
    } catch (error) {
      notificationHandler.addError(error);
    }
  };

  const handleReloadShipments = async () => {
    await mutateShipments();
    await mutateOrder();
  };

  const handleShipmentDelete = async (shipmentId: number) => {
    try {
      await ShipmentService.deleteShipment(shipmentId);

      await mutateShipments();
      await mutateOrder();
    } catch (error) {
      notificationHandler.addError(error);
    }
  };

  const isBlocked = order.state === OrderState.BLOCKED || order.process.blocked;
  const isEmpty = order.lineItems.length === 0;
  const orderId = order.orderId;
  const shopName = order.process.customer.company;
  return (
    <div className={classes.modalBody}>
      <Helmet>
        <title>{t('Order {{orderId}}', { orderId })}</title>
      </Helmet>
      {!isBlocked && !isEmpty && order && (
        <RegularOrderView
          order={order}
          shipments={shipments}
          orderTyp={orderType}
          autofocus={!shipmentId}
          onLineItemAssign={handleLineItemAssign}
          onLineItemAssignFinish={handleLineItemAssignFinish}
          reloadShipments={handleReloadShipments}
        />
      )}

      {order.process.returnPackageHandling === ShipmentReturnHandling.SPECIAL_TREATMENT &&
        orderType === OrderType.RETOURE && (
          <Box m={3}>
            <Alert
              severity="info"
              icon={<GradeIcon />}
            >
              <AlertTitle>{t('Special process!')}</AlertTitle>
              {t('Attention, special process, no normal return. Shop: {{shopName}}', { shopName })}
            </Alert>
          </Box>
        )}

      {isEmpty && (
        <Box m={3}>
          <Alert severity="warning">
            <AlertTitle>{t('The order is empty!')}</AlertTitle>
            {t('An order without an item cannot be cleared through customs, so please do not process the shipment.')}
          </Alert>
        </Box>
      )}

      {isBlocked && (
        <Box m={3}>
          <Alert
            severity="warning"
            icon={
              order.blockedBy === OrderBlockedBy.ARTICLE ? (
                <SmartToyIcon />
              ) : order.blockedBy === OrderBlockedBy.USER ? (
                <PersonIcon />
              ) : undefined
            }
          >
            {order.process.blocked ? (
              <AlertTitle>{t('The store is blocked!')}</AlertTitle>
            ) : (
              <AlertTitle>
                {order.blockedBy === OrderBlockedBy.USER
                  ? t('The order is blocked by a user!')
                  : t('The order is blocked by an article!')}
              </AlertTitle>
            )}
            {t('Please do not process any of the shipments, but contact the support team instead.')}
          </Alert>
        </Box>
      )}

      {!isBlocked &&
        shipments.map((shipment, index) => {
          const disableShipmentEditing =
            !editAddressAndShipment &&
            shipment.state !== ShipmentState.NEW &&
            shipment.state !== ShipmentState.RETOURE_ANNOUNCED &&
            shipment.state !== ShipmentState.TO_BE_CLARIFIED;

          const isActiveShipment =
            shipment.shipmentId === shipmentId ||
            (scannedTrackingCode && shipment.foreignOutboundTrackingId === scannedTrackingCode) ||
            (shipment.type === ShipmentType.RETOUR &&
              scannedTrackingCode &&
              shipment.foreignInboundTrackingId === scannedTrackingCode) ||
            (!shipmentId && index === 0);

          const shipmentNumber = index + 1;

          return (
            <div
              key={shipment.shipmentId}
              className={classes.shipmentViewWrapper}
            >
              <Box
                sx={
                  isActiveShipment
                    ? {
                        border: '3px solid',
                        borderColor: shipment.type === ShipmentType.SHIPMENT ? 'outbound.main' : 'inbound.main',
                      }
                    : undefined
                }
              >
                <ShipmentView
                  heading={
                    shipment.externalShipmentId ||
                    (orderType === OrderType.ORDER
                      ? t('Package {{shipmentNumber}}', { shipmentNumber })
                      : t('Return {{shipmentNumber}}', { shipmentNumber }))
                  }
                  disablePrinting={false || isEmpty}
                  focused={isActiveShipment}
                  onDelete={handleShipmentDelete}
                  onLineItemDelete={handleLineItemDelete}
                  onLineItemUpdate={handleLineItemUpdate}
                  onNextShipment={handleNextShipment}
                  onPrint={handleShipmentPrint}
                  onUpdate={handleShipmentUpdate}
                  order={order}
                  orderId={order.orderId}
                  orderTyp={orderType}
                  reloadShipments={handleReloadShipments}
                  shipment={shipment}
                  shipmentMethods={shipmentMethods}
                  triggerEditAdress={handleSwitchEditAdress}
                  disableShipmentEditing={disableShipmentEditing || isEmpty || shipment.isConsigned}
                  showArticleWeights={showArticleWeights}
                />
              </Box>
            </div>
          );
        })}
      {!isBlocked && !isEmpty && auth.isStaff() && (
        <Card
          onClick={handleShipmentCreate}
          className={classes.addShipmentCard}
        >
          <Typography
            style={{ fontSize: '2.5rem' }}
            variant="body1"
            component="p"
          >
            <AddIcon
              fontSize="large"
              sx={{ color: '#eda439', verticalAlign: 'middle' }}
            />{' '}
            <span
              role="img"
              aria-label="shipment"
            >
              📦
            </span>
          </Typography>
        </Card>
      )}
    </div>
  );
};

export default ModalBody;
