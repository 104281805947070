import { Alert, Button, MenuItem, Stack } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import React, { useState } from 'react';
import { useNotifications } from '../../../hooks/useNotifications';
import {
  createUser,
  updateUser,
  CreateUserDto,
  SafeUserDto,
  useUsers,
} from '../../../services/user-service/user.service';
import CopyToClipboardButton from '../../../shared/components/CopyToClipboardButton';
import ResetPasswordForm from './ResetPasswordForm';
import { UserRole } from '../../../shared/backend';
import { CustomerService } from '../../../services/customer-service/customer.service';
import { CustomerFilter } from '../../../shared/components/customer-filter/CustomerFilter';
import { ProcessTypeService } from '../../../services/process-type-service/process-type.service';
import { ProcessTypeFilter } from '../../../shared/components/process-type-filter/ProcessTypeFilter';
import { useAuthentication } from '../../../hooks/useAuthentication';
import { getTypedObjectEntries } from '../../../shared/helper/helper';
import { useTranslation } from 'react-i18next';

//If default values are given as props, the Form "puts" the Customer, based on the id
export interface UserFormProps {
  defaultValues?: Partial<SafeUserDto>;
  onSubmit: Function;
  onReactivateUser?: (userId: number) => void;
  customerId?: number;
}

export interface FormFields {
  userId: number;
  firstName: string;
  lastName: string;
  email: string;
  company: string;
  department: string;
  role: UserRole;
  customerId: number | null;
  processTypeIds: number[] | null;
}

const processTypeRestrictedRoles = [UserRole.PICKER, UserRole.WAREHOUSE_AGENT, UserRole.WAREHOUSE_SUPERVISOR];

export const UserForm: React.FC<UserFormProps> = ({ defaultValues, onReactivateUser, ...props }) => {
  const { t } = useTranslation('users');
  const auth = useAuthentication();
  const notificationHandler = useNotifications();
  const [user, setUser] = React.useState<FormFields>({
    userId: defaultValues?.userId ?? 0,
    firstName: defaultValues?.firstName ?? '',
    lastName: defaultValues?.lastName ?? '',
    email: defaultValues?.email ?? '',
    company: defaultValues?.company ?? '',
    department: defaultValues?.department ?? '',
    role: defaultValues?.role ?? UserRole.PICKER,
    customerId: defaultValues?.customerId ?? null,
    processTypeIds: defaultValues?.processTypes?.map(({ processTypeId }) => processTypeId) ?? null,
  });
  const [password, setPassword] = useState('');
  const customers = CustomerService.useCustomers();
  const processTypes = ProcessTypeService.useProcessTypes();
  const users = useUsers();

  const handleChange = (prop: keyof SafeUserDto) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setUser({ ...user, [prop]: event.target.value });
  };

  const handelSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();

    try {
      const userData: CreateUserDto = {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        role: user.role,
        customerId: user.role === UserRole.CUSTOMER ? user.customerId : null,
        processTypeIds: processTypeRestrictedRoles.includes(user.role) ? user.processTypeIds : null,
        company: user.company,
        department: user.department,
      };

      if (user.userId) {
        await updateUser(user.userId, userData);
        props.onSubmit();
      } else {
        const response = await createUser(userData);
        setPassword(response.password);
        users.mutate();
      }
    } catch (error) {
      notificationHandler.addError(error);
    }
  };

  const processTypeGroups = Object.groupBy(processTypes.data, (processType) =>
    [processType.dispatchCountry, processType.destinationCountry].sort((a, b) => a.localeCompare(b)).join(' & '),
  );

  return (
    <form onSubmit={handelSubmit}>
      <Stack
        spacing={2}
        direction="column"
        sx={{ width: 500, maxWidth: '100%' }}
      >
        {!!user.userId && (
          <TextField
            label={t('ID')}
            variant="outlined"
            value={user.userId}
            onChange={handleChange('userId')}
            disabled
          />
        )}

        <TextField
          label={t('First name')}
          variant="outlined"
          required
          value={user.firstName}
          onChange={handleChange('firstName')}
          disabled={!!password}
        />

        <TextField
          label={t('Last name')}
          variant="outlined"
          required
          value={user.lastName}
          onChange={handleChange('lastName')}
          disabled={!!password}
        />

        <TextField
          label={t('E-Mail')}
          variant="outlined"
          required
          type="email"
          value={user.email}
          onChange={handleChange('email')}
          disabled={!!password}
        />

        <TextField
          label={t('Company')}
          variant="outlined"
          value={user.company}
          onChange={handleChange('company')}
          disabled={!!password}
        />

        <TextField
          label={t('Department')}
          variant="outlined"
          value={user.department}
          onChange={handleChange('department')}
          disabled={!!password}
        />

        <FormControl fullWidth>
          <TextField
            variant="outlined"
            label={t('Role')}
            required
            select
            value={user.role}
            onChange={handleChange('role')}
            SelectProps={{
              native: false,
            }}
            disabled={!!password || !!props.customerId}
          >
            <MenuItem value={UserRole.PICKER}>{t('Picker')}</MenuItem>
            <MenuItem value={UserRole.WAREHOUSE_AGENT}>{t('Warehouse agent')}</MenuItem>
            <MenuItem
              value={UserRole.WAREHOUSE_SUPERVISOR}
              disabled={!auth.isAdmin()}
            >
              {t('Warehouse supervisor')}
            </MenuItem>
            <MenuItem
              value={UserRole.CUSTOMS_AGENT}
              disabled={!auth.isAdmin()}
            >
              {t('Customs agent')}
            </MenuItem>
            <MenuItem
              value={UserRole.CUSTOMER_CARE_AGENT}
              disabled={!auth.isAdmin()}
            >
              {t('Customer care agent')}
            </MenuItem>
            <MenuItem
              value={UserRole.CUSTOMER}
              disabled={!auth.isAdmin()}
            >
              {t('Customer')}
            </MenuItem>
            <MenuItem
              value={UserRole.ADMIN}
              disabled={!auth.isAdmin()}
            >
              {t('Admin')}
            </MenuItem>
          </TextField>
        </FormControl>

        {user.role === UserRole.CUSTOMER && (
          <CustomerFilter
            customers={customers.data}
            onChange={([customerId]) => setUser({ ...user, customerId })}
            selectedCustomerIds={user.customerId ? [user.customerId] : []}
            fullWidth={true}
            size="medium"
            required={true}
            disabled={!!props.customerId}
          />
        )}

        {processTypeRestrictedRoles.includes(user.role) && (
          <>
            <ProcessTypeFilter
              processTypes={processTypes.data}
              selectedProcessTypeIds={user.processTypeIds ?? []}
              onChange={(processTypeIds) => setUser({ ...user, processTypeIds })}
              multiple={true}
              size="medium"
              fullWidth={true}
              required={true}
            />
            <Stack
              direction="row"
              spacing={1}
            >
              {getTypedObjectEntries(processTypeGroups)
                .filter(([, group]) => (group?.length ?? 0) > 1)
                .map(([label, group]) => (
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={() =>
                      setUser({
                        ...user,
                        processTypeIds: [
                          ...new Set([
                            ...(user.processTypeIds ?? []),
                            ...(group?.map(({ processTypeId }) => processTypeId) ?? []),
                          ]),
                        ],
                      })
                    }
                  >
                    + {label}
                  </Button>
                ))}
            </Stack>
          </>
        )}

        <Button
          type="submit"
          variant="contained"
          color="secondary"
          disabled={!!password}
        >
          {!!password ? t('User created') : user.userId ? t('Update') : t('Create user')}
        </Button>

        {!!password && (
          <Alert severity="info">
            {t('The user can now log in with')} <strong>{user.email}</strong> {t('and')} <strong>{password}</strong>{' '}
            <CopyToClipboardButton value={password} />. {t('The password is only visible now.')}
          </Alert>
        )}

        {!!user.userId && <ResetPasswordForm id={user.userId} />}

        {!!user.userId && onReactivateUser && defaultValues?.isInactive && (
          <Button
            onClick={() => onReactivateUser(user.userId)}
            fullWidth
          >
            {t('Reactivate user')}
          </Button>
        )}
      </Stack>
    </form>
  );
};
