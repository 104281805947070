import { Typography } from '@mui/material';
import React from 'react';
import { ShipmentDto } from '../../services/shipment-service/shipment.service';
import { useTranslation } from 'react-i18next';

interface ReturnPackageIdentifierProps {
  shipment: ShipmentDto;
}

export const ReturnPackageIdentifier: React.FC<ReturnPackageIdentifierProps> = (
  props: ReturnPackageIdentifierProps,
) => {
  const { t } = useTranslation();

  return (
    <div className={'non'}>
      <Typography
        variant="body1"
        component="p"
      >
        {t('Return parcel number')}
      </Typography>
      <Typography
        variant="h6"
        component="p"
      >
        {props.shipment.returnPackageIdentifier ? (
          props.shipment.returnPackageIdentifier
        ) : (
          <span className={''}>{t('No number assigned')}</span>
        )}
      </Typography>
    </div>
  );
};

export {};
