import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import React from 'react';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircleOutline';
import { ShipmentDto, ShipmentService } from '../../services/shipment-service/shipment.service';
import { useAuthentication } from '../../hooks/useAuthentication';
import { Info } from '@mui/icons-material';
import LineItemRow from './LineItemRow';
import { OrderService } from '../../services/order-service/order.service';
import { useNotifications } from '../../hooks/useNotifications';
import { useBooleanParam } from '../../hooks/useParam';
import { useTranslation } from 'react-i18next';

type Props = {
  shipment: ShipmentDto;
  disabled: boolean;
};

const LineItemTable: React.FC<Props> = ({ shipment, disabled }) => {
  const { t } = useTranslation();
  const auth = useAuthentication();
  const notifications = useNotifications();
  const [showArticleWeights] = useBooleanParam('showArticleWeights', false);

  const { mutate } = OrderService.useOrderByShipmentId(shipment.shipmentId);

  if (shipment.lineItems.length === 0) {
    return (
      <Typography
        variant="h6"
        component="p"
        align="center"
        marginY={3}
      >
        {t('The package does not contain any items')} {auth.isStaff() && <span>😢</span>}
      </Typography>
    );
  }

  const articleCount = shipment.lineItems.reduce(
    (lineItemSum, lineItemQuantity) => lineItemSum + lineItemQuantity.quantity,
    0,
  );

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('Article {{articleCount}} pcs', { articleCount })}</TableCell>
            {!auth.isPicker() && <TableCell>Preis</TableCell>}
            <TableCell>{t('Quantity')}</TableCell>
            {showArticleWeights && (
              <>
                <TableCell>{t('Item weight')}</TableCell>
                <TableCell>{t('Category item weight')}</TableCell>
              </>
            )}
            {auth.isStaff() && (
              <TableCell>
                {t('Description')}
                <Tooltip title={t('This description is attached to the customs declaration.')}>
                  <Info sx={{ fontSize: '1em', verticalAlign: 'text-top' }} />
                </Tooltip>
              </TableCell>
            )}
            <TableCell align="right">{t('Return management')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {shipment.lineItems.map((lineItem) => (
            <LineItemRow
              key={lineItem.lineItemId}
              shipment={shipment}
              disabled={disabled}
              lineItem={lineItem}
            />
          ))}
        </TableBody>
        {shipment.lineItems.length > 1 && (
          <TableFooter>
            <TableRow>
              <TableCell colSpan={auth.isPicker() ? 3 : 4}></TableCell>
              <TableCell align="right">
                <Button
                  size="small"
                  variant="outlined"
                  color="error"
                  startIcon={<RemoveCircleIcon />}
                  onClick={async () => {
                    try {
                      for (const lineItem of shipment.lineItems) {
                        await ShipmentService.removeLineItemFromShipment(shipment.shipmentId, lineItem.lineItemId);
                      }
                      await mutate();
                    } catch (error) {
                      notifications.addError(error);
                    }
                  }}
                  disabled={disabled}
                >
                  {t('Remove all')}
                </Button>
              </TableCell>
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  );
};

export default LineItemTable;
