import { Backend } from '../../shared/backend';
import { api, IUseArrayResponse, SchemaType } from '../../shared/client';

export type ShippingUnitListOptionsDTO = Backend.paths['/v1/shipping/units']['get']['parameters']['query'];

export interface ShippingUnitCreateDTO extends SchemaType<'ShippingUnitCreateDTO'> {}
export interface ShippingUnitDTO extends SchemaType<'ShippingUnitDTO'> {}
export interface CreatedShippingUnitDTO extends SchemaType<'CreatedShippingUnitDTO'> {}
export interface ClosedShippingUnitDTO extends SchemaType<'ClosedShippingUnitDTO'> {}

export const SHIPPING_UNIT_BARCODE_PREFIX = 'EXSU-';
export const SHIPPING_UNIT_BARCODE_REGEX = /^EXSU-\d+-[a-z0-9]{6}$/;

export namespace ShippingService {
  export const useShippingUnits = (query: ShippingUnitListOptionsDTO | null): IUseArrayResponse<ShippingUnitDTO> => {
    const { data, error, isLoading, isValidating, mutate } = api.useApi(
      query ? `/v1/shipping/units` : null,
      query ?? {},
    );

    return {
      data: data ?? [],
      error,
      isLoading,
      isValidating,
      isError: !!error,
      mutate,
    };
  };

  export const createShippingUnit = async (processId: number, note: string): Promise<CreatedShippingUnitDTO> => {
    const response = await api.post<'/v1/shipping/units'>('/v1/shipping/units', {
      processId,
      note,
    });

    return response.data;
  };

  export const closeShippingUnit = async (shippingUnitId: number): Promise<ClosedShippingUnitDTO> => {
    const response = await api.post<'/v1/shipping/unit/{shippingUnitId}/close'>(
      `/v1/shipping/unit/${shippingUnitId}/close`,
    );

    return response.data;
  };
}
