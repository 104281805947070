import React, { PropsWithChildren, lazy } from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import 'typeface-roboto';
import { Customers as CrmCustomers } from './pages/crm/customers/Customers';
import { LoginPage } from './pages/login/LoginPage';
import { AllOrders as OrdersAllOrders } from './pages/orders/all-orders/AllOrders';
import { SettingsPage } from './pages/settings/SettingsPage';
import { NotFoundPage } from './pages/universal-error/UniversalError';
import { UsersPage } from './pages/users/UsersPage';
import { ReturnSettingsPage } from './pages/return-settings/ReturnSettingsPage';
import ActivityPage from './pages/activity/ActivityPage';
import SystemHealthPage from './pages/system/health/SystemHealthPage';
import CustomerDetails from './pages/crm/customers/components/customer-details/CustomerDetails';
import CustomerReturnSettings from './pages/crm/customers/components/customer-details/components/customer-process/CustomerReturnSettings';
import InboundShipments from './pages/shipments/InboundShipments';
import ShipmentPage from './pages/shipment/ShipmentPage';
import DefaultLayout from './shared/components/layout/DefaultLayout';
import ToursPage from './pages/customs/tours/ToursPage';
import TourPage from './pages/customs/tour/TourPage';
import TourCreationPage from './pages/customs/tour-creation/TourCreationPage';
import SettingsCustomsPage from './pages/customs/settings/SettingsCustomsPage';
import JobPage from './pages/jobs/JobPage';
import { UserRole } from './shared/backend';
import LogoutPage from './pages/login/LogoutPage';
import ReportsPage from './pages/reports/ReportsPage';
import PageLoading from './shared/components/PageLoading';
import ProcessPage from './pages/crm/process/ProcessPage';
import ErrorPage from './shared/components/ErrorPage';
import { useAuthentication } from './hooks/useAuthentication';
import { useUserInfo } from './services/user-service/user.service';
import DutiesAndVatPage from './pages/duties-and-vat/DutiesAndVatPage';
import ShippingUnitsPage from './pages/shipping/ShippingUnitsPage';

const startPath = '/orders/all-orders';

const ArticleCategories = lazy(
  () => import(/* webpackChunkName: "ArticleCategoriesPage" */ './pages/article-categories/ArticleCategories'),
);
const ArticleCategoriesWithWarnings = lazy(
  () =>
    import(
      /* webpackChunkName: "ArticleCategoriesWithWarningsPage" */ './pages/article-categories/ArticleCategoriesWithWarnings'
    ),
);
const Articles = lazy(() => import(/* webpackChunkName: "ArticlesPage" */ './pages/articles/Articles'));
const NonTariffedArticles = lazy(
  () => import(/* webpackChunkName: "NonTariffedArticles" */ './pages/articles/NonTariffedArticles'),
);

const TourDetailsPage = lazy(
  () => import(/* webpackChunkName: "TourDetailsPage" */ './pages/customs/tour-details/TourDetailsPage'),
);

interface IProtectedPageProps {
  roles: UserRole[];
}

const ProtectedPage: React.FC<PropsWithChildren<IProtectedPageProps>> = ({ roles, children }) => {
  const userInfo = useUserInfo();
  const { pathname, search } = useLocation();

  if (userInfo.isLoading) {
    return <PageLoading />;
  }

  if (!userInfo.data?.role) {
    const ref = `${pathname}${search}`;

    return (
      <Navigate
        to={ref === startPath ? '/login' : `/login?ref=${encodeURIComponent(ref)}`}
        replace
      />
    );
  }

  if (!roles.length || roles.includes(userInfo.data.role)) {
    return (
      <DefaultLayout>
        <React.Suspense fallback={<PageLoading />}>{children}</React.Suspense>
      </DefaultLayout>
    );
  }

  return (
    <DefaultLayout>
      <ErrorPage
        title="Permission denied"
        content="You are not allowed to view this page. If you think you should be allowed, please contact the exporto support team."
      />
    </DefaultLayout>
  );
};

const AppRouter: React.FC = () => {
  const auth = useAuthentication();

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <Navigate
              to={'/orders/all-orders'}
              replace
            />
          }
        />

        <Route
          path="/login/*"
          element={<LoginPage />}
        />

        <Route
          path="/logout"
          element={<LogoutPage />}
        />

        <Route
          path="/users"
          element={
            <ProtectedPage roles={[UserRole.ADMIN, UserRole.WAREHOUSE_SUPERVISOR]}>
              <UsersPage />
            </ProtectedPage>
          }
        />

        <Route path="/crm">
          <Route
            path="process/:processId"
            element={
              <ProtectedPage roles={[UserRole.ADMIN, UserRole.CUSTOMER_CARE_AGENT, UserRole.CUSTOMS_AGENT]}>
                <ProcessPage />
              </ProtectedPage>
            }
          />

          <Route
            path="customers"
            element={
              <ProtectedPage roles={[UserRole.ADMIN, UserRole.CUSTOMER_CARE_AGENT, UserRole.CUSTOMS_AGENT]}>
                <CrmCustomers />
              </ProtectedPage>
            }
          />
          <Route
            path="customers/:customerId"
            element={
              <ProtectedPage roles={[UserRole.ADMIN, UserRole.CUSTOMER_CARE_AGENT, UserRole.CUSTOMS_AGENT]}>
                <CustomerDetails new={false} />
              </ProtectedPage>
            }
          />
          <Route
            path="customers/:customerId/settings/:processId/return"
            element={
              <ProtectedPage roles={[UserRole.ADMIN, UserRole.CUSTOMER, UserRole.CUSTOMER_CARE_AGENT]}>
                <CustomerReturnSettings />
              </ProtectedPage>
            }
          />
          <Route
            path="customers/new"
            element={
              <ProtectedPage roles={[UserRole.ADMIN, UserRole.CUSTOMER_CARE_AGENT]}>
                <CustomerDetails new={true} />
              </ProtectedPage>
            }
          />
        </Route>

        <Route
          path="/articles"
          element={
            <ProtectedPage roles={[UserRole.ADMIN, UserRole.CUSTOMER_CARE_AGENT, UserRole.CUSTOMS_AGENT]}>
              <Articles />
            </ProtectedPage>
          }
        />

        <Route
          path="/articles/non-tariffed"
          element={
            <ProtectedPage roles={[UserRole.ADMIN, UserRole.CUSTOMS_AGENT]}>
              <NonTariffedArticles />
            </ProtectedPage>
          }
        />

        <Route path="/article-categories">
          <Route
            path=""
            element={
              <Navigate
                to="/article-categories/edit"
                replace
              />
            }
          />
          <Route
            path="edit"
            element={
              <ProtectedPage roles={[UserRole.ADMIN, UserRole.CUSTOMS_AGENT]}>
                <ArticleCategories />
              </ProtectedPage>
            }
          />
          <Route
            path="search"
            element={
              <ProtectedPage roles={[UserRole.ADMIN, UserRole.CUSTOMS_AGENT]}>
                <ArticleCategories searchPage={true} />
              </ProtectedPage>
            }
          />
          <Route
            path="warnings"
            element={
              <ProtectedPage roles={[UserRole.ADMIN, UserRole.CUSTOMS_AGENT]}>
                <ArticleCategoriesWithWarnings />
              </ProtectedPage>
            }
          />
        </Route>

        <Route
          path="/orders/all-orders"
          element={
            <ProtectedPage roles={[]}>
              <OrdersAllOrders />
            </ProtectedPage>
          }
        />

        <Route path="/shipments">
          <Route
            path=""
            element={
              <Navigate
                to="/shipments/inbound"
                replace
              />
            }
          />

          <Route
            path="inbound"
            element={
              <ProtectedPage roles={[]}>
                <InboundShipments />
              </ProtectedPage>
            }
          />
        </Route>

        <Route path="/shipping">
          <Route
            path=""
            element={
              <Navigate
                to="/shipping/units"
                replace
              />
            }
          />

          <Route
            path="units"
            element={
              <ProtectedPage
                roles={[
                  UserRole.ADMIN,
                  UserRole.CUSTOMS_AGENT,
                  UserRole.CUSTOMER_CARE_AGENT,
                  UserRole.WAREHOUSE_AGENT,
                  UserRole.WAREHOUSE_SUPERVISOR,
                ]}
              >
                <ShippingUnitsPage />
              </ProtectedPage>
            }
          />
        </Route>

        <Route path="/customs">
          <Route
            path=""
            element={
              <ProtectedPage
                roles={[
                  UserRole.ADMIN,
                  UserRole.CUSTOMS_AGENT,
                  UserRole.WAREHOUSE_AGENT,
                  UserRole.WAREHOUSE_SUPERVISOR,
                ]}
              >
                <Navigate
                  to={auth.isWarehouse() ? '/customs/tour/new' : '/customs/tours'}
                  replace
                />
              </ProtectedPage>
            }
          />
          <Route
            path="tours"
            element={
              <ProtectedPage roles={[UserRole.ADMIN, UserRole.CUSTOMS_AGENT]}>
                <ToursPage />
              </ProtectedPage>
            }
          />
          <Route
            path="tour/new"
            element={
              <ProtectedPage
                roles={[
                  UserRole.ADMIN,
                  UserRole.CUSTOMS_AGENT,
                  UserRole.WAREHOUSE_AGENT,
                  UserRole.WAREHOUSE_SUPERVISOR,
                ]}
              >
                <TourCreationPage />
              </ProtectedPage>
            }
          />
          <Route
            path="tour/:tourBatchId"
            element={
              <ProtectedPage roles={[UserRole.ADMIN, UserRole.CUSTOMS_AGENT]}>
                <TourPage />
              </ProtectedPage>
            }
          />
          <Route
            path="tour-details/:tourId/*"
            element={
              <ProtectedPage roles={[UserRole.ADMIN, UserRole.CUSTOMS_AGENT]}>
                <TourDetailsPage />
              </ProtectedPage>
            }
          />
          <Route
            path="settings"
            element={
              <ProtectedPage roles={[UserRole.ADMIN, UserRole.CUSTOMS_AGENT]}>
                <SettingsCustomsPage />
              </ProtectedPage>
            }
          />
        </Route>

        <Route
          path="/shipment/:shipmentId"
          element={
            <ProtectedPage roles={[]}>
              <ShipmentPage />
            </ProtectedPage>
          }
        />

        <Route
          path="/settings/returns"
          element={
            <ProtectedPage roles={[UserRole.ADMIN, UserRole.CUSTOMER, UserRole.CUSTOMER_CARE_AGENT]}>
              <ReturnSettingsPage />
            </ProtectedPage>
          }
        />
        <Route
          path="/settings"
          element={
            <ProtectedPage roles={[]}>
              <SettingsPage />
            </ProtectedPage>
          }
        />

        <Route
          path="/activity"
          element={
            <ProtectedPage roles={[UserRole.ADMIN, UserRole.CUSTOMS_AGENT, UserRole.CUSTOMER_CARE_AGENT]}>
              <ActivityPage />
            </ProtectedPage>
          }
        />

        <Route
          path="/jobs"
          element={
            <ProtectedPage roles={[UserRole.ADMIN, UserRole.CUSTOMS_AGENT]}>
              <JobPage />
            </ProtectedPage>
          }
        />

        <Route path="/reports">
          <Route
            path=""
            element={
              <ProtectedPage roles={[UserRole.ADMIN, UserRole.CUSTOMER, UserRole.CUSTOMER_CARE_AGENT]}>
                <Navigate
                  to="/reports/shipments"
                  replace
                />
              </ProtectedPage>
            }
          />

          <Route
            path="shipments"
            element={
              <ProtectedPage roles={[UserRole.ADMIN, UserRole.CUSTOMER, UserRole.CUSTOMER_CARE_AGENT]}>
                <ReportsPage />
              </ProtectedPage>
            }
          />

          <Route
            path="duties-and-vat"
            element={
              <ProtectedPage roles={[UserRole.ADMIN, UserRole.CUSTOMER, UserRole.CUSTOMER_CARE_AGENT]}>
                <DutiesAndVatPage />
              </ProtectedPage>
            }
          />
        </Route>

        <Route
          path="/system/health"
          element={
            <ProtectedPage roles={[]}>
              <SystemHealthPage />
            </ProtectedPage>
          }
        />

        <Route
          path="/duties-and-vat"
          element={
            <Navigate
              to={'/reports/duties-and-vat'}
              replace
            />
          }
        />

        <Route
          path="*"
          element={<NotFoundPage />}
        />

        <Route
          path="/"
          element={
            <Navigate
              to={startPath}
              replace
            />
          }
        />
      </Routes>
    </Router>
  );
};

export default AppRouter;
