import { IconButton, InputAdornment, TextField } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useScanInput } from '../../shared/hooks/keypress';
import { Clear } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const MIN_SCAN_LENGTH = 4;

interface BarcodeReaderProps {
  focus: boolean;
  handleScanBarcode: (barcode: string) => Promise<boolean>;
  onSubmit: () => void;
  disabled?: boolean;
}

export const BarcodeReader: React.FC<BarcodeReaderProps> = ({ focus, handleScanBarcode, onSubmit, disabled }) => {
  const { t } = useTranslation();

  const [prevBarcode, setPrevBarcode] = useState('');
  const [barcode, setBarcode] = useState('');
  const [found, setFound] = useState<boolean | null>(null);

  const scanCallback = useCallback(
    (buffer: string) => {
      if (disabled) {
        return;
      }

      if (buffer.length >= MIN_SCAN_LENGTH) {
        setPrevBarcode('');
        setBarcode(buffer);
      }
    },
    [disabled],
  );

  useScanInput(scanCallback);

  useEffect(() => {
    const isPastedInput = barcode.length >= MIN_SCAN_LENGTH && barcode.length - prevBarcode.length > MIN_SCAN_LENGTH;

    if (isPastedInput) {
      handleScanBarcode(barcode).then((result) => setFound(result));
    }

    setPrevBarcode(barcode);
  }, [handleScanBarcode, barcode, prevBarcode]);

  useEffect(() => {
    if (found) {
      setTimeout(() => {
        setBarcode('');
        setPrevBarcode('');
        setFound(null);
      }, 500);
    }
  }, [found]);

  return (
    <div>
      <form
        onSubmit={(ev) => {
          ev.preventDefault();

          if (!barcode) {
            onSubmit();
          } else {
            handleScanBarcode(barcode).then((result) => setFound(result));
          }
        }}
      >
        <TextField
          size="small"
          inputProps={{ tabIndex: 2 }}
          value={barcode}
          onChange={(ev) => {
            setBarcode(ev.target.value.trim());
            setFound(null);
          }}
          placeholder={t('Scan barcode')}
          autoFocus={focus}
          variant={'outlined'}
          autoComplete="off"
          disabled={disabled}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {found ? (
                  <CheckCircleIcon color="success" />
                ) : found === false ? (
                  <IconButton
                    size="small"
                    edge="end"
                    onClick={() => {
                      setBarcode('');
                      setPrevBarcode('');
                      setFound(null);
                    }}
                    color="warning"
                  >
                    <Clear />
                  </IconButton>
                ) : undefined}
              </InputAdornment>
            ),
          }}
        />
      </form>
    </div>
  );
};
