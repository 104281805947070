import { Box, Button, ButtonGroup, Grid, List, ListItem, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { ActivityDto } from '../../../services/activity-service/activity.service';
import DataTable, { TDataTableColumn } from '../../../shared/components/data-table/DataTable';
import JsonDiff from '../../../shared/components/diff/JsonDiff';
import UserLabel from './UserLabel';
import { Lock, LockOpen, Warning } from '@mui/icons-material';
import { OrderState } from '../../../shared/backend';
import ScaleIcon from '@mui/icons-material/Scale';
import LinkIcon from '@mui/icons-material/Link';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { TFunction } from 'i18next';

export const renderJson = (data: Record<string, unknown> | null) => (
  <Box
    sx={() => ({
      backgroundColor: 'action.hover',
      whiteSpace: 'pre-wrap',
      fontFamily: 'monospace',
      padding: 2,
    })}
  >
    {JSON.stringify(data, undefined, 2)}
  </Box>
);

const Panel = (row: ActivityDto) => {
  const [view, setView] = useState<'changes' | 'diff' | 'two'>(row.beforeValue && row.afterValue ? 'changes' : 'two');

  return (
    <Grid
      container
      spacing={1}
    >
      {(view === 'diff' || view === 'changes') && (
        <Grid
          item
          xs={12}
        >
          <Box
            sx={{
              backgroundColor: 'action.hover',
              fontFamily: 'monospace',
              padding: 2,
            }}
          >
            <JsonDiff
              onlyChanges={view === 'changes'}
              old={row.beforeValue ?? {}}
              current={row.afterValue ?? {}}
            />
          </Box>
        </Grid>
      )}
      {view === 'two' && (
        <>
          <Grid
            item
            sm={6}
          >
            {renderJson(row.beforeValue)}
          </Grid>
          <Grid
            item
            sm={6}
          >
            {renderJson(row.afterValue)}
          </Grid>
        </>
      )}
      <Grid
        item
        xs={12}
      >
        <ButtonGroup
          size="small"
          disableElevation
        >
          <Button
            variant={view === 'changes' ? 'contained' : 'outlined'}
            onClick={() => setView('changes')}
          >
            Änderungen
          </Button>
          <Button
            variant={view === 'diff' ? 'contained' : 'outlined'}
            onClick={() => setView('diff')}
          >
            Diff
          </Button>
          <Button
            variant={view === 'two' ? 'contained' : 'outlined'}
            onClick={() => setView('two')}
          >
            2 Spalten
          </Button>
        </ButtonGroup>
      </Grid>
      {row.context && (
        <Grid
          item
          xs={12}
        >
          <List dense>
            {row.context.referrer && (
              <ListItem>
                <ListItemIcon>
                  <LinkIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    row.context.referrer.startsWith(window.location.origin) ? (
                      <Link to={row.context.referrer}>{row.context.referrer}</Link>
                    ) : (
                      row.context.referrer
                    )
                  }
                />
              </ListItem>
            )}
          </List>

          {typeof row.context.scaleWeight === 'number' && (
            <ListItem>
              <ListItemIcon>
                <ScaleIcon />
              </ListItemIcon>
              <ListItemText primary={row.context.scaleWeight ?? ''} />
            </ListItem>
          )}
        </Grid>
      )}
    </Grid>
  );
};

function getActionLabel(activity: ActivityDto, t: TFunction<'activity'>): React.ReactNode {
  let icon: React.ReactNode | null;

  if (activity.action === 'order:update') {
    if (activity.beforeValue?.state !== OrderState.BLOCKED && activity.afterValue?.state === OrderState.BLOCKED) {
      icon = (
        <Lock
          fontSize="small"
          sx={{ verticalAlign: 'bottom' }}
        />
      );
    } else if (
      activity.beforeValue?.state === OrderState.BLOCKED &&
      activity.afterValue?.state !== OrderState.BLOCKED
    ) {
      icon = (
        <LockOpen
          fontSize="small"
          sx={{ verticalAlign: 'bottom' }}
        />
      );
    }
  } else if (
    activity.action.startsWith('category') &&
    activity.action.includes(':finished:') &&
    typeof activity.afterValue?.duration === 'number'
  ) {
    const durationInMinutes = Math.round(activity.afterValue?.duration / 100 / 60) / 10;
    const categoryCount = activity.afterValue?.categoryCount;

    return (
      <>
        {categoryCount} categories in {durationInMinutes} minutes
      </>
    );
  } else if (activity.action.includes('error')) {
    icon = (
      <Warning
        fontSize="small"
        sx={{ verticalAlign: 'bottom' }}
        color="warning"
      />
    );
  }

  return (
    <>
      {activity.action} {icon}{' '}
      {activity.context ? (
        <Tooltip title={t('Context available')}>
          <NewspaperIcon
            fontSize="small"
            sx={{ verticalAlign: 'bottom' }}
            color="disabled"
          />
        </Tooltip>
      ) : undefined}
    </>
  );
}

type Props = {
  activities: ActivityDto[];
  isLoading?: boolean;
};

const GeneralActivityTable: React.FC<Props> = ({ activities, isLoading }) => {
  const { t } = useTranslation('activity');

  const columns: TDataTableColumn<ActivityDto>[] = [
    {
      title: t('Id'),
      field: 'activityId',
    },
    {
      title: t('Action'),
      render: (activity) => getActionLabel(activity, t),
    },
    {
      title: t('User'),
      render: (activity) => (activity.userId ? <UserLabel userId={activity.userId} /> : <span></span>),
    },
    {
      title: t('Entity Name'),
      field: 'entityName',
    },
    {
      title: t('Entity Id'),
      field: 'entityId',
    },
    {
      title: t('Created'),
      render: (activity) => (
        <Tooltip title={DateTime.fromISO(activity.createdAt).toString()}>
          <span>{DateTime.fromISO(activity.createdAt).toRelative()}</span>
        </Tooltip>
      ),
    },
  ];

  return (
    <DataTable
      columns={columns}
      rows={activities}
      isLoading={isLoading}
      detailPanel={Panel}
    />
  );
};

export default GeneralActivityTable;
