import React from 'react';
import GradeIcon from '@mui/icons-material/Grade';
import { ShipmentDto } from '../../services/shipment-service/shipment.service';
import { OrderWithShipmentsAndToursDto } from '../../services/order-service/order.service';
import { Alert, AlertTitle, Box, Grid } from '@mui/material';
import { OrderBlockedBy, OrderState, ShipmentReturnHandling, ShipmentType } from '../../shared/backend';
import PersonIcon from '@mui/icons-material/Person';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import { useTranslation } from 'react-i18next';

type Props = {
  shipment: ShipmentDto;
  order: OrderWithShipmentsAndToursDto;
};

const OrderWarnings: React.FC<Props> = ({ order, shipment }) => {
  const { t } = useTranslation();

  const isSpecialTreatment =
    order.process.returnPackageHandling === ShipmentReturnHandling.SPECIAL_TREATMENT &&
    shipment.type === ShipmentType.RETOUR;
  const isBlocked = order.state === OrderState.BLOCKED || order.process.blocked;
  const isEmpty = order.lineItems.length === 0;

  if (!isSpecialTreatment && !isBlocked && !isEmpty) {
    return <></>;
  }

  const shopName = order.process.customer.company;

  return (
    <Grid
      item
      xs={12}
    >
      {isSpecialTreatment && (
        <Box m={3}>
          <Alert
            severity="info"
            icon={<GradeIcon />}
          >
            <AlertTitle>{t('Special process!')}</AlertTitle>
            {t('Attention, special process, no normal return. Shop: {{shopName}}', { shopName })}
          </Alert>
        </Box>
      )}

      {isEmpty && (
        <Box m={3}>
          <Alert severity="warning">
            <AlertTitle>{t('The order is empty!')}</AlertTitle>
            {t('An order without an item cannot be cleared through customs, so please do not process the shipment.')}
          </Alert>
        </Box>
      )}

      {isBlocked && (
        <Box m={3}>
          <Alert
            severity="warning"
            icon={
              order.blockedBy === OrderBlockedBy.ARTICLE ? (
                <SmartToyIcon />
              ) : order.blockedBy === OrderBlockedBy.USER ? (
                <PersonIcon />
              ) : undefined
            }
          >
            {order.process.blocked ? (
              <AlertTitle>{t('The store is blocked!')}</AlertTitle>
            ) : (
              <AlertTitle>
                {order.blockedBy === OrderBlockedBy.USER
                  ? t('The order is blocked by a user!')
                  : t('The order is blocked by an article!')}
              </AlertTitle>
            )}
            {t('Please do not process any of the shipments, but contact the support team instead.')}
          </Alert>
        </Box>
      )}
    </Grid>
  );
};

export default OrderWarnings;
