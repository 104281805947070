import React from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import { UserTable } from './components/userTable';
import { useTranslation } from 'react-i18next';

export const UsersPage = () => {
  const { t } = useTranslation('users');

  return (
    <Paper sx={{ px: 2, py: 3 }}>
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}
        >
          <Typography
            variant="h5"
            gutterBottom
          >
            {t('Users')}
          </Typography>
          <Typography
            variant="body1"
            mb={3}
          >
            {t(
              'All accounts can be viewed and edited here. A deleted account is only deactivated and can be restored via the DEV-Team.',
            )}
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
        >
          <UserTable />
        </Grid>
      </Grid>
    </Paper>
  );
};
