import React from 'react';
import { TourAndChecksDto } from '../../../../services/tour-service/tour.service';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import { Block, Note } from '@mui/icons-material';
import { Tooltip } from '@mui/material';

type Props = {
  tour: TourAndChecksDto['tour'];
  checks: TourAndChecksDto['checks'];
};

const TourCustomerCell: React.FC<Props> = ({ tour, checks }) => {
  return (
    <>
      {!checks.hasClosedTourForProcess && (
        <FiberNewIcon
          color="info"
          sx={{ mr: 0.5, verticalAlign: 'middle' }}
        />
      )}
      {tour.process.customsNote && (
        <Tooltip title={tour.process.customsNote}>
          <Note
            color="info"
            sx={{ mr: 0.5, verticalAlign: 'middle' }}
          />
        </Tooltip>
      )}
      {tour.process.blocked && (
        <Block
          color="error"
          sx={{ mr: 0.5 }}
        />
      )}
      {tour.process.customer.company} ({tour.processId})
    </>
  );
};

export default TourCustomerCell;
