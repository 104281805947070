import { Checkbox, FormControl, Select, MenuItem, Stack, IconButton, InputAdornment } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Chip from '@mui/material/Chip';
import InputLabel from '@mui/material/InputLabel';
import { Theme } from '@mui/material/styles';
import ListItemText from '@mui/material/ListItemText';
import { default as React, FC, useEffect, Fragment } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import { OrderState } from '../../backend';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '60px',
      display: 'flex',
      alignItems: 'center',
    },
  }),
);

const states: OrderState[] = [
  OrderState.NEW,
  OrderState.IN_PROGRESS,
  OrderState.REJECTED,
  OrderState.CLOSED,
  OrderState.BLOCKED,
];

interface OrderFilterProps {
  orderState: OrderState[];
  onChange: (orders: OrderState[]) => void;
}

export const OrderStatesFilter: FC<OrderFilterProps> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { orderState } = props;

  const stateLabels = {
    [OrderState.NEW]: t('New'),
    [OrderState.IN_PROGRESS]: t('In progress'),
    [OrderState.REJECTED]: t('Rejected'),
    [OrderState.CLOSED]: t('Completed'),
    [OrderState.BLOCKED]: t('Blocked'),
  };

  const renderValue = (selected: unknown) => {
    return (
      <Stack
        direction="row"
        spacing={1}
      >
        {orderState?.map((state) => (
          <Chip
            size="small"
            color="secondary"
            key={state}
            label={stateLabels[state]}
          />
        ))}
      </Stack>
    );
  };

  useEffect(() => {
    if (orderState) {
      props.onChange(orderState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderState]);

  return (
    <div className={classes.root}>
      <Fragment>
        <form onSubmit={(event) => event.preventDefault}>
          <FormControl
            size="small"
            variant="outlined"
            sx={{ minWidth: 120 }}
          >
            {props.orderState.length === 0 && <InputLabel id="order-filter-label">{t('State')}</InputLabel>}
            <Select
              sx={{ minHeight: 41 }}
              labelId="order-filter-label"
              label={props.orderState.length === 0 ? t('State') : undefined}
              multiple
              onChange={(event) => props.onChange(event.target.value as OrderState[])}
              value={orderState}
              renderValue={(selected) => renderValue(selected)}
              startAdornment={
                orderState.length > 0 && (
                  <InputAdornment position="start">
                    <IconButton
                      onClick={() => props.onChange([])}
                      edge="start"
                      size="small"
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                )
              }
            >
              {states.map((state: OrderState) => (
                <MenuItem
                  key={state}
                  value={state}
                  sx={{ pl: 0 }}
                >
                  <Checkbox checked={orderState.indexOf(state) > -1} />
                  <ListItemText primary={stateLabels[state]} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </form>
      </Fragment>
    </div>
  );
};
