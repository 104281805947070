import React, { useState } from 'react';
import { ShipmentDto, ShipmentService } from '../../services/shipment-service/shipment.service';
import { Stack, Typography, CircularProgress, Button, Box, Tooltip, Link } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FiberNewIcon from '@mui/icons-material/FiberNew';

import { ReturnPackageIdentifier } from './ReturnPackageIdentifier';
import {
  getTrackingUrl,
  TrackingInformation,
} from '../orders/shared/components/order-detail-modal/components/shipment-view/components/TrackingInformation';
import ShipmentStateCircle from './ShipmentStateCircle';
import { useAuthentication } from '../../hooks/useAuthentication';
import ShipmentWeight from './ShipmentWeight';
import { OrderService, OrderWithShipmentsAndToursDto } from '../../services/order-service/order.service';
import { useNotifications } from '../../hooks/useNotifications';
import { Warning } from '@mui/icons-material';
import { getConsignedInboundLineItemIds, getTotalOrderWeight, isAssignAllLineItems } from './shipment.helper';
import { OrderType, ShipmentState, ShipmentType } from '../../shared/backend';
import { useDebugView } from '../../shared/hooks/dev-mode';
import { useNavigate } from 'react-router-dom';
import { useHistoryRef } from '../../hooks/useHistoryNavigation';
import { useTranslation } from 'react-i18next';
import HistoryIconButton from '../../shared/components/HistoryIconButton';
import { ProcessDto } from '../../services/process-service/process.service';
import ButtonAsync from '../../shared/components/ButtonAsync';
import PiPIcon from '../../shared/PiPIcon';

type Props = {
  shipment: ShipmentDto;
  order: OrderWithShipmentsAndToursDto;
  process: ProcessDto;
  heading: string;
  editMode: boolean;
  readyForNext: () => void;
  acceptShipment: (shippingWeight: number | null) => Promise<void>;
};

const ShipmentHeader: React.FC<Props> = ({
  shipment,
  order,
  process,
  heading,
  editMode,
  readyForNext,
  acceptShipment,
}) => {
  const { t } = useTranslation();
  const { mutate } = OrderService.useOrderByShipmentId(shipment.shipmentId);
  const outgoingShipments = ShipmentService.useShipmentsByOrder(shipment.orderId, OrderType.ORDER);
  const [isDebugView] = useDebugView();
  const notifications = useNotifications();
  const navigateTo = useNavigate();
  const auth = useAuthentication();
  const historyRef = useHistoryRef();

  const [isWorking, setWorking] = useState(false);
  const [isDeleting, setDeleting] = useState(false);

  const shipmentState = shipment.state;

  const done = () => (!editMode ? readyForNext() : undefined);
  const isEmpty = shipment.lineItems.length === 0;
  const isInvalidShipment = !shipment.shippingWeight || isEmpty;
  const isAllowedToDispose = auth.isPermanentEmployee() && shipment.state === ShipmentState.TO_BE_CLARIFIED;
  const isReadyToProcess =
    (shipment.state === ShipmentState.RETOURE_ANNOUNCED || isAllowedToDispose) && !isInvalidShipment;

  const totalOrderWeight = getTotalOrderWeight(outgoingShipments.data);

  const domesticInboundTrackingUrl = shipment.domesticInboundTrackingId
    ? getTrackingUrl(shipment.domesticInboundTrackingId)
    : null;

  const updateShipmentState = async (state: ShipmentState) => {
    try {
      await ShipmentService.updateShipment(
        shipment.shipmentId,
        {
          state,
        },
        { referrer: historyRef ?? undefined },
      );

      done();

      await mutate();
    } catch (err) {
      notifications.addError(err);
    }
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={1}
    >
      <ShipmentStateCircle shipmentState={shipmentState} />

      <Tooltip title={heading}>
        <Typography
          variant="h5"
          component="h1"
          sx={{
            maxWidth: '10em',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {heading}
        </Typography>
      </Tooltip>

      <HistoryIconButton
        entityName="shipment"
        entityId={shipment.shipmentId}
      />

      <ShipmentWeight
        shipment={shipment}
        disabled={(shipmentState !== ShipmentState.RETOURE_ANNOUNCED && !editMode) || shipment.isConsigned}
        totalOrderWeight={totalOrderWeight}
        onSubmit={async (weight) => {
          setWorking(true);

          let allLineItemsAssigned = false;

          const shouldAssignAllLineItems =
            process.autoReturnShipmentConfirmationEnabled && isAssignAllLineItems(shipment, totalOrderWeight, weight);

          if (shouldAssignAllLineItems) {
            try {
              const consignedLineItemIds = getConsignedInboundLineItemIds(outgoingShipments.data);
              const unassignedLineItems = order.lineItems.filter(
                (lineItem) => !lineItem.returnShipmentId && consignedLineItemIds.includes(lineItem.lineItemId),
              );

              for (const { lineItemId } of unassignedLineItems) {
                await ShipmentService.addLineItemToShipment(shipment.shipmentId, lineItemId);
              }

              if (unassignedLineItems.length) {
                allLineItemsAssigned = true;
                notifications.addSuccess(t('All articles have been added'));
              }
            } catch (err) {
              notifications.addError(err);

              return;
            }
          }

          const isNowReadyToProcess = shipment.state === ShipmentState.RETOURE_ANNOUNCED && !isEmpty && !!weight;

          if (
            shipment.type === ShipmentType.RETOUR &&
            (allLineItemsAssigned || isReadyToProcess || isNowReadyToProcess)
          ) {
            await acceptShipment(weight);
          }

          await mutate();

          setWorking(false);
        }}
      />

      {isDebugView && (
        <Box pl={2}>
          <Typography
            variant="body1"
            component="p"
          >
            Bestellgewicht
          </Typography>
          <Typography
            variant="h6"
            component="p"
          >
            {totalOrderWeight}g
          </Typography>
        </Box>
      )}

      <TrackingInformation shipment={shipment} />

      {process.useShippingUnitForReturns && (
        <Box>
          <Typography
            variant="body1"
            component="p"
          >
            {t('Shipping unit')}
          </Typography>
          <Typography
            variant="h6"
            component="p"
          >
            {domesticInboundTrackingUrl ? (
              <Link
                sx={{ color: 'inherit' }}
                href={domesticInboundTrackingUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {shipment.domesticInboundTrackingId}
              </Link>
            ) : (
              shipment.domesticInboundTrackingId ?? shipment.shippingUnitId ?? <em>{t('Not assigned')}</em>
            )}
          </Typography>
        </Box>
      )}

      {auth.isStaff() && shipment.type === ShipmentType.SHIPMENT && <ReturnPackageIdentifier shipment={shipment} />}

      <Box flexGrow={1}></Box>

      <Stack
        alignItems="center"
        direction="row"
        spacing={1}
      >
        {isWorking && (
          <CircularProgress
            color="secondary"
            size="1.8em"
          />
        )}

        {shipmentState === ShipmentState.RETOURE_ANNOUNCED && !!shipment.returnPackageIdentifier && (
          <Tooltip title={t('The shipment has already been processed')}>
            <Warning color="warning" />
          </Tooltip>
        )}

        {(shipmentState === ShipmentState.RETOURE_ANNOUNCED ||
          shipmentState === ShipmentState.RETOURE_REJECTED ||
          (shipmentState === ShipmentState.TO_BE_CLARIFIED && auth.isPermanentEmployee()) ||
          editMode) && (
          <>
            <ButtonAsync
              variant="contained"
              color="printed"
              onClick={async () => {
                await acceptShipment(shipment.shippingWeight);

                await mutate();
              }}
              disabled={!isReadyToProcess || shipmentState === ShipmentState.RETOURE_TOUR_READY || shipment.isConsigned}
              startIcon={process.useShippingUnitForReturns ? <PiPIcon /> : <CheckIcon />}
              tabIndex={3}
            >
              {t('Confirm')}
            </ButtonAsync>

            {shipmentState !== ShipmentState.TO_BE_CLARIFIED && (
              <Button
                variant="outlined"
                size="small"
                onClick={async () => {
                  await updateShipmentState(ShipmentState.TO_BE_CLARIFIED);
                }}
                disabled={(!isReadyToProcess && !editMode) || shipment.isConsigned}
                startIcon={<ContactSupportIcon />}
              >
                {t('Need for clarification')}
              </Button>
            )}

            {shipmentState === ShipmentState.TO_BE_CLARIFIED && (
              <Button
                variant="outlined"
                size="small"
                onClick={async () => {
                  await updateShipmentState(ShipmentState.DISPOSED);
                }}
                disabled={!isReadyToProcess || shipment.isConsigned}
                startIcon={<DeleteForeverIcon />}
              >
                {t('Dispose')}
              </Button>
            )}

            {shipment.state !== ShipmentState.RETOURE_ANNOUNCED && (
              <Button
                variant="outlined"
                size="small"
                onClick={async () => {
                  await updateShipmentState(ShipmentState.RETOURE_ANNOUNCED);
                }}
                startIcon={<FiberNewIcon />}
                disabled={shipment.isConsigned || !!shipment.tourId}
              >
                {t('Announced')}
              </Button>
            )}
          </>
        )}

        {auth.isPermanentEmployee() && (
          <Button
            variant="contained"
            color="error"
            onClick={async () => {
              const nextShipment = order.shipments.filter(({ shipmentId }) => shipmentId !== shipment.shipmentId)[0];
              const shipmentId = shipment.shipmentId;

              if (nextShipment) {
                setDeleting(true);

                try {
                  await ShipmentService.deleteShipment(shipmentId);

                  navigateTo(`/shipment/${nextShipment.shipmentId}`);

                  notifications.addSuccess(t(`Shipment {{shipmentId}} has been deleted`));

                  mutate();
                } catch (error) {
                  notifications.addError(error);
                } finally {
                  setDeleting(false);
                }
              }
            }}
            disabled={
              !!shipment.tourId ||
              shipment.isConsigned ||
              (shipment.state !== ShipmentState.RETOURE_ANNOUNCED && !editMode) ||
              order.shipments.length <= 1 ||
              isDeleting
            }
          >
            {isDeleting ? <CircularProgress size="1.6em" /> : <DeleteIcon />}
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

export default ShipmentHeader;
