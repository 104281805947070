import React from 'react';
import { ShipmentDto } from '../../services/shipment-service/shipment.service';
import { Edit } from '@mui/icons-material';
import { Stack, Typography, Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import ShipmentStateCircle from './ShipmentStateCircle';
import { useTranslation } from 'react-i18next';

type Props = {
  shipment: ShipmentDto;
  selected: boolean;
};

const ShipmentCompact: React.FC<Props> = ({ shipment, selected }) => {
  const { t } = useTranslation();

  const shipmentId = shipment.shipmentId;
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
    >
      <ShipmentStateCircle shipmentState={shipment.state} />
      <Typography
        variant="h5"
        component="h1"
      >
        {t('Return {{shipmentId}}', { shipmentId })}
      </Typography>
      <Typography variant="body2">{shipment.lineItems.length} Artikel</Typography>

      {shipment.shippingWeight && <Typography variant="body2">{shipment.shippingWeight}g</Typography>}

      <Box flexGrow={1}></Box>

      <Button
        component={Link}
        to={`/shipment/${shipment.shipmentId}`}
        startIcon={<Edit />}
        variant="outlined"
        size="small"
        disabled={selected}
      >
        {t('Edit')}
      </Button>
    </Stack>
  );
};

export default ShipmentCompact;
