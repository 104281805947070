import { Alert, AlertTitle, Box } from '@mui/material';
import React from 'react';
import { ProcessDto, ProcessService } from '../../../../services/process-service/process.service';
import { ShipmentService } from '../../../../services/shipment-service/shipment.service';
import { ShipmentState, ShipmentType } from '../../../../shared/backend';
import { Link } from 'react-router-dom';
import BlockIcon from '@mui/icons-material/Block';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import ButtonAsync from '../../../../shared/components/ButtonAsync';
import { useNotifications } from '../../../../hooks/useNotifications';
import { ReauthTimeInMinutes, assertAuthentication } from '../../../../shared/components/ReAuthenticationDialog';
import { useTranslation } from 'react-i18next';

type Props = {
  process: ProcessDto;
};

const BlockProcess: React.FC<Props> = ({ process }) => {
  const { t } = useTranslation('CRM');

  const notifications = useNotifications();
  const { mutate } = ProcessService.useProcesses(process.customerId);

  const outboundShipments = ShipmentService.useShipments(
    {
      processIds: [process.processId],
      shipmentStates: [ShipmentState.LABEL_PRINTED],
      limit: 10,
    },
    ShipmentType.SHIPMENT,
  );
  const inboundShipments = ShipmentService.useShipments(
    {
      processIds: [process.processId],
      shipmentStates: [ShipmentState.RETOURE_TOUR_READY],
      limit: 10,
    },
    ShipmentType.RETOUR,
  );

  const shipments = [...(outboundShipments.data ?? []), ...(inboundShipments.data ?? [])];

  const updateBlocked = async (blocked: boolean) => {
    try {
      await assertAuthentication(ReauthTimeInMinutes.EDIT_PROCESS);

      await ProcessService.putProcess({ blocked }, process.processId);

      await mutate();
    } catch (error) {
      notifications.addError(error);
    }
  };

  return (
    <Box>
      {process.blocked ? (
        <ButtonAsync
          startIcon={<DirectionsRunIcon />}
          variant="contained"
          color="success"
          onClick={() => updateBlocked(false)}
        >
          {t('Release process')}
        </ButtonAsync>
      ) : (
        <ButtonAsync
          startIcon={<BlockIcon />}
          variant="contained"
          color="error"
          onClick={() => updateBlocked(true)}
          disabled={!!shipments.length}
        >
          {t('Block process')}
        </ButtonAsync>
      )}

      {shipments.length > 0 && (
        <Alert
          severity="warning"
          sx={{ mt: 1 }}
        >
          <AlertTitle>{t('Processed shipments found')}</AlertTitle>
          {process.blocked ? (
            <>
              {t(
                'The following consignments have already been processed in the warehouse, but will not be part of a tour as the process is blocked.',
              )}
            </>
          ) : (
            <>{t('The process cannot be blocked as there are already processed consignments.')}</>
          )}
          {t('Only the first consignments are displayed. Use the search to find them all.')}
          <ul>
            {shipments.map((shipment) => (
              <li key={shipment.shipmentId}>
                <Link to={`/shipment/${shipment.shipmentId}`}>
                  {t('Shipment {{shipmentId}}', { shipmentId: shipment.shipmentId })}
                </Link>{' '}
                {shipment.processedAt ? new Date().toLocaleString() : '-'}
              </li>
            ))}
          </ul>
        </Alert>
      )}
    </Box>
  );
};

export default BlockProcess;
