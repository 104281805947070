import React, { FC, useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Alert, MenuItem, Stack, TextField } from '@mui/material';
import * as ShipmentMethodService from '../../../../../../services/shipment-method-service/shipmentMethod.service';
import { ShipmentMethodCarrierName } from '../../../../../../shared/backend';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';

export interface AddShipmentMethodButtonProps {
  availableCarriers: string[] | undefined;
  onShipmentMethodCreate: (
    body: ShipmentMethodService.CreateShipmentMethodDto,
    processId: number,
  ) => Promise<ShipmentMethodService.ShipmentMethodDto | undefined>;
  processId: number;
}

const AddShipmentMethodForm: FC<AddShipmentMethodButtonProps> = (props) => {
  const { t } = useTranslation('CRM');

  const [carrier, setCarrier] = useState('');
  const [name, setName] = useState('');
  const [isWorking, setWorking] = useState(false);

  return (
    <form
      onSubmit={(ev) => {
        ev.preventDefault();

        setWorking(true);

        props
          .onShipmentMethodCreate(
            {
              carrierName: carrier as ShipmentMethodCarrierName,
              carrierCredential: {},
              methodName: name,
            },
            props.processId,
          )
          .then(() => {
            setCarrier('');
            setName('');
          })
          .finally(() => {
            setWorking(false);
          });
      }}
    >
      <Stack
        spacing={1}
        direction="row"
        alignItems="center"
      >
        {!props.availableCarriers?.length && <Alert severity="warning">Keine Versanddienstleister verfügbar.</Alert>}

        <TextField
          select
          label={t('Shipping service provider')}
          value={carrier}
          onChange={(ev) => setCarrier(ev.target.value)}
          disabled={!props.availableCarriers?.length || isWorking}
          sx={{ minWidth: 200 }}
        >
          <MenuItem value="">&nbsp;</MenuItem>
          {props.availableCarriers?.map((carrier) => (
            <MenuItem
              key={carrier}
              value={carrier}
            >
              {carrier}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          label={t('Name')}
          value={name}
          onChange={(ev) => setName(ev.target.value)}
          disabled={!carrier || isWorking}
        />

        <LoadingButton
          type="submit"
          startIcon={<AddCircleIcon />}
          disabled={!carrier || !name}
          variant="contained"
          loading={isWorking}
        >
          {t('Add')}
        </LoadingButton>
      </Stack>
    </form>
  );
};

export default AddShipmentMethodForm;
