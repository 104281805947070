import { Button, Card, CardContent, CardHeader, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import HomeIcon from '@mui/icons-material/Home';

import React, { FC, useState } from 'react';
import {
  AddressDto,
  CreateAddressDto,
  UpdateAddressDto,
  createAddressForCustomer,
  deleteAddressForCustomer,
  updateAddressForCustomer,
  useCustomerAddresses,
} from '../../../../../../../services/address-service/address.service';
import AddressForm from './AddressForm';
import { useNotifications } from '../../../../../../../hooks/useNotifications';
import { useTranslation } from 'react-i18next';

export interface CustomerAddressCardProps {
  customerId: number;
}

const CustomerAddressCard: FC<CustomerAddressCardProps> = (props: CustomerAddressCardProps) => {
  const addresses = useCustomerAddresses(props.customerId);
  const notificationHandler = useNotifications();
  const { t } = useTranslation('CRM');

  const [showNewAddressForm, setShowNewAddressForm] = useState(false);

  const handleAddressCreate = async (createValues: CreateAddressDto) => {
    try {
      const createdAddress = await createAddressForCustomer(props.customerId, createValues);
      const newAddresses: AddressDto[] = addresses.data ? [...addresses.data, createdAddress] : [createdAddress];
      addresses.mutate(newAddresses);
      setShowNewAddressForm(false);

      notificationHandler.addSuccess(t('Address created'));
    } catch (error) {
      notificationHandler.addError(error);
    }
  };

  const handleAddressDelete = async (addressId: number) => {
    try {
      await deleteAddressForCustomer(props.customerId, addressId);

      await addresses.mutate();

      notificationHandler.addSuccess(t('Address deleted'));
    } catch (error) {
      notificationHandler.addError(error);
    }
  };

  const handleAddressUpdate = async (addressId: number, updateValues: UpdateAddressDto) => {
    try {
      await updateAddressForCustomer(props.customerId, addressId, updateValues);

      await addresses.mutate();

      notificationHandler.addSuccess(t('Address updated')); /*  */
    } catch (error) {
      notificationHandler.addError(error);
    }
  };

  return (
    <Card sx={{ mt: 3, p: 1 }}>
      <CardHeader
        title={<Typography variant="h5">Adressen</Typography>}
        avatar={<HomeIcon sx={{ fontSize: '2.5em' }} />}
      ></CardHeader>

      <CardContent>
        {showNewAddressForm && (
          <AddressForm
            address={null}
            onAddressCreate={handleAddressCreate}
            onAddressDelete={handleAddressDelete}
            onAddressUpdate={handleAddressUpdate}
            cancelAddressCreate={() => {
              setShowNewAddressForm(false);
            }}
          />
        )}
        {addresses.data &&
          addresses.data.map((address, index) => (
            <AddressForm
              key={address.addressId}
              address={address}
              onAddressCreate={handleAddressCreate}
              onAddressDelete={handleAddressDelete}
              onAddressUpdate={handleAddressUpdate}
            />
          ))}

        <Button
          variant="outlined"
          disabled={showNewAddressForm}
          onClick={() => setShowNewAddressForm(true)}
          sx={(theme) => ({ padding: theme.spacing(1, 2), mt: 2 })}
          startIcon={<AddIcon />}
        >
          {t('Create new address')}
        </Button>
      </CardContent>
    </Card>
  );
};

export default CustomerAddressCard;
