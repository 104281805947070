import React from 'react';
import { OrderWithShipmentsAndToursDto } from '../../services/order-service/order.service';
import { Box, Stack, Tooltip, Typography } from '@mui/material';
import BlockIcon from '@mui/icons-material/Block';
import { CustomerDto } from '../../services/customer-service/customer.service';
import { Link } from 'react-router-dom';
import { useAuthentication } from '../../hooks/useAuthentication';
import { useTranslation } from 'react-i18next';

type Props = {
  order: OrderWithShipmentsAndToursDto;
  customer: CustomerDto | undefined;
};

const OrderInfo: React.FC<Props> = ({ order, customer }) => {
  const { t } = useTranslation();
  const auth = useAuthentication();

  return (
    <Box>
      <Stack direction="row">
        <Box flexGrow={1}>
          <Tooltip title={t('Customer facing ID')}>
            <Typography
              variant="h4"
              component="h1"
            >
              {order.customerFacingId}
            </Typography>
          </Tooltip>
          <Typography
            variant="subtitle1"
            component="h2"
            title={t('Company')}
          >
            {order.process.blocked && (
              <BlockIcon
                color="error"
                sx={{ fontSize: '1.2em', verticalAlign: 'sub', mr: 0.5 }}
              />
            )}
            {(auth.isAdmin() || auth.isCustomerCareAgent()) && customer ? (
              <Link to={`/crm/customers/${customer.customerId}`}>
                {customer?.company} ({order.processId})
              </Link>
            ) : (
              customer?.company
            )}
          </Typography>
        </Box>
        {!!order.externalCreatedAt && (
          <div>
            <Box
              sx={{
                paddingLeft: 3,
                flexGrow: 1,
              }}
            >
              <Typography
                align="right"
                variant="body1"
                component="p"
              >
                Bestellt am
              </Typography>
              <Typography
                align="right"
                variant="h6"
                component="p"
              >
                {new Date(order.externalCreatedAt).toLocaleDateString()}
              </Typography>
            </Box>
          </div>
        )}
      </Stack>
    </Box>
  );
};

export default OrderInfo;
