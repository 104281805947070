import { Clear, Save } from '@mui/icons-material';
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import CasinoIcon from '@mui/icons-material/Casino';
import { generateHumanReadablePassword, isEqualArray } from '../../../../../../../shared/helper/helper';
import { ProcessDto, UpdateProcessDto } from '../../../../../../../services/process-service/process.service';
import { Scope } from '../../../../../../../shared/backend';
import { useTranslation } from 'react-i18next';

interface Props {
  process: ProcessDto;
  handleProcessUpdate: (body: UpdateProcessDto, processId: number) => Promise<ProcessDto | undefined>;
}

export const ProcessTechnicalUser = (props: Props) => {
  const { t } = useTranslation('CRM');

  const [username, setUsername] = useState(props.process.username);
  const [password, setPassword] = useState('');
  const [scope, setScope] = useState(props.process.scope ?? []);

  return (
    <div>
      <Typography
        variant="h6"
        mb={2}
      >
        {t('Technical user settings')}
      </Typography>

      <form
        onSubmit={(ev) => {
          ev.preventDefault();

          props.handleProcessUpdate(
            {
              username: username,
              password: password || undefined,
              scope: scope,
            },
            props.process.processId,
          );
        }}
      >
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
        >
          <TextField
            label={t('Username')}
            variant="outlined"
            value={username ?? ''}
            onChange={(ev) => setUsername(ev.target.value)}
          />

          <TextField
            label={t('Password: type to reset')}
            variant="outlined"
            inputProps={{
              minLength: 16,
            }}
            value={password ?? ''}
            onChange={(ev) => setPassword(ev.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    onClick={() => {
                      setPassword(generateHumanReadablePassword(32));
                    }}
                    sx={{ transition: 'transform 0.5s', ':hover': { transform: 'rotate(35deg)' } }}
                  >
                    <CasinoIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <FormControl sx={{ m: 1, minWidth: 200 }}>
            <InputLabel id="scope-input-label">API Scope</InputLabel>
            <Select
              labelId="scope-input-label"
              multiple
              value={scope}
              onChange={({ target: { value } }) =>
                setScope((typeof value === 'string' ? value.split(',') : value) as Scope[])
              }
              input={<OutlinedInput label={t('Name')} />}
            >
              {Object.entries(Scope).map(([, value]) => (
                <MenuItem
                  key={value}
                  value={value}
                >
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Button
            color="secondary"
            variant="contained"
            disabled={
              username === props.process.username && password === '' && isEqualArray(props.process.scope, scope)
            }
            startIcon={<Save />}
            type="submit"
          >
            Update
          </Button>
          <Button
            variant="outlined"
            startIcon={<Clear />}
            onClick={() => {
              setUsername(props.process.username);
              setPassword('');
              setScope(props.process.scope);
            }}
          >
            {t('Reset')}
          </Button>
        </Stack>
      </form>
    </div>
  );
};
