import React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { ConfirmDialog, confirmable } from 'react-confirm';
import { createConfirmation } from '../../../../../../../shared/confirm-dialog';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';
import Close from '@mui/icons-material/Close';
import Send from '@mui/icons-material/Send';

type Props = {
  shipmentLabel: string;
};

const GlobalSubmitWarningDialog: ConfirmDialog<Props, boolean> = ({ shipmentLabel, proceed, show }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={show}
      disableEscapeKeyDown
    >
      <DialogTitle>{t('Process shipment?')}</DialogTitle>

      <DialogContent>
        <DialogContentText>
          <Trans>
            We have noticed that you have pressed the Enter key, but we are not sure whether this means that you wish to
            process the shipment <strong>{shipmentLabel}</strong>. You can let us know your intention below.
          </Trans>
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => proceed(false)}
          color="error"
          startIcon={<Close />}
        >
          {t('Abort')}
        </Button>

        <Box flexGrow={1}></Box>

        <Button
          onClick={() => proceed(true)}
          color="success"
          startIcon={<Send />}
          autoFocus={true}
        >
          {t('I want to process the shipment')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const confirmGlobalSubmitWarningDialog = createConfirmation(confirmable(GlobalSubmitWarningDialog));
