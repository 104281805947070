import React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Check, Edit } from '@mui/icons-material';
import { ConfirmDialog, confirmable } from 'react-confirm';
import { createConfirmation } from '../../../../../../../shared/confirm-dialog';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';
import { useUserInfo } from '../../../../../../../services/user-service/user.service';

type Props = {
  weight: number;
};

const WeightWarningDialog: ConfirmDialog<Props, boolean> = ({ weight, proceed, show }) => {
  const { t } = useTranslation();
  const userInfo = useUserInfo();

  const userName = userInfo.data?.firstName;

  return (
    <Dialog open={show}>
      <DialogTitle>{t('Is the weight correct?')}</DialogTitle>

      <DialogContent>
        <DialogContentText>
          {/** Trans has issues with React 18, see https://github.com/i18next/react-i18next/issues/1483
           *  This is the reason for the ugly any cast, but it seems to be the best solution for now */}
          <Trans>
            Hello {{ userName }}, with <strong>{{ weight } as any}g</strong> the weight is outside the defined range.
            Please check it again carefully to avoid costly errors during customs clearance. Your decision will be
            logged accordingly.
          </Trans>
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => proceed(false)}
          color="error"
          startIcon={<Edit />}
        >
          {t('Modify')}
        </Button>

        <Box flexGrow={1}></Box>

        <Button
          onClick={() => proceed(true)}
          color="success"
          startIcon={<Check />}
        >
          {t('The weight is correct')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const checkWeightBoundaries = (weight: null | number, minWeight: null | number, maxWeight: null | number) => {
  const isTooLight = weight !== null && minWeight !== null && weight < minWeight;
  const isTooHeavy = weight !== null && maxWeight !== null && weight > maxWeight;

  return { isTooLight, isTooHeavy };
};

export const confirmWeightWarningDialog = createConfirmation(confirmable(WeightWarningDialog));

export const isWeightWarningAccepted = async (
  weight: null | number,
  { minWeight, maxWeight }: { minWeight: null | number; maxWeight: null | number },
): Promise<boolean> => {
  if (weight === null) {
    return minWeight === null;
  }

  const { isTooLight, isTooHeavy } = checkWeightBoundaries(weight, minWeight, maxWeight);

  if (!isTooLight && !isTooHeavy) {
    return true;
  }

  return await confirmWeightWarningDialog({ weight });
};
