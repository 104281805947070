import React from 'react';
import { useParams } from 'react-router-dom';
import { ProcessService } from '../../../services/process-service/process.service';
import { Alert, Grid, Skeleton, Stack } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import ProcessDetails from './ProcessDetails';
import ProcessSettings from './ProcessSettings';
import BreadcrumbsGridItem from '../BreadcrumbsGridItem';
import ProcessFiscalRepresentation from './settings/ProcessFiscalRepresentation';
import ProcessShippingMethods from './settings/ProcessShippingMethods';
import ProcessTechnicalUser from './settings/ProcessTechnicalUser';
import ProcessDangerzone from './settings/ProcessDangerzone';
import { useTranslation } from 'react-i18next';

type Props = {};

const ProcessPage: React.FC<Props> = () => {
  const { t } = useTranslation('CRM');

  const params = useParams();
  const processId = parseInt(params.processId ?? '', 10);

  const process = ProcessService.useProcess(processId);
  const processTypeLabel = process.data?.processType.label;
  const customerCompany = process.data?.customer.company;

  return (
    <Grid
      container
      spacing={3}
    >
      {process.data && (
        <BreadcrumbsGridItem
          items={[
            { href: '/crm/customers', label: t('Customers') },
            {
              href: `/crm/customers/${process.data.customerId}`,
              label: process.data.customer.company,
            },
            {
              label: t('Process {{processId}} ({{processTypeLabel}})', {
                processId,
                processTypeLabel,
              }),
            },
          ]}
        />
      )}

      <Grid
        item
        xs={12}
      >
        {process.isLoading ? (
          <Skeleton
            variant="rounded"
            height={80}
          />
        ) : process.data ? (
          <>
            <Helmet>
              <title>{t(`Process {{processId}} ({{customerCompany}})`, { processId, customerCompany })}</title>
            </Helmet>

            <Stack
              spacing={3}
              pb={12}
            >
              <ProcessDetails process={process.data} />

              <ProcessSettings process={process.data} />

              <ProcessFiscalRepresentation process={process.data} />

              <ProcessShippingMethods process={process.data} />

              <ProcessTechnicalUser process={process.data} />

              <ProcessDangerzone process={process.data} />
            </Stack>
          </>
        ) : (
          <Alert severity="warning">{process.error?.toString()}</Alert>
        )}
      </Grid>
    </Grid>
  );
};

export default ProcessPage;
