import { Box, Button, LinearProgress, Stack, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import axios, { AxiosError } from 'axios';
import React, { Fragment } from 'react';
import { useNotifications } from '../../hooks/useNotifications';
import { updatePasswordForCurrentlyLoggedInUser, useUserInfo } from '../../services/user-service/user.service';
import SuccessToast from '../../shared/components/success-toast/SuccessToast';
import { getPasswordStrength } from '../../shared/helper/password';
import { useTranslation } from 'react-i18next';

export const UpdatePasswordForm: React.FC<{}> = () => {
  const { t } = useTranslation();
  const notificationHandler = useNotifications();
  const userInfo = useUserInfo();

  const [successMsg, setSuccessMsg] = React.useState('');
  const [oldPasswordInvalid, setOldPasswordInvalid] = React.useState<boolean>(false);

  const updatePasswordFormInitialState = {
    oldPassword: '',
    newPassword: '',
    newPasswordConfirm: '',
  };

  const [updatePasswordFormFields, setUpdatePasswordFormFields] = React.useState<{
    oldPassword: string;
    newPassword: string;
    newPasswordConfirm: string;
  }>(updatePasswordFormInitialState);

  const handleUpdatePasswordFormChange = (prop: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (prop === 'oldPassword') {
      setOldPasswordInvalid(false);
    }

    setUpdatePasswordFormFields({
      ...updatePasswordFormFields,
      [prop]: event.target.value,
    });
  };

  const handleUpdatePassword = async () => {
    try {
      await updatePasswordForCurrentlyLoggedInUser(
        updatePasswordFormFields.oldPassword,
        updatePasswordFormFields.newPassword,
      );

      setOldPasswordInvalid(false);
      setSuccessMsg(t('Your password was updated.'));

      // Clear form values
      setUpdatePasswordFormFields(updatePasswordFormInitialState);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (
          (error as AxiosError<{ context?: { errorCode?: string } }>).response?.data?.context?.errorCode ===
          'update_password_01'
        ) {
          setOldPasswordInvalid(true);

          return;
        }
      }
      notificationHandler.addError(error);
    }
  };

  const passwordMismatch =
    !!updatePasswordFormFields.newPassword &&
    !!updatePasswordFormFields.newPasswordConfirm &&
    updatePasswordFormFields.newPassword !== updatePasswordFormFields.newPasswordConfirm;

  const newPasswordInvalid = !!updatePasswordFormFields.newPassword && updatePasswordFormFields.newPassword.length < 8;

  const hasError =
    !updatePasswordFormFields.oldPassword ||
    !updatePasswordFormFields.newPassword ||
    !updatePasswordFormFields.newPasswordConfirm ||
    passwordMismatch ||
    oldPasswordInvalid ||
    newPasswordInvalid;

  const passwordStrength = updatePasswordFormFields.newPassword
    ? getPasswordStrength(updatePasswordFormFields.newPassword)
    : 0;

  return (
    <Fragment>
      <SuccessToast
        successMsg={successMsg}
        open={successMsg !== ''}
        onClose={() => setSuccessMsg('')}
      ></SuccessToast>

      <Typography
        variant="h5"
        gutterBottom
      >
        {t('Change password')}
      </Typography>
      <Typography
        variant="body2"
        sx={{ mb: 3 }}
      >
        {t('A secure password contains not common words or is at least 12 characters long.')}
      </Typography>

      <form
        onSubmit={(event) => {
          event?.preventDefault();
          handleUpdatePassword();
        }}
        style={{ width: '100%' }}
      >
        <input
          readOnly
          type="hidden"
          name="username"
          autoComplete="username"
          value={userInfo.data?.email}
        />

        <Stack
          direction="column"
          spacing={2}
        >
          <TextField
            required
            variant="outlined"
            label={t('Current password')}
            helperText={oldPasswordInvalid ? t('This is not your current password') : undefined}
            error={oldPasswordInvalid}
            type="password"
            value={updatePasswordFormFields.oldPassword}
            onChange={handleUpdatePasswordFormChange('oldPassword')}
            InputProps={{ autoComplete: 'current-password' }}
          />

          <Box>
            <TextField
              required
              fullWidth
              variant="outlined"
              label={t('New password')}
              helperText={newPasswordInvalid ? t('Your password needs to be between 8 and 64 characters') : undefined}
              error={newPasswordInvalid}
              type="password"
              value={updatePasswordFormFields.newPassword}
              onChange={handleUpdatePasswordFormChange('newPassword')}
              InputProps={{ autoComplete: 'new-password' }}
            />
            <LinearProgress
              variant="determinate"
              color={passwordStrength >= 85 ? 'success' : 'error'}
              value={passwordStrength}
            />
          </Box>

          <TextField
            required
            variant="outlined"
            type="password"
            error={passwordMismatch}
            label={t('Confirm new password')}
            helperText={passwordMismatch ? t('Password differs') : undefined}
            value={updatePasswordFormFields.newPasswordConfirm}
            onChange={handleUpdatePasswordFormChange('newPasswordConfirm')}
            InputProps={{ autoComplete: 'new-password' }}
          />

          <Button
            name="save"
            variant="contained"
            color="secondary"
            disabled={hasError}
            type="submit"
          >
            {t('Change password')}
          </Button>
        </Stack>
      </form>
    </Fragment>
  );
};
