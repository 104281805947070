import { Alert, Box, Button, FormControlLabel, Grid, Paper, Skeleton, Stack, Switch, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { OrderService, OrderWithShipmentsAndToursDto } from '../../services/order-service/order.service';
import OrderInfo from './OrderInfo';
import OrderConsumerAddress from './OrderConsumerAddress';
import OrderDetails from './OrderDetails';
import { Link, useNavigate } from 'react-router-dom';
import { Add, Search } from '@mui/icons-material';
import { useAuthentication } from '../../hooks/useAuthentication';
import OrderWarnings from './OrderWarnings';
import InboundShipment from './InboundShipment';
import LineItemsUnassigned from './LineItemsUnassigned';
import ShipmentStateLegend from './ShipmentStateLegend';
import ShipmentCompact from './ShipmentCompact';
import { ShipmentAcceptModal } from '../orders/shared/components/order-detail-modal/components/shipment-accept-modal/ShipmentAcceptModal';
import { useHistoryRef } from '../../hooks/useHistoryNavigation';
import ErrorPage from '../../shared/components/ErrorPage';
import axios from 'axios';
import { OrderState, OrderType, ShipmentState, ShipmentType } from '../../shared/backend';
import { ShipmentService } from '../../services/shipment-service/shipment.service';
import { useLastReturn } from '../../shared/hooks/last-return';
import { useTranslation } from 'react-i18next';
import { useNotifications } from '../../hooks/useNotifications';
import { useScale } from '../../contexts/ScaleContext';
import { confirmWeightWarningDialog } from '../orders/shared/components/order-detail-modal/components/shipment-view/WeightWarningDialog';
import { confirmShippingUnit } from './ShippingUnitDialog';
import { getTotalOrderWeight } from './shipment.helper';

type Props = {
  shipmentId: number;
};

const ShipmentOrderOverview: React.FC<Props> = ({ shipmentId }) => {
  const { t } = useTranslation();
  const historyRef = useHistoryRef();
  const navigateTo = useNavigate();
  const notifications = useNotifications();
  const auth = useAuthentication();
  const scale = useScale();
  const [, setLastReturn] = useLastReturn();

  const readyForNextUrl = historyRef ? new URL(`${window.location.origin}${historyRef}`) : null;
  readyForNextUrl?.searchParams.delete('q');

  const order = OrderService.useOrderByShipmentId(shipmentId ?? null);
  const process = order.data?.process;
  const shipment = order.data?.shipments.find((shipment) => shipment.shipmentId === shipmentId);
  const otherShipments = order.data?.shipments.filter((shipment) => shipment.shipmentId !== shipmentId);
  const outgoingShipments = ShipmentService.useShipmentsByOrder(shipment?.orderId, OrderType.ORDER);

  const [isEditMode, setEditMode] = useState(false);
  const [isReadyForNext, setReadyForNext] = useState(false);

  const readyForNext = useCallback(() => setReadyForNext(true), []);

  const totalOrderWeight = getTotalOrderWeight(outgoingShipments.data);

  const acceptReturnShipment = async (shippingWeight: number | null) => {
    if (!shipment || !process) {
      return;
    }

    try {
      if (shippingWeight !== null && shippingWeight / totalOrderWeight > 1.05) {
        if (!(await confirmWeightWarningDialog({ weight: shippingWeight }))) {
          return;
        }
      }

      let shippingUnitBarcode: string | undefined = undefined;

      if (process.useShippingUnitForReturns) {
        if (!process.returnWarehouseAddressId) {
          notifications.addError(
            t(
              'No return warehouse address configured. Please contact our support team and do not process this shipment.',
            ),
          );

          return;
        }

        shippingUnitBarcode = await confirmShippingUnit({ process });

        if (!shippingUnitBarcode) {
          notifications.addWarning(t('Shipment was not processed due to missing shipping unit'));

          return;
        }
      }

      await ShipmentService.acceptReturnShipment(
        shipment.shipmentId,
        {
          referrer: historyRef ?? undefined,
          scaleWeight: scale.weight,
        },
        shippingUnitBarcode,
      );

      if (!isEditMode) {
        readyForNext();
      }
    } catch (err) {
      notifications.addError(err);
    }
  };

  useEffect(() => {
    if (isReadyForNext) {
      setLastReturn({
        shipmentId,
        processedAt: new Date(),
      });
    }
  }, [isReadyForNext, shipmentId, setLastReturn]);

  if (order.error && axios.isAxiosError(order.error) && order.error.response?.status === 404) {
    return (
      <ErrorPage
        title={t('Shipment not found')}
        content={t('But at least you can look at a sweet kitten.')}
      />
    );
  }

  if (shipment?.type === ShipmentType.SHIPMENT) {
    return (
      <ErrorPage
        title={t('Outbound shipment detected')}
        content={t('This page is only available for inbound shipments.')}
      >
        <Button
          component={Link}
          to={`/orders/all-orders?orderId=${shipment.orderId}&shipmentId=${shipment.shipmentId}`}
          variant="contained"
        >
          {t('Go to corresponding order')}
        </Button>
      </ErrorPage>
    );
  }

  return (
    <Grid
      container
      spacing={1}
    >
      <ShipmentAcceptModal
        open={isReadyForNext}
        onClose={() =>
          navigateTo(readyForNextUrl ? `${readyForNextUrl.pathname}${readyForNextUrl.search}` : '/shipments/inbound')
        }
      />

      <Grid
        item
        xs={12}
        paddingBottom={2}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
        >
          <Button
            component={Link}
            to={historyRef ?? '/shipments/inbound'}
            variant="outlined"
            color="secondary"
            startIcon={<Search />}
          >
            {t('Go to search')}
          </Button>

          {auth.isStaff() && (
            <FormControlLabel
              sx={{ m: 0, mt: -1 }}
              control={
                <Switch
                  disabled={order.data?.process.blocked ?? true}
                  color="secondary"
                  onChange={(ev) => setEditMode(ev.target.checked)}
                  checked={isEditMode}
                ></Switch>
              }
              label={t('Activate editing')}
            />
          )}
        </Stack>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        md={3}
      >
        {order.isLoading ? (
          <Skeleton height="100" />
        ) : order.data ? (
          <Paper
            sx={{
              height: '100%',
              paddingX: 2,
              paddingY: 1,
              ...(order.data.state === OrderState.BLOCKED || order.data.process.blocked
                ? { backgroundColor: 'error.light', color: 'error.contrastText' }
                : null),
            }}
          >
            <Stack
              direction="column"
              justifyContent="space-between"
              sx={{ height: '100%' }}
            >
              <OrderInfo
                order={order.data}
                customer={process?.customer}
              />
              {isEditMode && <OrderConsumerAddress order={order.data} />}
            </Stack>
          </Paper>
        ) : (
          <Alert severity="warning">{order.error?.toString()}</Alert>
        )}
      </Grid>
      {!isEditMode && (
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
        >
          {order.isLoading ? (
            <Skeleton height="100" />
          ) : order.data ? (
            <Paper sx={{ height: '100%', paddingX: 3, paddingY: 2 }}>
              <OrderConsumerAddress order={order.data} />
            </Paper>
          ) : (
            <Alert severity="warning">{order.error?.toString()}</Alert>
          )}
        </Grid>
      )}

      <Grid
        item
        xs={12}
        md={isEditMode ? 9 : 6}
      >
        {order.isLoading ? (
          <Skeleton height="100" />
        ) : order.data ? (
          <Paper sx={{ height: '100%', paddingX: 3, paddingY: 2 }}>
            <OrderDetails
              editing={isEditMode}
              order={order.data}
              saveOrder={async (data) => {
                if (order.data) {
                  await OrderService.putOrder(data, order.data.orderId, { referrer: historyRef ?? undefined });
                  await order.mutate({ ...order.data, ...(data as Partial<OrderWithShipmentsAndToursDto>) });
                }

                setEditMode(false);

                return true;
              }}
            />
          </Paper>
        ) : (
          <Alert severity="warning">{order.error?.toString()}</Alert>
        )}
      </Grid>

      {order.isLoading ? (
        <Skeleton height="100" />
      ) : order.data && shipment ? (
        <OrderWarnings
          order={order.data}
          shipment={shipment}
        />
      ) : undefined}

      {shipment && order.data && process && (
        <Grid
          item
          xs={12}
        >
          <Box>
            <LineItemsUnassigned
              order={order.data}
              shipment={shipment}
              disabled={shipment.isConsigned || shipment.state !== ShipmentState.RETOURE_ANNOUNCED}
              acceptShipment={acceptReturnShipment}
              editing={isEditMode}
            />
          </Box>
        </Grid>
      )}

      {shipment && order.data && process && (
        <Grid
          item
          xs={12}
          my={5}
        >
          <Paper
            sx={{
              paddingX: 2,
              paddingY: 1,
              border: '3px solid',
              borderColor: (shipment.type as ShipmentType) === ShipmentType.SHIPMENT ? 'outbound.main' : 'inbound.main',
            }}
            elevation={6}
          >
            <InboundShipment
              editMode={isEditMode}
              order={order.data}
              process={process}
              shipment={shipment}
              readyForNext={readyForNext}
              acceptShipment={acceptReturnShipment}
            />
          </Paper>
        </Grid>
      )}

      {!!otherShipments?.length &&
        order.data?.shipments.map((shipment) => {
          const isCurrentShipment = shipment.shipmentId === shipmentId;

          return (
            <Grid
              key={shipment.shipmentId}
              item
              xs={12}
            >
              <Paper
                sx={{
                  paddingX: 1,
                  paddingY: 2,
                  opacity: isCurrentShipment ? 0.7 : undefined,
                  filter: isCurrentShipment ? 'grayscale(100%)' : undefined,
                }}
                variant="outlined"
              >
                <ShipmentCompact
                  shipment={shipment}
                  selected={isCurrentShipment}
                />
              </Paper>
            </Grid>
          );
        })}

      {otherShipments !== undefined && !otherShipments.length && (
        <Grid
          item
          xs={12}
        >
          <Box sx={{ paddingX: 1, paddingY: 2 }}>
            <Typography
              variant="body2"
              align="center"
              color="text.disabled"
            >
              {t('No further shipments available')}
            </Typography>
          </Box>
        </Grid>
      )}

      {shipment && (
        <Grid
          item
          xs={12}
          textAlign="center"
          mt={3}
        >
          <Button
            startIcon={<Add />}
            variant="outlined"
            size="small"
            onClick={async () => {
              await ShipmentService.createShipmentByOrder(shipment.orderId, {
                shipmentType: shipment.type as ShipmentType,
              });
              await order.mutate();
            }}
          >
            {t('Create shipment')}
          </Button>
        </Grid>
      )}

      <Grid
        mt={5}
        item
        xs={12}
      >
        <Box
          component="details"
          sx={{ color: 'text.disabled' }}
        >
          <Box
            component="summary"
            sx={{ cursor: 'pointer' }}
          >
            {t('Legend')}
          </Box>

          <ShipmentStateLegend />
        </Box>
      </Grid>
    </Grid>
  );
};

export default ShipmentOrderOverview;
